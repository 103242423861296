// Customizable Area Start
import React from "react";

import {
  Box,
  Popover,
  Badge,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";

import { createTheme,styled } from "@material-ui/core/styles";
import {AddIcon,UpArrow,DownArrow,thumbnailImg,EditIcon,TrashIcon,closeIcon} from "./assets";
import AdvancedSearch from "../../advancedsearch/src/AdvancedSearch.web"
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import AlertSuccess from "../../../components/src/AlertSuccess.web";
import { NewFilter,Filter } from "../../../../packages/blocks/catalogue/src/assets";
import { Table, Thead, Tbody, Th, Tr, Td } from "../../../components/src/CustomTableV3";
import FilterItems from "../../filteritems/src/FilterItems.web"
const headershows=[
  { id: 1, label: "Name", sort: true, dataType: "string", key: "name" },
  { id: 2, label: "Branches", sort: false, dataType: "string", key: "Branches" },
  { id: 3, label: "Manager", sort: true, dataType: "string", key: "manager" },
  { id: 3, label: "Users", sort: true, dataType: "string", key: "users_count" },
 
]

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import TeamBuilderController, {
  Props,
  configJSON,
} from "./TeamBuilderController";

export default class TeamBuilder extends TeamBuilderController {
  constructor(props: Props) {
    super(props);
  }

  advancedSearch=()=>{
    const openFilterContainer = Boolean(this.state.anchorUserFilterContentsEl)
    return(<StyledContentWrapper>
      <AdvancedSearch
    data-test-id="btn_change_one"
    searchInputValue={this.state.AdvanceSearchValue}
    handleInputChange={this.handleTeamInputChangeSearch}
    handleListItemClick={this.handleThumbnailListClick}
    filterList={this.state.filteredContentData}
    inputWidth={window.innerWidth<800?"300":"480"}
    isFilterDropActive={openFilterContainer}
    showList={this.state.showContentLists} navigation={undefined} id={""} />
     <div style={{ marginLeft: "0px" }} className={this.state.anchorUserFilterContentsEl ? "filterIconBox allBorder" : "filterIconBox"} aria-describedby={"myPopover"} onClick={this.handleFilterDropClick}>
    {this.state.placeholderSelectItems.length > 0 ? (
      <BadgeComponent color="secondary" variant="dot"></BadgeComponent>) : (
      ""
    )
    }
    {this.state.anchorUserFilterContentsEl ? (
      <img src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
    ) : (
      <img src={NewFilter} data-test-id="filterOpenTestID" />
    )}
  </div>
  <Popover 
                data-test-id="filterPropsID"
                className="filterContainerPopover"
                open={openFilterContainer}
                id="myPopover"
                anchorEl={this.state.anchorUserFilterContentsEl}
                onClose={this.filterContainerDropdownCloseHandlers}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right"
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right"
                }}
              
              >
                <FilterItems
              isThumbnail={true}
              expertAdminList={this.state.expertAdminsContent}
              navigation={this.props.navigation}
              data-test-id="FilterItemsTestID"
              handleClick={this.handleClicksFilters}
              placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandles}
              handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChanges}
              placeholderCheckboxUserManageChangeHandler={this.placeholderCheckboxUserManageChangeHandlers}
              placeholderCheckboxUserManageChangeHandlerBranch={this.placeholderCheckboxUserManageChangeHandlersBranchs}
              handlePlaceholderUserSearchChangeBranch={this.handlePlaceholderUserBranchSearchChanges}
              dropdownOpenHandler={this.dropdownHandlerOpenFun}
              creationDateChangeHandler={this.creationChangeHandlerDates}
              dropdownCloseHandler={this.dropdownHandlerCloses}
              anchorEl={this.state.anchorsEl}
              dropdownType={this.state.dropdownType}
              placeholderSelectedBranchItems={this.state.placeholderSelectsBranch}
              placeholderSearchText={this.state.placeholderSearchsTexts}
              userBranchList={this.state.UserBranchData}
              isCalendarOpen={false}
              handleClear={this.handleClearFilters}
              placeholderSelectedItems={this.state.placeholderSelectsData}
              placeholderSearchBranchText={this.state.placeholderSearchBranchText} 
              handleCalendarOpen={function (): void {}} 
              handleCalendarChange={function (): void {}} 
              isTeamBuilder={true}
              />
              </Popover>
    </StyledContentWrapper>)
    
  }
  renderTableBody=()=>{
    return(
      <>
        {this.state.TeamList?.map((user:any) => { 
           const BgColors=this.state.TeamId===user.id?true:false
              return (
                <Tr key={user.id} rowBg={BgColors} data-test-id="rowId" RowClick={()=>this.RowClick(user)}>
                  <Td rowBg={BgColors}>
                    {user.attributes.name}
                  </Td>
                  <Td rowBg={BgColors}>
                    <span className="branch_text">
                    {user.attributes.branches===null ? "" :user.attributes.branches.map((branch:string)=>{
                      return(
                        <span>{branch},</span>
                      )
                   
                    })}
                    </span>
                   
                    </Td> 
                    <Td rowBg={BgColors}>
                    {user.attributes.manager}
                    </Td>  
                    <Td rowBg={BgColors}>
                  {user.attributes.users_count}
                    </Td> 
                    <Td rowBg={BgColors}>
                 <span className="action_wrap"><img src={EditIcon} data-test-id="edit_id" onClick={(e)=>{ e?.stopPropagation(); this.handleEditIcon(user.id)}} style={{width:"24px",height:"24px",cursor:"pointer"}}/><img src={TrashIcon} data-test-id="trash_id" style={{width:"24px",height:"24px",cursor:"pointer"}} onClick={(e)=>{ e?.stopPropagation();this.handleTeamPopup(user.id)}}/></span>
                    </Td>      
                </Tr>)})}
      </>
    )
  }
  render() {
    return (
      <>
      <HeadingWrapper>
        <HeadingBranch>
        <p className="main_head">Primary Branch</p>
        <p className="sub_head">{this.state.branchName}</p>
        </HeadingBranch> 
        <Box className="advance_main">
          {this.advancedSearch()}
          </Box>
          <ButtonWrapper >
          <button className="btns" data-test-id="create_tem" onClick={this.handleCreateTeam}><span><img src={AddIcon} alt ="icon"/></span>Create Team</button>
          </ButtonWrapper>
      </HeadingWrapper>
      <Box>
      {this.state.TeamList && this.state.TeamList.length > 0? 
        (<> 
         <TableMainWrapper>
          <Table>
            <Thead>
              <Tr>

                {headershows?.map((header: any) => {
                  return(
                      <Th>
                      <p className="headingLabel">
                        {header.label}
                        <span className="arrowIconsContainer">
                          {header.sort && (
                            <>
                              <img
                                data-test-id={`sort_asc${header.label}`}
                                src={DownArrow}
                                width="12px"
                                onClick={() =>
                                  this.sortUsers(
                                    header?.dataType ?? "",
                                    header?.key ?? "",
                                    "asc"
                                  )
                                }
                              />
  
                              <img
                              data-test-id={`sort_desc${header.label}`}
                                src={UpArrow}
                                width="12px"
                                onClick={() =>
                                  this.sortUsers(
                                    header?.dataType ?? "",
                                    header?.key ?? "",
                                    "desc"
                                  )
                                }
                              />
                            </>
                          )}
                        </span>
                      </p>
                    </Th>
                  )})}
            
              </Tr>
            </Thead>
            <Tbody>
              {this.renderTableBody()}
            </Tbody>
          </Table>
          </TableMainWrapper>
        
        </>)
      :  <  AddIconNotFoundBoxs>
        <AddIconInnerBoxs>
          <Box>
            <AddImageComponents src={thumbnailImg} />
          </Box>
          <AddIconNotFoundTexts>No results found</AddIconNotFoundTexts>
        </AddIconInnerBoxs>
      </  AddIconNotFoundBoxs>}
      </Box>
      {this.state.BranchModal && 
        <DialogWrapper
        open={this.state.BranchModal}
        onClose={this.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description" 
      >
        
        <DialogTitle id="alert-dialog-title">
           <div className="dialogTitle">
                <div>
                    <span className="headingStyle">{this.state.TeamName}</span>
                </div>
               
           </div>
        </DialogTitle>
       <DialogContent>
        <p className="branch_head">Branches:</p>
          <span className="subHeading">
             {this.state.Branches && this.state.Branches.map((i:any,index:number)=>{
              return(
                <>
                <p className={this.state.branchSelect===i?" row_select branch_subhead":"branch_subhead"} onClick={()=>this.HandleselectBranch(index)}>
                {i}
                </p>
                </>
              )
            })}
          </span>
       </DialogContent>
        
    <DialogActions className="btnWrapper">
                  <Button variant="outlined" className="cancelBtn"
                        onClick={this.handleCancel}
                    >
                       Cancel
                    </Button>
                   <Button variant="contained" data-test-id="editpopupn_btn" className="primaryBtnStyle" onClick={()=>this.handleEditOpenPop()}>
                            Edit
                   </Button>        

        </DialogActions>
        <span className="close_icon">
          <img src={closeIcon} alt="cross_icon" onClick={this.handleCancel}/>
        </span>
      </DialogWrapper>
      }
     {this.state.DeletePopup && 
      <DeleteModal
      data-test-id='delete-content'
      openDialog={this.state.DeletePopup}
      headingText={`Are you sure you want to delete this team?`}
      btnCancelText="Cancel"
      btnOkText="Delete Team"
      subHeading="Users with content assigned via branches will no longer have access to that content."
      handleCancel={this.cancelContentDeleteDialog}
      handleOk={() => this.handleDelete(this.state.DeleteId)}
    />
     }
       <AlertSuccess
            data={{
              show:this.state.CreateTeam?this.state.CreateTeam: this.state.deletealert.isOpen,
              msg: this.state.CreateTeam?"Team Created":this.state.deletealert.msg,
              type: this.state.deletealert.type,
              vertical: "top",
              horizontal: "right",
              showThumb:false
            }}
          />
  <AlertSuccess
            data={{
              show: this.state.showTeamToasts.open,
              msg:this.state.showTeamToasts.mess,
              vertical: "top",
              horizontal: "right",
              showThumb:false
            }}
          />
      </>
    
    );
  }
}
const StyledContentWrapper = styled(Box)({
  position: "relative",
  display: "flex",
  width: "100%",

  "& .filterIconBox": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FAFAFA",
      width: "63px",
      borderRadius: "8px",
      borderTopLeftRadius: "8px !important",
      borderBottomLeftRadius: "8px !important",
      cursor: "pointer",
      border: "1px solid #C9C6C5",
      position: "relative",
      zIndex: 999,
      left: "-1%"
  },
  "& .filterIconBox:hover": {
      borderColor: '#652786'
  },
  "& .allBorder": {
      borderColor: '#652786',
      backgroundColor: "#EEE8F2",
  }
});
export const DialogWrapper=styled(Dialog)({
  "& .MuiDialog-paper":{  
        maxWidth: '585px',
        width:"100%",
        position:"relative",
        overflow:"visible"
  },
"& .close_icon":{
    position:"absolute",
    top:"-8px",
    right:"-8px",
    cursor:"pointer"
  },
"& .headingStyle": {
    color: "#000",
    fontSize: "24px",
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "32px",
    fontWeight: 600,
    letterSpacing: "-0.12px",
    textTransform: "capitalize"
},
"& .MuiPaper-rounded":{
  borderRadius: '16px',
},

"& .btnWrapper": {
  display: "flex",
  justifyContent: 'space-between',
  padding: "24px 32px",
  borderTop: "0px",
  gap:"20px",
  boxShadow: "0px -7px 20px 0px #00000011",
  '& button': {
      fontSize: '16px',
      fontWeight: 600,
      borderRadius: '12px',
      height: "42px",
      width:"100%"
  }
},

"& .primaryBtnStyle": {
  background: "#652786",
  marginLeft:"0px !important",
  '&:hover': {
    backgroundColor: '#652786',
},
  "text-transform": "none",
  "color": "white",
 
},
"& .cancelBtn": {
  border:"1px solid #E47004",
  color: '#E47004',
  background: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  textTransform: "capitalize",
  borderRadius:"12px",
},
"& .branch_head":{
  fontSize: '18px',
  fontWeight: 600,
  color:"#1C1B1B",
  marginTop:"0px"
},
"& .subHeading": {
  "& .branch_subhead": {
    fontSize: "16px",
    fontWeight: 400,
    padding: "10px 35px",
    margin: 0,
    position: "relative",
    "&::before": {  
      position: "absolute",
      content: '""', 
      width: "5px",
      height: "5px",
      background: "#000",
      borderRadius:"100%",
      top: "50%",  
      left: "20px", 
      transform: "translateY(-50%)" 
    }
  },
  
  "& .row_select": {
    backgroundColor: "#EEE8F2 !important",
    borderLeft: "1.5px solid #6C328B",
    color: "#6C328B",
    position: "relative",
    "&::before": {  
      position: "absolute",
      content: '""', 
      width: "5px",
      height: "5px",
      background: "#6C328B !important",
      borderRadius:"100%",
      top: "50%",  
      left: "20px", 
      transform: "translateY(-50%)" 
    }
  }
}

})
const ButtonWrapper = styled(Box)({
 display:"flex",
 justifyContent:"center",
 alignItems:"center",
 "& .btns": {
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  gap:"10px",
  width:"228px",
  height:"56px",
  backgroundColor: "#652786 !important",
  cursor:"pointer",
  color:"#fff",
  border:"none",
  borderRadius: "16px",
  fontFamily: "'Poppins', sans-serif" as const,
  fontSize: "16px",
  fontWeight: 600 as const,
  textTransform: "capitalize" as const,
},

});
const BadgeComponent = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
      top: "-14px",
      left: "20px",
      right: " 0px",
      backgroundColor: " #FF883F",
      fontSize: "81px",
      width: "10px",
      height: "10px",
      borderRadius: "20px"
  }
});
const TableMainWrapper = styled(Box)({
  width: "100%",
  marginTop:"30px",
  "& .tr":{
      borderRadius:"12px",
  },
  "& .td":{
      textAlign:"start"
  },
  "& .table tbody .tr":{
    cursor:"pointer"
},
"& .table thead .th:first-child":{
  paddingLeft:"20px",
},
  "& .td:first-child":{
     paddingLeft:"20px",
     borderBottomLeftRadius: "12px",
     borderTopLeftRadius: "12px",
     width:"23%",
     minWidth:"250px"
  },
  "& .td:last-child":{
      paddingRight:"20px",
      display:"flex",
      justifyContent:"flex-end",
      borderBottomRightRadius: "12px",
      borderTopRightRadius: "12px"
    },
    "& .td:nth-child(2n)":{
      width:"23%",
      minWidth:"250px"
    },
    "& .td:nth-child(3n)":{
      width:"23%",
      minWidth:"250px"
    },
   
    "& .td:nth-child(4n)":{
      paddingLeft:"20px",
      width:"20%",
      minWidth:"250px"
    },
    "& .table tbody .rowBg":{
    backgroundColor:"#EEE8F2 !important",
    },
 "& .BackgroundCss":{
      background:"#EEE8F2"
  },
  "& .headingLabel":{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
   textAlign:"start",
    "@media(max-width:600px)":{
      fontSize: "18px",
      minWidth:"140px"
    }, 
  },
  "& .arrowIconsContainer":{
    display: "flex",
    flexDirection: "column",
    margin: "3px 0px 0px 10px",
    "& > img": {
      cursor: "pointer",
    },
    "& > img:first-child": {
      marginBottom: 5,
    },
  },
  "& .action_wrap":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    gap:"20px"
  },
  "& .branch_text":{
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width:"200px !important",
    display: "inline-block"
  }
}) 
const  HeadingBranch =styled(Box)({
  display:"flex",
  flexDirection:"column",
  paddingLeft:"20px",
  justifyContent:"center",
  gap:"5px",
  width:"304px",
  height:"72px",
  backgroundColor: "#fff !important",
  cursor:"pointer",
  color:"#2B2E3CCC",
  border:"none",
  borderRadius: "16px",
  fontFamily: "'Poppins', sans-serif" as const,
  fontSize: "16px",
  fontWeight: 700 as const,
  textTransform: "capitalize" as const,
  boxShadow:  "6px 6px 25px 0px #E5E5E580", 
"& .main_head":{
  margin:0
},"& .sub_head":{
  margin:0,
  color:"#2B2E3C99",
  fontWeight:500
}
})
const AddImageComponents = styled("img")({
  macWidth:"100%",
  height:"auto",
});

const   AddIconNotFoundBoxs = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%"
});

const AddIconInnerBoxs = styled(Box)({
  marginTop: "40px"
});

const AddIconNotFoundTexts = styled(Typography)({
  marginTop: "26px",
  color: "#1C1B1B !important",
  textAlign: "center",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "-1px"
});

  const HeadingWrapper=styled(Box)({
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    '@media (max-width: 1100px)': {
       flexDirection:"column",
       alignItems:"flex-start",
       gap:"10px"
    },
    "& .advance_main":{
      '@media (max-width: 800px)': {
        paddingLeft:"20px"
     },
    }
  })

// Customizable Area End
