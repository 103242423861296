// Customizable Area Start
import React from "react";
import { Box, Button, Grid, TextField, Checkbox, Typography, Divider, IconButton, Modal, Slider, Tooltip } from "@material-ui/core";
import { styled, withStyles, } from "@material-ui/core/styles";
import { CheckBoxOutlineBlank, CheckBox, CancelOutlined, Search } from '@material-ui/icons';
import * as yup from "yup";
import CustomFormController, {
  Props, Tag, configJSON,
} from "./CustomFormController.web";
import { Formik } from "formik";
import { icon_upload, addTgumbnail,uploadError,errorIcon, choseLibraryIcon, videoIcon} from "./assets";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DeleteModal from "../../../components/src/DeleteDialog/DeleteModal";
import Commonmanagement from "../../imagemanagement2/src/Commonmanagement.web"
import DragAndDropFileUploadWithProgress from "../../../components/src/DragAndDropFileUploadWithProgress";
import './CustomForm1.css';
import PickContentFromLibraryModal from "../../../components/src/DeleteDialog/PickContentModal";
import { areAllTruthy } from "./ultils";
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from "react-player";
import VolumeOffOutlinedIcon from '@material-ui/icons/VolumeOffOutlined';
import { backwardIcon, collapseIcon, expandIcon, forwardIcon, pauseIcon, playIcon, startIcon, volumeIcon } from "../../../../packages/blocks/Library2/src/assets";
import { Document, Page } from "react-pdf";
import { FaMusic } from "react-icons/fa";

export class CustomLessonForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
  }
  
  renderLabel = () => {
    const { contentType } = this.state;
    switch (contentType) {
      case 'video':
        return 'Video Name*';
      case 'audio':
        return 'Audio Name*';
      default:
        return 'Document Name*';
    }
  };
  fileUploadFunction=(setFieldTouched:any,feildName:string,values:any)=>{
    return(
      <>
        <Box>
               <Grid>
                            <DragAndDropFileUploadWithProgress
                             data-test-id="drag_and_drop_id"
                              isBulkUploading
                              type={this.state.contentType}
                              selectedThumbnailFile={this.DeleteFile}
                              image={icon_upload}
                              errorImage={uploadError}
                              onSelectFile={this.dnDfiles}
                              setFieldTouched={()=>setFieldTouched(feildName,true,true)}
                              selectedFile={this.state.attachedFile || this.state.onEditAttachedFile}
                              progress={this.state.uploadContentProgress}
                              uploadStatus={this.state.uploadContentStatus}
                              navigation={this.props.navigation}
                              uploadingMessage={"This can take a few minutes depending on the file size and connection speed"}
                            />
                          </Grid>
                        </Box>
      </>
    )
  }
  fileNameClass=(values:any,classes:any)=>{
    if(values.name){
      return `whiteBg ${classes.InputStyle}` 
    }else{
      return `pinkBg ${classes.InputStyle}`
    }
  }

  fileDescriptionClass=(values:any)=>{
    if(values.description){
      return  `whiteBg` 
    }else{
      return `pinkBg`
    }
  }
 

  contentUpload = (classes: any, handleBlur: any, handleChange: any, setFieldValue: any, values: any, touched: any, errors: any) => {
   
    return (
      <Formik
      enableReinitialize={true}
        initialValues={{ link: values?.link }}
        validationSchema={yup.object().shape({
          link: yup
            .string()
            .test('is-youtube-link', 'This is not a valid YouTube link', function (value) {
              const youTubeUrlRegExp = /^(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))([a-zA-Z0-9_-]{11})([?&][^\s#]*)?$/;
              const isValid = youTubeUrlRegExp.test(value);
              return isValid || this.createError({ path: this.path, message: 'This is not a valid YouTube link' });
            }),
        })}
        onSubmit={(values) => {
          // Handle form submission
        }}
      >
        {({ handleBlur, handleChange, setFieldValue, values, touched, errors, validateForm, setTouched }) => {
          const hasError = touched.link && Boolean(errors.link) && !this.state.isLinkValid && (values.link !== "");
  
          return (
            <>
              {this.state.contentType === "video" && !this.state.attachedFile && !this.state.onEditAttachedFile  ? (
                <Grid container className={classes.contentDiv}>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{
                      height: "262px",                      
                      border: hasError ? '2px dashed #E62345' : '2px dashed #929090',
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      padding: "32px 32px 0 32px",
                      borderRadius: "8px",
                      backgroundColor: hasError ? '#FFF5F5' : '',
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "22px",
                          fontWeight: 600,
                          lineHeight: "33px",
                          color: "#1C1B1B",
                          textAlign: "left",
                        }}
                      >
                        {configJSON.enterLinkText}
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "12px",
                          fontWeight: 400,
                          color: "#1C1B1B",
                          lineHeight: "18px",
                          textAlign: "left",
                        }}
                      >
                        {configJSON.pasteLinkText}
                      </Typography>
                    </div>
                    <Grid
                      data-test-id="openBackModalID"
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        name="link"
                        data-test-id="youtube_link_form_id"
                        fullWidth
                        style={{
                          height: "40px",
                          paddingRight: "12px",
                          paddingLeft: "0px",
                          fontSize: "16px",
                        }}
                        onChange={(event) => this.handleLinkChange(setFieldValue, setTouched, event)}
                        InputProps={{
                          style: {
                            borderColor: errors.link && touched.link && !this.state.isLinkValid && values.link !== "" ? '#f44336' : '',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: '8px',
                            backgroundColor:this.state.setLink ? "#FFFFFF": "#FFF7FF",
                            maxWidth: '223px',
                            minHeight: '40px',
                            padding: '0 12px',
                          },
                        }}
                        helperText={
                          touched.link && errors.link && values.link !== "" && (
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              <img src={errorIcon} alt="Error Icon" style={{ marginRight: 4, color: "#EF2B4B" }} />
                              <Typography variant="caption" style={{ color: "#EF2B4B" }}>
                                {errors.link}
                              </Typography>
                            </span>
                          )
                        }
                        onBlur={handleBlur}
                        defaultValue={values?.link}
                        className="input-field"
                        value={values?.link}
                        placeholder="Enter your link here"
                      />

                      <Box sx={continueButtonContainer}>
                        <HeaderContainer
                         style={(this.isDisableAddVideo()) ? webStyle.enableBTns : {}}
                          data-test-id="handleAddLinkButton"
                          type="submit"
                          disabled={!this.isDisableAddVideo()}
                          onClick={() => {
                            setTouched({ link: true }); // Mark the field as touched
                            validateForm(); // Trigger validation manually
                            this.validateYouTubeUrl(setTouched); // Pass setTouched to your custom validation logic
                          }}
                        >
                          Add Video
                        </HeaderContainer>
                      </Box>
                    </Grid>
                  </Grid>
  
                  <Grid item xs={12} md={6} className={classes.outlinedBox} onClick={this.handleOpenClosePickContentFromLibraryModal} data-test-id="imgOpenModal">
                    <img src={choseLibraryIcon} style={{ width: "56px", height: "56px" }} alt="Icon"  />
                    <Typography
                      style={{
                        fontFamily: "'Poppins', sans-serif",
                        fontSize: "22px",
                        fontWeight: 600,
                        lineHeight: "33px",
                        textAlign: "left",
                      }}
                    >
                      {configJSON.chooseLibraryText}
                    </Typography>
                  </Grid>
                </Grid>
              ) 
              : (
                <Box>
                  {(!this.state.attachedFile && !this.state.onEditAttachedFile) && (
                    <Grid item xs={12} md={12} className={classes.outlinedBox} onClick={this.handleOpenClosePickContentFromLibraryModal} data-test-id="imgOpenModal">
                      <img src={choseLibraryIcon} style={{ width: "56px", height: "56px" }} alt="Icon" />
                      <Typography
                        style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "22px",
                          fontWeight: 600,
                          lineHeight: "33px",
                          textAlign: "left",
                        }}
                      >
                        {configJSON.chooseLibraryText}
                      </Typography>
                    </Grid>
                  )}
                </Box>
              )
              }
            </>
          );
        }}
      </Formik>
    );
  }
  
  showThumbnailrender=(values:any)=>{
   return(
    <>
       <div style={{ display: "flex", gap: "20px", width: "270px" }}>
                              <Button data-test-id="thumbnailButton" className="changethumb-btn" style={{background:this.getDisabledColor("")}}  disabled={this.isDisableContent()}  onClick={
                                   this.handleOpenOptionPrompt
                                  }>
                                <img style={{ paddingRight: "9px" }} src={addTgumbnail} />
                                Add Thumbnail
                              </Button>
                              <Box className={values.thumbnail? "":"thumbpreview"}>
                                {values.thumbnail ? <img data-test-id="thumbnailImg" src={values.thumbnail} alt="Thumbnail"  className={values.thumbnail?"thumbnailImg":"thumbnailwidth"} /> : <div></div>}
                              </Box>
                            </div>
    </>
   )
  }
  getInitialValues=()=>{
    const { lessonData} = this.state;
   return({
      name: lessonData.title || lessonData.name || "",
      description: lessonData.description || "",
      file: lessonData.file || "",
      videoName: lessonData.videoName || "",
      link: lessonData.link || "",
      tags: lessonData.tags || "",
      content_description: lessonData.content_description || "",
      thumbnail: this.props.location?.state?.thumbnail?.attributes.image.url || lessonData?.thumbnail || ""
    })
  }
  getSubmitButton=(touched:any,values:any,submitForm:any)=>{
    if(this.props.navigation.getParam('type')=== "addLesson"){
      return (
        <HeaderContainer 
        style={{cursor:"pointer",...this.isDisableStyle(webStyle)}}
        data-test-id="handleSubmitButton" type="submit"  
        disabled={this.isDisable()} 
        onClick={() => {
          submitForm(); // this is called to apply validation on submit 
           this.createLesson(values, touched)
           }}>
            Add Lesson
        </HeaderContainer>
           
      )
    }else{
      return(
        <HeaderContainer 
        style={{cursor:"pointer",...this.isDisableSaveButtonStyle(touched,webStyle)}}  
        data-test-id="handleEditSubmitButton" 
        type="submit"  
        disabled={this.isDisableSaveButton(touched)} 
        onClick={()=>{
          submitForm(); // this is called to apply validation on submit 
          this.handleSaveEditLessonModalOpenClose(values, touched);
        }}>
          Save Lesson
        </HeaderContainer>
      )
    }                       
  }

  rederChoosenFile() {
    if(!Boolean(this.state.selectedContentId)) {
      return this.renderFileAccepted()
    }

    const selectedContentInfo = this.state.contentUserData.find(content => content.id.toString() === this.state.selectedContentId)

    return (
      <>
        <div className="selectedFileDetailsContainer">
          <img src={videoIcon} alt="Selected File" className="video-icon" />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
             <p className="acceptedFileMessage">File accepted</p>
             <p style={{
            marginBottom: 0,
            marginTop: "10px",
            fontSize: "14px",
            fontWeight: 400,
            color: "#787776",
            wordBreak: "break-all",
            lineHeight: "22px",
            fontFamily: "'Poppins', sans-serif",
          }}>
              {selectedContentInfo?.attributes.title}
          </p>
          </div>
          <Button
          style={{
          width: "186px",
          height: "56px",
          padding: "20px 32px 20px 32px",
          gap: "10px",
          borderRadius: "16px",
          border: "1px solid #E47004",
          opacity: "0px",
          color: "#E47004",
          textTransform: "capitalize",
          fontFamily: "'Poppins, sans-serif",
          fontWeight: 700,
          fontSize: "14px"
        }}
        onClick={this.handleChangeLinkClick}
      >
        Change
      </Button>
        </div>

      {this.state.isOpenFileChangeModal &&  
      <DeleteModal
      data-test-id='delete-all-content'
      openDialog={this.state.isOpenFileChangeModal}
      headingText={`Change File`}
      btnCancelText="Cancel"
      btnOkText="Change"
      subHeading={this.props.navigation.getParam("type")==="editLesson"?"Are you sure you want to unlink the content?": "Are you sure you want to change the file? The current one will be cleared."}
      handleCancel={this.fileChange}
      handleOk={this.fileAccepted}
    />}
      </>
    )
  }
 nameField ( values:any, handleChange:any, handleBlur:any, touched:any, errors:any, classes:any) {
    return (
    <Grid item xs={12} md={12} className={classes.formStyle}>
      <label className={classes.lableStyle}>Name*</label>
      <TextField
        name="name"
        onBlur={handleBlur}
        className={values.name ? "whiteBg" : "pinkBg"}
        placeholder="Enter title"
        value={values.name}
        data-test-id="name"
        error={touched.name && Boolean(errors.name)}
        helperText={touched.name && errors.name}
        variant="outlined"
        style={{ background: "#FCF8F8" }}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          handleChange(event)
          this.handleChangeFormValue(event)
        }}
      />
    </Grid>
  )};

  ValueLabelComponent(props:any) {
    const { children, open, value } = props;
  
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

  videoComponent = () => {
    const {contentItemDetail, isVideoPlaying, playbackRate} = this.state;

    return (
      <VideoPlayerBox
        className={"videoComponentid"}
        ref={this.videoContainerRef}
        data-test-id="videoPlayerId"
        onMouseLeave={this.mouseLeaveHandler}
        onMouseEnter={this.mouseEnterHandler}
      >
        <ReactPlayer
          data-test-id="reactPlayerId"
          ref={this.videoPlayerRef}
          onProgress={this.progressHandler}
          playing={isVideoPlaying}
          onDuration={this.durationHandler}
          url={contentItemDetail?.data?.attributes?.attachment?.url}
          onEnded={this.videoEndHandler}
          muted={this.state.isVideomute}
          playbackRate={playbackRate}
          controls={contentItemDetail?.data?.attributes?.attachment?.url?.includes("www.youtube")}
        />
        {!contentItemDetail?.data?.attributes?.attachment?.url?.includes("www.youtube") && <div style={webStyle.videoControlSection}>
          <img className="cursor" data-test-id="backWardId" src={backwardIcon} onClick={this.seekBarwardHandler} />
          <img className="cursor" data-test-id="playPauseId" style={{ width: "24px", height: "24px" }} src={isVideoPlaying ? pauseIcon : startIcon} onClick={this.toggleVideoHandler} />
          <img className="cursor" data-test-id="forwarId" src={forwardIcon} onClick={this.seekFrowardHandler} />
          <select
            id="speedSelect"
            value={playbackRate}
            data-test-id="speedSelect"
            style={webStyle.selectBox}
            onChange={this.speedChangeHandler}
          >
            {this.state.speedData.map((speed,index) => <option value={speed} key={index}>{`${speed}x`}</option>)}
          </select>
          <Slider
            ValueLabelComponent={this.ValueLabelComponent}
            defaultValue={0}
            data-test-id="sliderchangeId"
            value={this.state.videoCurrentTime}
            max={this.state.videoDuration || 0}
            style={{ margin: "0 5px" }}
            onChange={this.seekChangeHandler}
          />
          <Typography style={{ color: "white" }}>{this.timeFormatter(this.state.videoCurrentTime)}</Typography>
          {this.state.isVideomute ? <VolumeOffOutlinedIcon style={{ fontSize: "24px", color: "white" }} onClick={this.toggleMuteHandler} /> : <img className="cursor" data-test-id="toggleMute" src={volumeIcon} onClick={this.toggleMuteHandler} />}
          <img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreenView ? collapseIcon : expandIcon} onClick={this.fullScreenToggleHandler} />
        </div>}
        {(!contentItemDetail?.data?.attributes?.attachment?.url?.includes("www.youtube") && (!isVideoPlaying || this.state.isActionButtonShow)) && <Box style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          display: "flex",
          transform: "translate(-50%, -50%)",
          alignItems: "center",
          justifyContent: "center",
          gap: "20px",
          width: "auto",
          color: "white",
        }}>
          <img
            className="cursor"
            src={isVideoPlaying ? pauseIcon : playIcon}
            style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }}
            onClick={this.toggleVideoHandler}
          />
        </Box>
        }
      </VideoPlayerBox>
    )
  }


  renderDocumentSection = () => {
    return (
      <Box style={webStyle.downloadFileSectionCss}>
        <Typography style={webStyle.sorryText}>{configJSON.sorryMsg}</Typography>
        <Typography style={{ ...webStyle.sorryText, fontWeight: 400 }}>Click <span data-test-id="downloadBtn" style={{ ...webStyle.sorryText, cursor: "pointer" }} onClick={this.handleDownloadFile}>here</span> to download</Typography>
      </Box>
    )
  }

  renderPdfComponent = () => {
    const {contentItemDetail} = this.state;
    return (
      <Box className={this.state.isFullScreenView ? "pdfDialogBox" : ""}>
        <div
          ref={this.videoContainerRef}
          className={"documentConteiner"} style={{ position: "relative", display: "flex", justifyContent: "center", height: "482px" }}>
          <Box className="pdfDocumentBox" style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Document
              data-test-id="DocumentComponent"
              file={contentItemDetail?.data?.attributes?.attachment?.url}
              onLoadSuccess={this.onDocumentLoadSuccessHandler}
            >

              <Page pageNumber={this.state.pdfActivePage} />
            </Document>
          </Box>
          <div style={{
            background: "#1C1B1B", color: "white", position: "absolute", bottom: 0, width: "100%", height: "44px",
            display: "flex", justifyContent: "space-between", alignItems: "center"
          }}>
            <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", width: "56%", gap: "10px", height: "100%" }}>
              <Typography data-test-id="prevBtnId" className="cursor" style={{ paddingBottom: "5px" }} onClick={() => this.state.pdfActivePage >= 1 && this.setPageNumberHandler(this.state.pdfActivePage - 1)}>
                ❮
              </Typography>
              <span style={{ fontWeight: 600, fontSize: "18px" }}>{this.state.pdfActivePage}</span>/<span style={{ fontWeight: 400, fontSize: "16px" }}>{this.state.pdfTotalPages}</span>
              <Typography data-test-id="nextBtnId" className="cursor" style={{ paddingBottom: "5px" }} onClick={() => this.state.pdfActivePage < this.state.pdfTotalPages && this.setPageNumberHandler(this.state.pdfActivePage + 1)}>
                ❯
              </Typography>
            </Box>
            <Box style={{ marginRight: "10px" }}>
              <img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreenView ? collapseIcon : expandIcon} onClick={this.fullScreenToggleHandler} />
            </Box>
          </div>
          <div style={{
            color: "black", position: "absolute", top: "40%", width: "100%", height: "44px",
            display: "flex", justifyContent: "space-between", alignItems: "center", gap: "16px"
          }}>
            <button data-test-id="prevBtnTestId" disabled={this.state.pdfActivePage <= 1} className="carousel-button prev" onClick={() => this.setPageNumberHandler(this.state.pdfActivePage - 1)}>
              ❮
            </button>
            <button data-test-id="nextBtnTestId" disabled={this.state.pdfActivePage === this.state.pdfTotalPages} className="carousel-button next" onClick={() => this.setPageNumberHandler(this.state.pdfActivePage + 1)}>
              ❯
            </button>
          </div>
        </div>
      </Box>
    )
  }

  imagePreview = () => {
    const {isFullScreenView, contentItemDetail} = this.state;
    return (
      <div
        ref={this.videoContainerRef}
        style={{ ...webStyle.mainImg_box, alignItems: isFullScreenView ? "center" : "start" }}>
        <img data-test-id="imageid" src={contentItemDetail?.data?.attributes?.attachment?.url} style={{ ...webStyle.main_courseImg, maxHeight: isFullScreenView ? "100%" : "406px" }} alt="img" />
        <div style={{
          background: "#1C1B1B", color: "white", position: "absolute", bottom: 0, width: "100%", height: "44px",
          display: "flex", justifyContent: "flex-end", alignItems: "center", paddingRight: "10px"
        }}>
          <img className="cursor" data-test-id="fullScreenVideo" src={isFullScreenView ? collapseIcon : expandIcon} onClick={this.fullScreenToggleHandler} />
        </div>
      </div>
    )
  }


  audioComponent = () => {
    const {contentItemDetail} = this.state;

    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          background: "black"
        }}
        ref={this.videoContainerRef}
        data-test-id="videoPlayerId"
        onMouseEnter={this.mouseEnterHandler}
        onMouseLeave={this.mouseLeaveHandler}
      >
        <Box style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", height: "100%" }}>
          {contentItemDetail.data.attributes.thumbnail ? <img style={{ maxHeight: "100%", maxWidth: "100%" }} src={contentItemDetail?.data?.attributes?.thumbnail?.url} /> : <FaMusic fontSize="200px" color="white" />}
        </Box>
        <ReactPlayer
          ref={this.audioRef}
          data-test-id="audioReactPlayer"
          onDuration={this.audioDurationHandler}
          url={contentItemDetail?.data?.attributes?.attachment?.url}
          playing={this.state.isAudioPlaying}
          onProgress={this.audioProgressHandler}
          onEnded={this.audioEndHandler}
          playbackRate={this.state.playbackRate}
          muted={this.state.isAudioMuted}
          width="100%"
          height="50px"
        />
        <div style={{
          position: "absolute", bottom: 0, left: 0, backgroundColor: "#1C1B1B", width: "100%", height: "44px", display: "flex", alignItems: "center",
          gap: "8px", padding: "0 8px"
        }}>
          <img className="cursor" data-test-id="backWardId" src={backwardIcon} onClick={this.seekBackwardAudioHandler} />
          <img className="cursor" data-test-id="playPauseId" style={{ width: "24px", height: "24px" }} src={this.state.isAudioPlaying ? pauseIcon : startIcon} onClick={this.playToggler} />
          <img className="cursor" data-test-id="forwarId" src={forwardIcon} onClick={this.seekForwardAudioHandler} />
          <select
            value={this.state.playbackRate}
            id="speedSelect"
            data-test-id="speedSelect"
            onChange={this.speedChangeHandler}
            style={webStyle.selectBox}
          >
            {this.state.speedData.map((speed,index) => <option key={index} value={speed}>{`${speed}x`}</option>)}
          </select>
          <Slider
            step={1}
            ValueLabelComponent={this.ValueLabelComponent}
            data-test-id="sliderchangeId"
            max={this.state.audioLessonTotalDuration || 0}
            value={this.state.audioActiveTime}
            onChange={this.sliderChangeHandler}
            style={{ margin: "0 5px" }}
          />
          <Typography style={{ color: "white" }}>{this.timeFormatter(this.state.audioActiveTime)}</Typography>
          {this.state.isAudioMuted ? <VolumeOffOutlinedIcon data-test-id="toggleMute" style={{ fontSize: "24px", color: "white" }} onClick={this.toggleAudioMuteHanlder} /> : <img className="cursor" data-test-id="toggleMute" src={volumeIcon} onClick={this.toggleAudioMuteHanlder} />}
          <img className="cursor" data-test-id="fullScreenVideo" src={this.state.isFullScreenView ? collapseIcon : expandIcon} onClick={this.fullScreenToggleHandler} />
        </div>
        {(!this.state.isAudioPlaying || this.state.isActionButtonShow) && <Box style={{
          top: "50%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          left: "50%",
          justifyContent: "center",
          display: "flex",
          width: "auto",
          alignItems: "center",
          color: "white",
          gap: "20px",
        }}>

          <img
            className="cursor"
            src={this.state.isAudioPlaying ? pauseIcon : playIcon} style={{ width: "87px", height: "87px", cursor: "pointer", margin: "0" }}
            onClick={this.playToggler}
          />
        </Box>
        }
      </div>
    )
  }

  renderComponentOnPopUp = () => {
    const {contentItemDetail} = this.state;
    const fileExtension = contentItemDetail?.data?.attributes?.attachment?.url && contentItemDetail?.data?.attributes?.attachment?.url.substring(contentItemDetail?.data?.attributes?.attachment?.url.lastIndexOf('.') + 1);
  
    if(contentItemDetail?.data?.attributes?.content_type === "video"){
      return this.videoComponent()
    }else if(contentItemDetail?.data?.attributes?.content_type === "document"){
       if(contentItemDetail?.data?.attributes?.file_extension && contentItemDetail?.data?.attributes?.file_extension !== "application/pdf" && contentItemDetail?.data?.attributes?.file_extension.includes("application")){
        return this.renderDocumentSection()
      }else if(contentItemDetail?.data?.attributes?.file_extension && contentItemDetail?.data?.attributes?.file_extension.includes("image")){
          return this.imagePreview()
      }else if(fileExtension && fileExtension === "pdf"){
        return this.renderPdfComponent()
      }
    }else if(contentItemDetail?.data?.attributes?.content_type === "audio"){
      return this.audioComponent()
    }
  }

  renderPreviewModal = () => {
    const {contentItemDetail} = this.state
    return (
      <Modal
        onClose={()=>this.closePreviewModal()}
        aria-labelledby="custom-modal-title"
        aria-describedby="custom-modal-description"
        open={this.state.isPreviewOpen}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: "10px",
          borderRadius: '20px'
        }}
        data-test-id="preview_modal"
      >
        <ResponsiveBox>
          <Box
            style={{
              backgroundColor: '#FFF',
              height: "100%",
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              minHeight: 0,
              overflow: "hidden"
            }}
          >
            <StyledIconButton data-test-id="close_icon" onClick={()=>this.closePreviewModal()} ><CloseIcon /></StyledIconButton>
            <Typography variant="h4" id="custom-modal-title" gutterBottom className="modalTitle">
              {contentItemDetail?.data?.attributes?.title}
            </Typography>

            <ScrollableContainer>
              <Box width="100%" height="450px" bgcolor="lightgrey" mb={2}>
                {this.renderComponentOnPopUp()}
              </Box>
              <Box mt={2}>
                <ExpertNameComponent variant="h5" gutterBottom>
                  {contentItemDetail?.data?.attributes?.expert_name} | <span title={contentItemDetail?.data?.attributes?.tags?.length > 1 ? contentItemDetail?.data?.attributes?.tags?.map((tagObject: { name: string; }) => tagObject.name).join(", ") : ""}>{contentItemDetail.data?.attributes?.tags?.length > 1 ? `${contentItemDetail.data?.attributes?.tags?.[0]?.name} & ${contentItemDetail.data?.attributes?.tags?.length - 1} more` : contentItemDetail?.data?.attributes?.tags?.map((tagObject: { name: string; }) => tagObject.name).join(", ")}</span>
                </ExpertNameComponent>
                <Typography variant="body1" gutterBottom>
                  Created on: {contentItemDetail?.data?.attributes.created_at
                    ? this.formatDateModal(contentItemDetail?.data?.attributes.created_at)
                    : "-"}
                </Typography>
              </Box>
              <Box sx={{ marginTop: "15px" }}>
                <DescriptionHeading variant="h5" gutterBottom>
                  Video description
                </DescriptionHeading>
                <DescriptionComponent variant="body1" id="custom-modal-description">
                  {this.state.showFullDescription ? contentItemDetail?.data?.attributes?.description : `${contentItemDetail?.data?.attributes?.description?.slice(0, 100)}`}
                  {!this.state.showFullDescription && (
                    <button data-test-id="btn_toggle-des" style={{ color: "#6C328B", backgroundColor: "#FFF", border: "none", fontFamily: "Poppins, sans-serif", fontSize: "16px", fontWeight: 400 }} onClick={this.toggleDescription}>Read More...</button>
                  )}
                </DescriptionComponent>
              </Box>

              {contentItemDetail.data?.attributes?.lessons?.length > 0 && <Box sx={{ marginTop: "15px" }}>
                <Typography style={{
                  fontWeight: 600, fontSize: "18px"
                }}>Lessons that use this object</Typography>
                <ul>
                  {contentItemDetail.data?.attributes?.lessons?.map((lesson) => (
                    <li style={{ color: "black", fontWeight: 400 }}>{`Course: ${lesson.course_title} > Lesson: ${lesson.lesson_title}`}</li>
                  ))}
                </ul>
              </Box>}
            </ScrollableContainer>

            <FixedFooter>
              <Box p={2} display="flex" justifyContent="flex-end">
                <ResponsiveButtonSecond
                  data-test-id="editContent"
                  variant="contained"
                  color="secondary"
                  onClick={()=>{
                    this.closePreviewModal();
                    this.handleSelectedContentFromLibrary(contentItemDetail.data.id.toString())
                  }}
                >
                  Select Content
                </ResponsiveButtonSecond>
              </Box>
            </FixedFooter>
          </Box>

        </ResponsiveBox>
      </Modal>
    )
  }
  render() {
    const { classes } = this.props;
    const {isLinkValid} = this.state;
    const isChoosenFile = isLinkValid || Boolean(this.state.selectedContentId)

     return (
      <>
        <Box>
          <Formik
          // validateOnBlur={true}
            enableReinitialize={true}
            innerRef={formik => { this.formikRef = formik; }}
            data-test-id="createLessonForm"
            initialValues={this.getInitialValues()}
            onSubmit={()=>{}}
            validationSchema={yup.object().shape({
              name: yup
                .string()
                .required("Name is required")
                .min(5, "min 5 characters required")
                .max(50, "Name can't have more than 50 characters"),
              videoName:  this.state.selectedContentId ? yup.string() : 
              yup.string()
                .required("Name is required")
                .min(5, "min 5 characters required")
                .max(50, "max 50 characters required"),
              content_description: yup
                .string()
                .max(500, "Description is too long (Max 500 character allowed)"),
              description: yup
                .string()
                .max(500, "Description is too long (Max 500 character allowed)"),
              thumbnail: yup
                .string()
                .required("Thumbnail is required"),
              tags: yup
                .string()
                .required("Tags is required"),

            })}
          >
            {({submitForm, values, handleChange, handleSubmit, validateForm, touched, errors, handleBlur, setFieldValue,setFieldTouched }) => {
              return (
                  <Grid>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={gridStyle}>

                     { this.nameField(values, handleChange, handleBlur, touched, errors, classes )}
                        <Grid item xs={12} md={12} className={classes.formStyle}>
                          <label className={classes.lableStyle}>
                            Description
                          </label>
                          <TextField
                            className={this.fileDescriptionClass(values)}
                            fullWidth
                            data-test-id="description"
                            name="description"
                            value={values?.description}
                            error={areAllTruthy(touched.description,Boolean(errors.description))}
                            helperText={areAllTruthy(touched.description , errors.description)}
                            onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                              handleChange(event)
                              this.handleChangeFormValue(event)
                            }}
                            onBlur={handleBlur}
                            InputProps={{
                              style: descInput,
                            }}
                            variant="outlined"
                            placeholder=" Please write your description here"
                            multiline
                            rows={10}
                            maxRows={12}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <Typography style={{ fontFamily: "'Poppins', sans-serif", fontSize: "12px", fontWeight: 400, lineHeight: "18px", textAlign: "left" }}>                           
                            {configJSON.descriptionBottomText}
                          </Typography>
                        </Grid>

                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ width: "100%", border: "1px solid #E2E5E1", height: "1px" }}></div>
                        <Typography style={{
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: "32px",
                          fontWeight: 700,
                          lineHeight: "48px",
                          letterSpacing: "-1px",
                          textAlign: "center",
                          padding: "10px"
                        }}>Content</Typography>
                        <div style={{ width: "100%", border: "1px solid #E2E5E1", height: "1px" }}></div>
                      </Box>

                      <Grid container style={gridStyle}>
                      {isChoosenFile ? (
                        this.rederChoosenFile()
                      ) : (
                        <>
                          {this.fileUploadFunction(setFieldTouched,"file",values)}
                          {this.contentUpload(
                           classes,
                           handleBlur,
                           handleChange,
                           setFieldValue,
                           values,
                           touched,
                           errors
                          )}
                        </>
                      )}    
                          <Box className={classes.tagCss}>
                          <Box style={thumbnailOuterBox}>
                            <label className={classes.lableStyle}>
                              Thumbnail*
                            </label>
                         {this.showThumbnailrender(values)}
                         {(touched.thumbnail && errors.thumbnail) && (
                            <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.thumbnail}</Typography>
                          )}
                          </Box>
                         
                          <Divider className={classes.dividerCss} />
                          <Grid container style={{display:"flex"}}>
                            <Grid item >
                              <label style={webStyle.labelText} >
                                Tags*
                              </label>
                              <Autocomplete
                                data-test-id="selectButtonID"
                                disabled={this.isDisableContent()}
                                className={classes.autoComplete}
                                options={this.state.tagsList}
                                freeSolo
                                value={values?.tags}
                                multiple
                                getOptionLabel={(option: any) => option?.attributes.name}
                                autoSelect
                                onChange={(event, newValue: Tag[]) => this.handleChange(setFieldValue, newValue)}
                                onBlur={handleBlur}
                                renderOption={(option: any, { selected }) => (
                                  <li>
                                    <Checkbox
                                      className="checkBox"
                                      disabled={this.isDisableContent()}
                                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                                      checkedIcon={<CheckBox fontSize="small" />}
                                      style={{ marginRight: 8, color: "#652786", textTransform: "capitalize" }}
                                      checked={selected}
                                      onBlur={handleBlur}
                                      color="primary"
                                    />
                                    {option.attributes.name}
                                  </li>
                                )}
                                renderInput={(params) => {
                                  const { InputProps, ...rest } = params;
                                  return (
                                    <TextField
                                      {...rest}
                                      className={classes.tagFieldCss}
                                      disabled={this.isDisableContent()}
                                      onBlur={handleBlur}
                                      variant="outlined"
                                      placeholder="Find a tag"
                                      name="tags"
                                      InputProps={{
                                        ...InputProps,
                                        style: { borderRadius: '8px', background: this.getDisabledColor("initial") },
                                        startAdornment: (
                                          <Search style={{ color: "" }} />
                                        )
                                      }}
                                    />
                                  )
                                }}
                              />
                                {(touched.tags && errors.tags) && (
                          <Typography style={{ fontSize: "0.75rem", marginTop: "3px" }} color="error">{errors.tags}</Typography>
                        )}
                            </Grid>
                            {values?.tags?.map((tagSelected: Tag, index: number) => {
                              return (
                                <Grid item key={index} style={{ alignItems: 'end', display: 'flex' }}>
                                  <Box
                                    style={{ cursor:"pointer",display: 'inline-flex',position:"relative", alignItems: 'center', width: '170px', justifyContent: 'center', margin: '5px', padding: '11px', border: '1px solid #652786', borderRadius: '12px' }}
                                  >
                                    <Typography variant="body1" style={{ fontSize: "14px", textTransform: "capitalize", lineHeight: "16px", fontWeight: 400, color: "#652786", fontFamily: "'Poppins', sans-serif" }}>{tagSelected?.attributes?.name}</Typography>
                                    <IconButton data-test-id={`removeTagButton-${index}`} style={{cursor:"pointer",position: "absolute", top: "-12px", right: "-12px"}} size="small" onClick={() => { setFieldValue("tags", values?.tags.filter((tagRemove: any) => tagRemove !== tagSelected)) }} disabled={this.isDisableContent()}>
                                      <CancelOutlined style={{  background: "#fff", borderRadius: "10px", color: "#652786" }} fontSize="small" />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              )
                            })}
                          </Grid>
                        
                        </Box>
                      
                        <Grid item xs={12} md={12} className={classes.formStyle}>
                          <label className={classes.lableStyle}>
                            {this.renderLabel()}
                          </label>
                          <TextField
                            name="videoName"
                            className={values.videoName ? `whiteBg ${classes.InputStyle}` : `pinkBg ${classes.InputStyle}`}
                            placeholder="Enter title"
                            error={touched.videoName && Boolean(errors.videoName)}
                            helperText={touched.videoName && errors.videoName}
                            defaultValue={values?.videoName}
                            value={values.videoName || this.state.contentItemDetail.data.attributes.title}
                            style={{ background: this.getDisabledColor("#FCF8F8") }}
                            id="bootstrap-input"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            disabled={this.isDisableContent()}
                            variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.formStyle}>
                          <label className={classes.lableStyle} style={{ display: "flex" }}>
                            Description&nbsp;<p style={{ color: "#ABAAAA", margin: "0" }}> - For internal use only</p>
                          </label>
                          <TextField
                            // className={values.content_description ? "whiteBg" : "pinkBg"}
                            fullWidth
                            id="_desc"
                            name="content_description"
                            disabled={this.isDisableContent()}
                            value={values?.content_description || this.state.contentItemDetail.data.attributes.description}
                            error={areAllTruthy(touched.content_description, Boolean(errors.content_description))}
                            helperText={areAllTruthy(touched.content_description , errors.content_description)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            InputProps={{
                              style: {
                                borderRadius: "8px",
                                outline:"none",
                                background: this.getDisabledColor("#FCF8F8")
                              },
                            }}
                            variant="outlined"
                            placeholder=" Please write your description here "
                            multiline
                            rows={10}
                            maxRows={12}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Grid data-test-id="openBackModalID" style={{ display: "flex", gap: "20px", justifyContent: "end", marginTop: "50px", alignItems: "center" }}>
                      <CancelDraftstyle  data-test-id="cancel_btn" onClick={this.handleBackModal}>Cancel</CancelDraftstyle>
                      <Box sx={continueButtonContainer}>
                        {this.getSubmitButton( touched,values,submitForm)
                        }
                        
                      </Box>
                    </Grid>
                  </Grid>
              )
            }}
          </Formik>
          {this.state.showThumbnailModal && 
      <>
      <Commonmanagement
      id="2"
      data-test-id="commonmanagement_id"
      navigation={this.props.navigation}
         open={this.state.showThumbnailModal}
         onClose={this.handleThumbnailModalClose}
        CloseModal={this.closeModals}
      />
       </>
      }
      <DeleteModal
          data-test-id='option-prompt'
          openDialog={this.state.showOptionPrompt}
          headingText={`Where do you want to select the thumbnail from?`}
          btnOkText="Thumbnail library"
          btnCancelText="Local storage"
          handleCancel={() => this.closeOptionPrompt()}
          handleOk={() => this.handleOpenThumbnails()}
          promptOption={true}
          handleOptionOne={() => this.handleAddThumbnailFromLocal(this.props?.location?.pathname)}
        />
    </Box>
        <DeleteModal
          data-test-id='delete-all-content'
          openDialog={this.state.goBackModal}
          headingText={this.props.navigation.getParam("type")==="editLesson"?"Are you sure you want to abandon your changes?":"Save to avoid losing changes"}
          btnCancelText="Leave"
          btnOkText="Keep Editing"
          handleCancel={this.handleSaveScreen}
          handleOk={this.handleModalClose}
          library={true}
        />
      <DeleteModal
          data-test-id='save-content-modal'
          openDialog={this.state.saveLessonModal}
          headingText="Are you sure you want to save changes?"
          btnCancelText="Cancel"
          btnOkText="Save"
          handleCancel={this.handleSaveEditLessonModalclose}
          handleOk={()=>this.createLesson(this.state.saveLessonValues, this.state.saveTouchedValues)}
          library={true}
        />

      <PickContentFromLibraryModal 
      isOpen={this.state.isOpenPickContentFromLibraryModal}
      onCloseModal={this.handleOpenClosePickContentFromLibraryModal}
      isLoading={false}
      sortHeader={(dataType:string, keyType:string, sortType:string)=>this.handleContentSorting(dataType, keyType, sortType)}
      handleSelectedContent={this.handleSelectedContentFromLibrary}
      data={this.state.contentUserData}
      handleListItemClick={this.handleListItemClickFilter}
      searchInputValue={this.state.searchContentValue}
      handleInputChange={this.handleInputChangeSearch}
      filterList={[]}
      isFilterDropActive={ Boolean(this.state.anchorFilterContentEl)}
      filterContentDropdownCloseHandler={this.filterContentDropdownCloseHandler}
      anchorFilterContentEl={this.state.anchorFilterContentEl}
      navigation={this.props.navigation}
      showContentLists={this.state.showContentList}
      creationDateChangeHandler={this.creationChangeHandlerDate} 
      dropdownHandlerOpen={this.dropdownHandlerOpen}
      handleClearFilter={this.handleClearFilter}
      handleChangeCalendar={this.handleChangeCalendar}
      dropdownCloseHandler={this.dropdownHandlerClose}
      handleCalendarOpen={this.handleOpenCalendar}
      anchorEl={this.state.anchorEl}
      dropdownType={this.state.dropdownType}
      tagsSearchText={this.state.tagsSearchText}
      creatorSearchText={this.state.creatorSearchText}
      contentCreationDate={this.state.contentCreationDate}
      handleClickContentFilter={this.handleClickContentFilter}
      tagsListing={this.state.tagsListing}
      createrNameListing={this.state.createrNameListing}
      isCalendarOpen={this.state.isCalendarOpen}
      handleTagsSearchChange={this.handleTagsSearchChange}
      handleCreatorSearchChange={this.handleCreatorSearchChange}
      tagsCheckboxChangeHandler={this.tagsCheckboxChangeHandler}
      creationCheckboxChangeHandler={this.creationCheckboxChangeHandler}
      creationSelectedItems={this.state.creationSelectedItems}
      openFilterContent ={Boolean(this.state.anchorFilterContentEl)}
      tagsSelectedItems={this.state.tagsSelectedItems}
      handleFilterOpen={this.handleFilterOpen}
      creationDateParams={this.state.creationDateParams}
      openPreviewModal={this.previewModalHandler}
      />

      {this.renderPreviewModal()}
      </>
    );
  }
}


const HeaderContainer = styled(Button)({
  backgroundColor: "#E5E2E1",
  height: "56px", 
  width: "230px", 
  borderRadius: "16px", 
  textTransform: "none", 
  fontSize: "16px", 
  color: "#787776",
  lineHeight: "16px", 
  fontWeight: 600, 
  fontFamily: "'Poppins', sans-serif",
   "&:hover": {
    backgroundColor: "#E5E2E1",
  },
  cursor: 'pointer',
});
const continueButtonContainer = { display: "flex", flexDirection: "row", justifyContent: "center" }

const CancelDraftstyle = styled("div")({ width: "230px", height: "56px", textTransform: "none", textAlign: "center", display: "grid", alignItems: "center", color: "#FF8D3B", fontSize: "16px", lineHeight: "16px", fontWeight: 600, fontFamily: "'Poppins', sans-serif", margin: "20px 0px", border: "1px solid #FF8D3B", borderRadius: "16px" , cursor: "pointer"})

const ResponsiveBox = styled(Box)({
  maxWidth: "899px",
  width: "calc(100% - 40px)",
  height: "calc(100% - 100px)",
  position: "relative",
  backgroundColor: "#ffffff",
  borderRadius: "17px",
  "& .modalTitle":{
    padding: "16px",
    fontFamily: "Poppins,sans-serif",
    fontWeight: 600,
    fontSize: "24px"
  }
});

const StyledIconButton = styled(Box)({
  position: "absolute",
  top: "-20px",
  right: "-15px",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  backgroundColor: "purple",
  color: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
})

const ScrollableContainer = styled(Box)({
  flex: "1",
  height: "100%",
  overflowY: "auto",
  padding: "0 16px 16px 16px",
  
  "& .react-player": {
    position: "static",
    top: 0,
    left: 0,
  },
});

const ExpertNameComponent = styled(Typography)({
  fontSize:"16px",
  fontWeight:400,
  fontFamily: "Poppins, sans-serif"
})

const DescriptionComponent = styled(Typography)({
  fontSize:"16px",
  fontWeight:400,
  fontFamily: "Poppins, sans-serif"
});

const DescriptionHeading = styled(Typography)({
  color: "#1C1B1B",
  fontSize: "18px",
  fontWeight: 600,
  fontFamily: "Poppins , sans-serif"
})

const FixedFooter = styled(Box)({
  width: "100%",
  boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
  borderTop: "1px solid #e0e0e0",
  backgroundColor: "#ffffff"
});

const ResponsiveButtonSecond = styled(Button)({
  display: "flex !important",
  width: "256px",
  maxWidth: "100%",
  height: "40px !important",
  padding: "16px 32px !important",
  justifyContent: "center !important",
  alignItems: "center !important",
  gap: "10px !important",
  borderRadius: "12px !important",
  backgroundColor: "#652786 !important",
  color: "#fff !important",
  fontSize: "16px !important",
  fontStyle: "normal !important",
  fontWeight: 600,
  lineHeight: "16px !important",
  textTransform: "capitalize"
});

const VideoPlayerBox =  styled('div')({
  position: "relative",
  '& > div:first-of-type': {
    width: "100% !important",
  height: "100% !important",
  }
})

const descInput = {
  borderRadius: "8px",
  background: "#FCF8F8",
  outline:"none"
}

const thumbnailOuterBox = { display: "flex", flexDirection: "column" as const, gap: "4px" }

const gridStyle = {
  display: "grid",
  gap: "24px",
  padding: "24px",
  boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.3)",
  borderRadius: "18px"
};

const webStyle = ({
  lableStyle: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#1C1B1B"
  },
  outlinedBox: { height: "262px", padding: "20px", borderRadius: "8px", border: "2px dashed #929090", display: "flex", flexDirection: "column" as const, gap: "15px", alignItems: "center", justifyContent: "center", cursor:"pointer" },
  labelText: { fontSize: "16px", fontWeight: 400, lineHeight: "24px", textAlign: "left" as const, color: "#1C1B1B", fontFamily: "'Poppins', sans-serif" },
  formStyle: {
    display: "grid",
    gap: "6px",
    "& .MuiFormControl-root.whiteBg .MuiOutlinedInput-multiline":{
      backgroundColor: "#fff !important"
    },
    "& .MuiFormControl-root.pinkBg .MuiOutlinedInput-multiline":{
      backgroundColor: "rgb(255, 247, 255) !important"
    },
    "& .MuiFormControl-root.whiteBg .MuiInput-root":{
      backgroundColor: "#fff !important"
    },
    "& .MuiFormControl-root.pinkBg .MuiInput-root":{
      backgroundColor: "rgb(255, 247, 255) !important"
    },
    "& .MuiInput-underline":{
borderRadius:"8px"
    }
  },
  InputStyle: {
    "& .MuiInputBase-input": {
      padding: "16px 20px 16px 24px",
      gap: "0px",
      borderRadius: "8px",
    }
  },
  contentRoleContentbtn: {
    border: "1px solid #6c328b",
    width: "200px",
    borderRadius: "12px",
    height: "40px",
    fontFamily: "'Poppins', sans-serif",
    fontWeight: 700,
    fontSize: "15px",
    lineHeight: "16px",
    textAlign: "center" as const,
    color: "#6c328b",
    textTransform: "capitalize" as const,
  },
  autoComplete: {
    maxWidth: "369px",
    width:"100%",
    background: "#FCF8F8",
    "& .MuiAutocomplete-inputRoot[class*='MuiInput-root']": {
      padding: "12px",
      marginTop: "5px"
    },
    "& .Mui-focused": {
     background:"#fff",
     color:"#000",
     outline:"none",
     border:"transparent"
    },
    "@media(max-width:800px)": {
      maxWidth:"800px",
      width:"100%"
     },
  },

  contentDiv: {
    display: "flex",
    gap: "20px",
    flexWrap: "nowrap" as const,
    "@media (max-width: 1300px)": {
      flexWrap: "nowrap",
    },
    "@media (max-width: 700px)": {
      flexWrap: "wrap",
    },
  },
  enableBTns:{
    backgroundColor:"#6C328B",
    color:"#FFFFFF",
    cursor:"pointer",

  },
  tagCss:
  {display: 'flex',
  gap:"30px",
  "@media(max-width:800px)": {
   flexDirection:"column"
  },
  },
  dividerCss:{
 height: "80px",
  width: "1px", 
  backgroundColor: "#929090",
  "@media(max-width:800px)": {
   display:"none"
   },
  } ,
  tagFieldCss:{
    minWidth: '369px', 
    background: "#fff7ff",
    "@media(max-width:800px)": {
      width:"100%"
     },
  },
  selectBox:{
    padding: '2px 8px',
    width: 'auto',
    height: 'auto',
    borderRadius: '88px',
    gap: '10px',
    backgroundColor: '#787776',
    appearance: 'none' as 'none',
    textAlign:"center" as 'center',
    border: 'none',
    color:"white",
     fontWeight:500,
     fontSize:"10px",
  },
  videoControlSection: {
    position: "absolute" as 'absolute',
    bottom: 0, left: 0, backgroundColor: "#1C1B1B",
    width: "100%", height: "44px", display: "flex", alignItems: "center",
    gap: "8px", padding: "0 8px"
  },
  downloadFileSectionCss: {
    justifyContent: "center",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    height: "100%", background: "#FCF8F8",
    gap: "16px", width: "100%",
  },
  sorryText: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    textAlign: "center" as 'center',
    color: "#787776"
  },
  mainImg_box: {
    borderRadius: "16px 16px 0px 0px",
    position: "relative" as "relative",
    display: "flex",
    justifyContent: "center",
    maxWidth: "100%",
    height: "100%"
  },
  main_courseImg: {
    cursor:"pointer",
    maxWidth:"100%"
  },

})
export default withStyles(webStyle)(CustomLessonForm)
// Customizable Area End
