import React from "react";
// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Box, Dialog,
  styled,
  Popover,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  Table,
  withStyles,
  Accordion, 
  AccordionDetails, 
  AccordionSummary, 
  Modal,
  Divider,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import Badge from "@material-ui/core/Badge";
import Typography from '@material-ui/core/Typography';
import { newCourse, newProgram, Filter, NotFoundImage, NewFilter, newContentRole, coursePreview, crossIcon, docIcon, editIconPreview, lessonpic, courseIcon, starRating, emptyStar } from "./assets";
import FilterItems from "../../filteritems/src/FilterItems.web";
import AdvancedSearch from "../../../blocks/advancedsearch/src/AdvancedSearch.web";
import "./Catalogue.css";
import { ContentRolesType, ProgramCourse } from "./CatalogueController";
import moment from "moment";
import { archived,  duplicate, editNew, newDownArrow, newUpArrow, optionIcon} from "../../../components/src/Table/asset";
import EnhancedTable, { LightTooltip } from "../../../../packages/components/src/Table";
import {ContentIdToolTip}  from "../../../../packages/components/src/ContentIdToolTip";
import SaveAndDiscardChangesModal from "../../../../packages/components/src/SaveAndDiscardChangesModal";
export const configJSON = require("./config");
import CoursePreviewWeb from "./CoursePreview.web";
import Title from "../../../../packages/components/src/DynamicTitle";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Rating } from "@material-ui/lab";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  generateToggleBoxStyle(isActive: boolean) {
    return {
      backgroundColor: isActive ? "#FFF" : "#fcf6f6",
      height: "48px",
      boxShadow: isActive ? "-3px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
      color: isActive ? "#000" : "",
      display: "flex",
      alignItems: "center",
      fontWeight: isActive ? 700 : 400,
      justifyContent: "center",
      borderRadius: "10px",
      cursor: "pointer",
      
    };
  }

  generateNewToggleBoxStyle(isActive: boolean) {
    return {
      backgroundColor: isActive ? "#FFF" : "#fcf6f6",
      height: "48px",
      boxShadow: isActive ? "-3px 1px 9px 1px rgba(0, 0, 0, 0.11)" : "",
      color: isActive ? "#000" : "",
      display: "flex",
      alignItems: "center",
      minWidth: "170px",
      maxWidth: "200px",
      fontWeight: isActive ? 700 : 400,
      justifyContent: "center",
      borderRadius: "10px",
      cursor: "pointer",
      gap: "10px",
      padding: "14px 24px",
      // border:"1px solid black",

      
    };
  }

  renderToggleBox() {
    const { isCourseActive, filterItems, anchorFilterContainerEl } = this.state;
    
    const courseToggleStyle = this.generateToggleBoxStyle(isCourseActive === "course");
    const programToggleStyle = this.generateToggleBoxStyle(isCourseActive === "program");
    const contentRoleToggleStyle = this.generateNewToggleBoxStyle(isCourseActive === "contentRole");
    const openFilterContainer = Boolean(this.state.anchorFilterContainerEl)

    return (
      <Grid item container md={12} style={{display:"flex" , alignItems:"center"}}>
        <TabGrid item md={6} xs={12} lg={4}>
          <ToggleBox >
            <ToggleInnerbox data-test-id="courseToggleId" style={courseToggleStyle} onClick={this.handleToggleCourses}>
              Courses
            </ToggleInnerbox>
            <ToggleInnerbox style={programToggleStyle} onClick={this.handleTogglePrograms} data-test-id="programToggleId">
              Programs
            </ToggleInnerbox>
            <ToggleInnerbox style={contentRoleToggleStyle} onClick={this.handleToggleContentRole} data-test-id="contentRoleToggleId">
              Content Role
            </ToggleInnerbox>
          </ToggleBox>
        </TabGrid>
        <InputMain item md={6} xs={12} lg={6} style={{ display: 'flex', alignItems: 'center', borderRight: "none", justifyContent: "center" }}>
          <StyledContentWrapper  
         >
            <AdvancedSearch
              data-test-id="btn_change"
              isFilterDropActive={openFilterContainer}
              handleListItemClick={this.handleListItemClickSuggestion}
              searchInputValue={this.state.searchInputValue}
              handleInputChange={this.handleInputCatalogueChangeSearch}
              filterList={this.state.filteredList}
              showList={this.state.showList} navigation={undefined} id={""} />
            <div style={{ marginLeft: "0px" }} className={anchorFilterContainerEl ? "filterIconBox allBorder" : "filterIconBox"}  onClick={this.handleFilterDrop} aria-describedby={"myPopover"}>
              {this.state.creationDate.length > 0 || (this.state.creationDateParams.hasOwnProperty('start_date') && this.state.creationDateParams.start_date.length > 0) ||this.state.categorySelectedItems.length > 0|| this.state.placeholderSelectedItems.length > 0  || this.state.contentRoleSelectedItems.length > 0 || this.state.selectedStatus.length > 0 ? (
                <BadgeComponents color="secondary" variant="dot"></BadgeComponents>) : (
                ""
              )
              }
              {anchorFilterContainerEl ? (
                <FiterImage src={Filter} data-test-id="filterOpenTestID" alt="Filter" />
              ) : (
                <img src={NewFilter} data-test-id="filterOpenTestID" />
              )}
            </div>
            <Popover
              data-test-id="filterPropsID"
              open={openFilterContainer}
              className="filterContainerPopover"
              id="myPopover"
              onClose={this.filterContainerDropdownCloseHandler}
              anchorEl={this.state.anchorFilterContainerEl}
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
            >
              <FilterItems
                isContentRole={this.state.contentRoleTrue}
                data-test-id="FilterItemsTestID"
                selectedStatus={this.state.selectedStatus}
                handleChangeStatus={this.handleCatalogueChangeStatus}
                handleClick={this.handleClickFilter}
                contentRoleCheckboxChangeHandler={this.contentRoleCheckboxHandlerChange}
                handleContentRoleSearchChange={this.handleContentRoleChangeSearch}
                categoryCheckboxChangeHandler={this.categoryCheckboxHandlerChange}
                handleCategorySearchChange={this.handleCategoryChangeSearch}
                categorySelectedItems={this.state.categorySelectedItems}
                contentRoleSearchText={this.state.contentRoleSearchText}
                contentRoleSelectedItems={this.state.contentRoleSelectedItems}
                categorySearchText={this.state.categorySearchText}
                placeholderCheckboxChangeHandler={this.placeholderCheckboxAssignedExpertChangeHandler}
                handlePlaceholderSearchChange={this.handlePlaceholderAssignedSearchChange}
                creationDateChangeHandler={this.creationChangeHandlerDate}
                dropdownOpenHandler={this.dropdownHandlerOpen}
                dropdownCloseHandler={this.dropdownHandlerClose}
                handleCalendarOpen={this.handleOpenCalendor}
                anchorEl={this.state.anchorEl}
                dropdownType={this.state.dropdownType}
                placeholderSelectedItems={this.state.placeholderSelectedItems}
                placeholderSearchText={this.state.placeholderSearchText}
                creationDate={this.state.creationDate}
                expertAdminList={this.state.expertAdminList}
                adminCatagoryList={this.state.adminCatagoryList}
                adminContentRolesList={this.state.adminContentRolesList}
                isCalendarOpen={this.state.isCalendarOpen}
                handleClear={this.handleClearFilter}
                handleCalendarChange={this.handleChangeCalendor} />
            </Popover>

          </StyledContentWrapper>
        </InputMain>
        <CreateNewGrid item md={6} xs={12} lg={2}>
          <Box style={Styles.Accordion} className="btnBox">
            <Box>
              <Dialog
                data-test-id="Tooltip"
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                  style: {
                    margin: 'auto',
                    maxWidth: 'fit-content',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    zIndex: 9999,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: "10px"
                  },
                }}
              >
                <MainDialogueComponent spacing={2}>
                  <Grid data-test-id="btn_click" onClick={this.NewCourse} style={{ display: "flex", flexDirection: "column", alignItems: "center", cursor: "pointer", height: "100%", width: "100%", justifyContent: "center" }} item lg={12}>
                    <ImageComponentInner src={newCourse} />
                    <ButtonComponent>
                      New Course           
                    </ButtonComponent>
                  </Grid>
                  <BorderStyle/> 
                  <GridComponentTwo data-test-id="btn_click1" onClick={this.NewProgram} item lg={12}>
                    <ImageComponentInnerTwo src={newProgram} />
                    <ButtonComponentTwo>
                      New Program    
                    </ButtonComponentTwo>
                  </GridComponentTwo>
                  <BorderStyle/>
                  <GridComponentTwo data-test-id="btn_click1" onClick={this.redirectContentRolePage} item lg={12}>
                    <ImageComponentInnerTwo src={newContentRole} />
                    <ButtonComponentTwo>
                      New Content Role
                    </ButtonComponentTwo>
                  </GridComponentTwo>
                </MainDialogueComponent>
              </Dialog>
            </Box>
            <Button onClick={() => this.handleClickOpen()}
              style={Styles.AccordionSummary}
              data-test-id='panel1a-content'
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <CreateNewVideoComponent>
                <span style={{ fontSize: "16px", fontWeight: 600, paddingRight: "10px", fontFamily: "Poppins, sans-serif" }}>
                  +
                </span>
                Create New
              </CreateNewVideoComponent>
            </Button>
          </Box>
        </CreateNewGrid>
      </Grid>
    );
  }

  enhancedTableData(){
return(
  <EnhancedTable
            data-test-id="tableTestId"
            prevPageExists={!!this.state.pagination.prevPage}
            nextPageExists={!!this.state.pagination.nextPage}
            handleNextPageNavigation1={this.handlePageNavigationNext}
            handlePrevPageNavigation1={this.handlePageNavigationPrev}
            data={this.state.data || []}
            enrolledUser={this.state.enrolledUser}
            getCourseData={(obje: { search: string; filter: string; page: string; per: string; }) => this.getCourse(obje, this.state.pagination)}
            contentType={this.state.contentType}
            meta={this.state.meta}
            search={this.state.text}
            handleSave={(courseId: string | number, idSav: number, expand_type: string | number) => this.handleEditSave(courseId, idSav, expand_type)}
            getUserEnrolled={(idEnr: string | number, expand_type: string | number) => this.getEnrolledUser(idEnr, expand_type)}
            deleteItem={(idDelete: string | number, expand_type: string | number) => this.deleteItemNew(idDelete, expand_type)}
            archivedItem={(idArchived: string | number, expand_type: string | number, title:string) => this.archivedItemNew(idArchived, expand_type, title)}
            unArchivedItem={(idArchived: string | number, expand_type: string | number, title:string) => this.unArchivedItemNew(idArchived, expand_type, title)}
            className="table"
            isExist={this.state.isExist}
            handleInputChange={this.handleEditCourse}
            handleEditClick1={this.handleEditCourse}
            handleEditClick={this.handleEditCourse}
            gotoEditCourse={this.handleEditCourseData}
            handleDuplicate={this.handleDuplicate}
            handlePreview={this.handlePreview}
            isEditCourseIdOpen={this.state.isEditCourseIdOpen}
            onCloseRenameCourse={this.handleisEditCourseIdOpenClose}
            activeId={this.state.activeId}
          />
)
  }
  renderTableOrNotFound() {
    let contentToRender;
    if (this.state.isCourseActive === "contentRole" &&  this.state.contentRolesData.length>0) {
      contentToRender = this.renderTableContentRole();
    }else if(( this.state.isCourseActive === "course" ||  this.state.isCourseActive === "program") && this.state.data.length>0){
      contentToRender = this.enhancedTableData();
    }
    else if(this.state.contentRolesData.length===0 || this.state.data.length===0){
        contentToRender = (
        <MainNotFoundBox>
        <MainInnerBox>
          <Box>
            <ImageComponent src={NotFoundImage} />
          </Box>
          <NotFoundText>No results founds</NotFoundText>
        </MainInnerBox>
      </MainNotFoundBox>)
      } 
  
  
    return contentToRender;
  }

  statusOptionData = (row:any) => {
    if(row?.attributes?.status ==="active"){
      return webStyle.statusNew
    }else if(row?.attributes?.status === "draft"){
      return webStyle.statusDraft
    }else {
      return webStyle.statusArchived
    }
  }

  renderTableContentRole = () => {
    return (
      <TableContainer data-test-id="content-role-table" style={{marginTop:"2%"}}>
        <Table style={{ minWidth: 750 , borderSpacing:"0px 15px",borderCollapse:"separate" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {this.state.contentRoleTableHeaderData.map((header, index) => {
                return (
                  <TableCell key={index} id={index===4?"last":""}  className={index === 0 ? "first borderNone" : "all borderNone"}>
                    <TableSortLabel direction={"asc"} style={{ display: "flex", flexDirection: 'row', alignItems: 'center' }}>
                      <span  style={{ display: "flex", justifyContent: "space-between", whiteSpace: "nowrap", fontWeight: 600, fontStyle: "normal", lineHeight: "24px", color: "#000", fontFamily: "Poppins, sans-serif" }}>{header.name}</span>
                      <div style={{
                        display: 'flex',
                        flexDirection: 'column', 
                        marginLeft: '4px'
                      }}>
                        <img
                          src={newUpArrow}
                          alt="Up Arrow"
                          width="10px"
                          data-test-id={`testing${index}`}
                          style={{ paddingBottom: '5px', cursor: 'pointer' }}
                          onClick={() => this.handleSort(header.key)}
                        />
                        <img
                          src={newDownArrow}
                          alt="Down Arrow"
                          width="10px"
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.handleSort(header.key)}
                        />
                      </div>
                    </TableSortLabel>
                  </TableCell>
                )
              })}

            </TableRow>

          </TableHead>
          <TableBody>
            {this.state.contentRolesData.map((row: ContentRolesType) => (
              <TableRow style={{cursor: "pointer"}} data-test-id="content-role-table-row" onClick={() => this.navigateToEditContentRole(row.attributes.id)}
              >
                <TableCell style={{ maxWidth: "200px", wordBreak:"break-all",
                 borderBottom:"none",height:"72px",padding:"0 24px", fontSize: "16px"}} >{row.attributes.title}</TableCell>
                <TableCell align="center" style={{ width: "300px",borderBottom:"none",height:"72px",padding:"0 24px",fontSize: "16px"}}>
                  <ContentIdToolTip
                    data-test-id="ContentIdToolTipId"
                    uniqId={row.attributes.uniq_id}
                    editIconClick={() => this.handleisEditContentIdOpenClose(row)}
                    isEditContentIdOpen={this.state.isEditContentIdOpen}
                    row={row}
                    editContentdata={this.state.editContentdata}
                    updateContentIDErrorMessage={this.state.updateContentIDErrorMessage}
                    handleChangeContentId={this.handleChangeContentId}
                    updateEditContentId={this.updateEditContentId}
                  />
                </TableCell>
                <TableCell style={{ width: "300px",borderBottom:"none",height:"72px",padding:"0 24px", fontSize: "16px"}} align="center">{row.attributes.lessons_count}</TableCell>
                <TableCell style={{width: "300px",borderBottom:"none",height:"72px",padding:"0 24px", fontSize: "16px"}} align="center">{ moment.utc(row.attributes.updated_at).format('MM-DD-YYYY')}</TableCell>
                <TableCell data-test-id="id-table-cell" style={{borderBottom:"none",height:"72px",padding:"0 24px", fontSize: "16px"}} align="center">
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth: "300px", fontSize: "16px" }}>
                    <div style={this.statusOptionData(row)}
                    >
                      {row.attributes.status}
                    </div>
                    <div data-test-id="id-tooltip-div" onClick={(e) => this.handlePropagation(e)}>
                    <LightNewTooltip
                      enterDelay={500}
                      leaveDelay={100}
                      interactive={true}
                      data-test-id="tooltip_duplicate_content"
                      title={
                        <NewToolTipMain>
                          <NewToolTipInner style={{ cursor: "pointer" }} onClick={() => this.navigateToEditContentRole(row.attributes.id)}>
                            <img src={editNew} />
                            <NewToolTipTextComponent>Edit</NewToolTipTextComponent>
                          </NewToolTipInner>
                          <HrComponentNew />
                          <NewToolTipInner style={{ cursor: "pointer" }} onClick={() => this.navigateToDuplicateContentRole(row.attributes.id)}>
                            <img src={archived} />
                            <NewToolTipTextComponent>Duplicate</NewToolTipTextComponent>
                          </NewToolTipInner>
                          <HrComponentNew />
                          <NewToolTipInner>
                            <img style={{ cursor: "pointer" }} src={duplicate} />
                            <NewToolTipTextComponent>Archive</NewToolTipTextComponent>
                          </NewToolTipInner>
                        </NewToolTipMain>
                      }
                      placement="bottom"
                    >
                      <TableCell
                        data-test-id="ellipsis-menu-cell"
                        onClick={(e) => this.handlePropagation(e)}
                        style={{
                          border: "none",
                        }}
                        align="right">
                        <img style={{ cursor: "pointer" }} src={optionIcon} />
                      </TableCell>
                    </LightNewTooltip>
                    </div>
                  </div>
                </TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
     

    )
  }
  renderduplicateConfirm() {
    return (
      <Box sx={continueButtonContainer}>
          <>
            {this.state.handleDuplicateOpen && 
              <SaveAndDiscardChangesModal
              data-test-id="duplicate-content-modal"
              open={this.state.handleDuplicateOpen}
              onClose={this.handleCloseDuplicate}
              description={configJSON.duplicateSubText}
              heading={this.state.isCourseActive === "course" ? configJSON.duplicateHeadingTxt : configJSON.duplicateProgramHeadingTxt}
              rightButtonText={configJSON.duplicateText}
              leftButtonText={configJSON.cancelTxt}
              onLeftButtonClick={this.handleCloseDuplicate}
              onRightButtonClick={this.handleDuplicateSubmit} />             
            }               
          </>      
      </Box>
    );
  }

  renderProgramCourses(courses: ProgramCourse[]) {
    return courses.map((data, index) => (
    <AccordionDetails key={index} style={{height: "128px"}}> 
        <Box 
          key={index} 
          style={{
          height: "100%",
          display: "flex",
          gap: "16px",
          }}
        >
           <img style={{width: "80px", height: "80px", borderRadius: "10px"}} data-test-id="thumbnailImg" src={data.thumbnail_image?.url}/>

          <div style={{ minHeight: "43px", display: "flex", flexDirection: "column" }}>
              <TitleTypography>
                {data.title.length > 16 ? data.title.substring(0, 16) + "..." : data.title}
              </TitleTypography>
              <LightTooltip
                  data-test-id="tooltip-editBtn"
                  enterDelay={200}
                  leaveDelay={100}
                  enterTouchDelay={0}
                  leaveTouchDelay={100000}
                  interactive={true}
                  title={
                    <Typography style={{
                      lineHeight: "22px",
                      color: "#484646",
                      fontWeight: 500,
                      fontSize: "14px",
                      fontFamily: "Poppins"
                    }}>
                      {data.description}
                    </Typography>
                  }
                  placement="top"
                  arrow
              >
              <Typography style={{
                lineHeight: "22px",
                color: "#484646",
                fontWeight: 500,
                fontSize: "14px",
                fontFamily: "Poppins",
              }}>
                {data.description.length > 40 
                ? data.description.substring(0, 40) + "..." 
                : data.description}
              </Typography>
              </LightTooltip>
              <Box style={{display: "flex"}}>
                <Typography style={{
                  lineHeight: "16px",
                  color: "#FF8B3F",
                  fontWeight: 600,
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  marginTop: "auto"
                }}>
                  {data.average_rating}&nbsp;
                </Typography>     
                <Rating
                    style={{height: "12px", alignSelf: "center", fontSize: "12px", width: "12px", gap: "3px"}}
                    name='read-only'
                    value={data.average_rating}
                    precision={0.5}
                    readOnly
                    icon={<img src={starRating}/>}
                    emptyIcon={<img src={emptyStar}/>}
                />
              </Box>
              <Typography style={{
                lineHeight: "18px",
                color: "#1C1B1B",
                fontWeight: 400,
                fontSize: "12px",
                fontFamily: "Poppins"
              }}>
                {data.expert_name}
              </Typography>
          </div>
        </Box>
      </AccordionDetails>)
    )
  }

  renderPreviewImage() {
    return (
      <ImageBox >
        <img style={{ width: "100%", height: "344px", borderRadius: "20px 20px 0px 0px" }}
          src={this.state.programPreviewData?.attributes?.thumbnail_image?.url ?
            this.state.programPreviewData.attributes?.thumbnail_image?.url : coursePreview} 
            />
      </ImageBox>
    )
  }

  renderArrowIcon(index: number) {
    return (
      <KeyboardArrowRightIcon style={{ rotate: `${this.state.arrowClick.includes(String(index)) ? "90deg" : "0deg"}`, alignSelf: "center" }}/>
    )
  }

  renderHeaderArrowIcon() {
    return (
      <KeyboardArrowRightIcon 
        style={this.state.toggleDropDown ? { rotate: "90deg", cursor: "pointer" } : { rotate: "0deg", cursor: "pointer" }} 
      />
    )
  }

  renderIfPhasesAreReleased() {
    if (this.state.programPreviewData?.attributes.content_release_schedule) {
      return (
        <Box style={{
          display: "flex"
        }}>
          <Typography style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "16px",
            fontFamily: "Poppins",
            alignSelf: "center"
          }}>
            Phases are released</Typography>
          <Button style={{
            padding: "6px 9px 6px 9px", borderRadius: "88px", backgroundColor: "#E0D4E7",
            textTransform: "capitalize", gap: "10px", color: "#542070", textAlign: "center",
            cursor: "pointer", marginLeft: "auto", fontSize: "14px", minWidth: "86px", minHeight: "40px",
            fontWeight: 400
          }}>
            {this.state.programPreviewData?.attributes.content_release_schedule_value}
          </Button>

        </Box>
      
      )
    }
  }

  renderStatus() {
    if (this.state.programPreviewData?.attributes?.status) {
      return (
        <Box>
          <Statuses 
          style={this.statusOptionData(this.state.programPreviewData)}
          >
            {this.state.programPreviewData?.attributes?.status}
          </Statuses>
        </Box>
      )
    }
  }

  renderProgramPreview() {
    const dueDateAtString = this.state.programPreviewData?.attributes?.due_date;
    const dueDateAtMoment = moment(dueDateAtString);
    const formattedDueDate = dueDateAtMoment.format('MM-DD-YYYY');

    return (
      <DialogBox
          data-test-id="dialogPreview"
          open={this.state.isProgramPreview}
        >

          <SecondDialogBox>
            <Box style={webStyle.crossIcon}
              data-test-id="close-btn"
              onClick={() => { this.handlePreviewClose(); this.setState({ arrowClick: [] }) }}
              >
              <img src={crossIcon} />
            </Box>
            <ModalBox>
              <Box style={{ padding: "10px" }}>
                {<Title
                  showBackButton title1="Program preview" 
                  onBackClick={this.handlePreviewClose} 
                  />}
              </Box>


              <Grid container style={{ display: "flex", flexWrap: "wrap", }} spacing={2} >
                <Grid item xs={12} md={7} lg={8}>
                  <Box style={{
                    display: "flex", flexDirection: "column", gap: "24px", maxWidth: "771px",
                    height: "fit-content"
                  }}>
                    {this.renderPreviewImage()}

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", gap: "12px" }}>
                        <Typography style={{
                          fontWeight: 700,
                          fontSize: "24px",
                          fontFamily: "Poppins",
                          lineHeight: "36px",
                          color: "#1C1B1B"
                        }}>
                          {this.state.programPreviewData?.attributes?.title} 
                          </Typography>

                        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>

                          <Typography style={{
                            borderRight: "1px solid rgba(173, 170, 170, 1)",
                            color: "#787776",
                            paddingRight: "12px",
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            lineHeight: "22px",

                          }}>
                            {this.state.programPreviewData?.attributes.expert_name}
                          </Typography>

                          <Typography style={{
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            lineHeight: "22px",
                            color: "#ADAAAA"
                          }}>
                            {(this.state.programPreviewData?.attributes && this.state.programPreviewData.attributes.categories.length > 1) ? `${this.state.programPreviewData?.attributes.categories[0].attributes.name} & ${this.state.programPreviewData.attributes && this.state.programPreviewData?.attributes.categories.length - 1} more` : this.state.programPreviewData?.attributes.categories[0].attributes.name}
                          </Typography>
                          <Box style={{display: "flex"}}>
                            <Typography style={{
                              lineHeight: "16px",
                              color: "#FF8B3F",
                              fontWeight: 600,
                              fontSize: "16px",
                              fontFamily: "Poppins",
                              marginTop: "auto"
                            }}>
                              {this.state.programPreviewData?.attributes.average_rating || 0}&nbsp; 
                            </Typography>     
                            <Rating
                                style={{height: "12px", alignSelf: "center", fontSize: "12px", width: "12px", gap: "3px"}}
                                name='read-only'
                                value={this.state.programPreviewData?.attributes.average_rating} 
                                precision={0.5}
                                readOnly
                                icon={<img src={starRating}/>}
                                emptyIcon={<img src={emptyStar}/>}
                            />
                          </Box>
                        </Box>

                      </Box>

                      {this.renderStatus()}
                    </Box>

                    <Box >
                      <Box style={{
                        display: "flex", justifyContent: "space-between", gap: "12px", alignItems: "center",
                        flexWrap: "wrap"
                      }}>
                        <Typography style={{
                          fontWeight: 600,
                          fontSize: "16px",
                          fontFamily: "Poppins",
                          lineHeight: "16px",
                          color: "#652786"
                        }}>
                          Last Modified:  &nbsp; <span style={{
                            color: "#484646",
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            lineHeight: "22px",
                          }}>
                            {moment.utc(this.state.programPreviewData?.attributes.updated_at).format('MM/DD/YYYY')}
                            </span> 
                            </Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "12px", flexWrap: "wrap" }}>

                          <Button data-test-id="edit-program-btn" style={{
                            border: "1px solid #E47004", padding: "12px 24px 12px 16px", borderRadius: "16px", color: "#E47004",
                            textTransform: "capitalize", whiteSpace: "nowrap", gap: "16px"
                          }} 
                          onClick={() => this.handleEditCourseData(Number(this.state.programPreviewData?.id))}
                          >
                            <img src={editIconPreview} />
                            &nbsp;  &nbsp;
                            <Typography
                              style={{
                                fontWeight: 600,
                                fontSize: "16px",
                                fontFamily: "Poppins",
                                lineHeight: "16px",
                                color: "#E47004"
                              }}
                            >Edit Program</Typography>
                          </Button>
                          <Button data-test-id="edit-program-content-btn" style={{
                            border: "1px solid #E47004", padding: "12px 24px 12px 16px", borderRadius: "16px", color: "#E47004",
                            textTransform: "capitalize", whiteSpace: "nowrap", gap: "8px"
                          }}
                          onClick={() => this.handleEditCourseContent(this.state.programPreviewData?.attributes.content_release_schedule,Number(this.state.programPreviewData?.id))}
                          >
                            <img src={docIcon} />
                            &nbsp;  &nbsp;
                            <Typography
                              style={{
                                fontSize: "16px",
                                lineHeight: "16px",
                                fontWeight: 600,
                                fontFamily: "Poppins",
                                color: "#E47004"
                              }}>
                              Edit Content
                            </Typography>
                          </Button>
                        </Box>

                      </Box>
                    </Box>


                    <DescriptionBox >
                      <Typography style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        lineHeight: "22px",
                        color: "#787776"
                      }}>
                        {this.state.programPreviewData?.attributes?.description}
                      </Typography>
                    </DescriptionBox>

                  </Box>
                </Grid>

                <Grid item xs={12} md={5} lg={4}>

                  <GridBox>

                    <ContentBox>
                      <div style={{ display: "flex", justifyContent: "space-between"}}>
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: "24px",
                            fontFamily: "Poppins",
                            lineHeight: "36px",
                            color: "#1C1B1B" 
                          }}>Program includes</Typography>
                        <div style={{alignSelf: "center"}} data-test-id="toggleDropDown" 
                        onClick={() => this.setState({ toggleDropDown: !this.state.toggleDropDown })}
                        >
                          {this.renderHeaderArrowIcon()}
                        </div>
                      </div>

                      <Box style={{
                        display: "flex",
                      }}>

                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                          maxWidth: "304px",
                          gap: "8px",
                          alignItems: "center",
                          flexWrap: "wrap",
                          padding:"8px 0"
                        }}>

                          <div>
                            <img src={courseIcon} />
                          </div>

                          <div>
                            <Typography style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              color: "#1A1A1A",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            > 
                            {this.state.programPreviewData?.attributes.courses_count} 
                            </Typography>
                            <Typography style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              lineHeight: "18px",
                              color: "#484646"
                            }}> Courses </Typography>
                          </div>

                        </Box >

                        <Divider style={{margin: "0 24px"}} orientation="vertical" flexItem />

                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                          maxWidth: "304px",
                          gap: "8px",
                          alignItems: "center",
                          flexWrap: "wrap",
                          padding:"8px 0"
                        }}>

                          <div>
                            <img src={lessonpic} />
                          </div>

                          <div>
                            <Typography style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              color: "#1A1A1A",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            > 
                            {this.state.programPreviewData?.attributes.lessons_count} 
                            </Typography>
                            <Typography style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              fontFamily: "Poppins",
                              lineHeight: "18px",
                              color: "#484646"
                            }}> Lessons </Typography>
                          </div>

                        </Box >
                      </Box>

                      {this.renderIfPhasesAreReleased()}

                    </ContentBox>


                    {this.state.toggleDropDown && this.state.programPreviewData?.attributes?.content_release_schedule &&
                    <Box style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
                      {this.state.programPreviewData?.attributes?.contents?.map((item, index) => {
                        return (
                          <>
                            <WithOutPhase key={index}>

                              <Box key={index} style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "304px"
                              }}>

                                <Accordion key={index}>
                                  <AccordionSummary
                                    onClick={() => this.rotateArrowIcon(String(index))}
                                    style={{ display: "flex", justifyContent: "space=between", alignItems: "center" }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    data-test-id="phase-accordion"
                                  >
                                    <Box>
                                      <Typography
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "16px",
                                          fontFamily: "Poppins",
                                          lineHeight: "22px",
                                          color: "#1C1B1B"
                                        }}>Phase &nbsp;{item.attributes.phase_number} &nbsp;
                                        {item.attributes.name && <span>{"- " + item.attributes.name}</span>}
                                      </Typography>
                                      {this.state.programPreviewData?.attributes?.add_due_date && 
                                      <Typography
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "12px",
                                          fontFamily: "Poppins",
                                          lineHeight: "22px",
                                          color: "#E47004"
                                        }}>Due: &nbsp;{<span style={{color: "#787776", fontWeight: 
                                          400}}>{formattedDueDate}</span>}
                                      </Typography>}
                                    </Box>
                                    {this.renderArrowIcon(index)}
                                  </AccordionSummary>
                                  {this.renderProgramCourses(item.attributes.courses)}
                                </Accordion>
                              </Box>
                            </WithOutPhase>

                          </>)

                      })}
                      </Box>}
                    {this.state.toggleDropDown && !this.state.programPreviewData?.attributes?.content_release_schedule &&
                      this.state.programPreviewData?.attributes?.contents?.map((item, index) => {
                        return (
                          <>

                            <WithOutPhase key={index}>
                              {this.renderProgramCourses(item.attributes.courses)}
                            </WithOutPhase>

                          </>)

                      })}

                  </GridBox>
                </Grid>
              </Grid>
            </ModalBox>
          </SecondDialogBox>
        </DialogBox>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Grid container style={{ marginTop: '7px' }}>
          {this.renderToggleBox()}
          {this.renderTableOrNotFound()}
          {this.renderduplicateConfirm()}
          <CoursePreviewWeb 
          data-test-id="coursePreviewId"
          isPreview={this.state.isPreview} 
          dialogContent={this.state.coursePreviewData} handlePreviewClose={this.handlePreviewClose}
            gotoEditCourse={this.handleEditCourseData}
          gotoEditContent={this.handleEditCourseContent}
          onProgramPreview={this.handlePreview}
          />
          {this.renderProgramPreview()}
        </Grid>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const continueButtonContainer = { display: "flex", flexDirection: "row", justifyContent: "center" }
const CreateNewVideoComponent = styled(Typography)({
  margin: 'auto',
  fontStyle: 'normal',
  display: ' flex',
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '16px',
  textAlign: 'center',
  color: '#FFFFFF',
  textTransform: 'capitalize'
})
const webStyle = {
  table: {
    minWidth: 750,
    borderCollapse: "separate" as "separate",
    borderSpacing: "0 15px",
    "& .MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0 15px"
    },
  },

  statusNew: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#E0D4E7",
    color: "#542070",
    textTransform:"capitalize" as 'capitalize'
    
  },



  textalignleft: {
    paddingLeft: "0",
    textAlign: "left",
    border: "1px solid red"

  },
  statusDraft: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFEDE4",
    color: "#E47004",
    textTransform:"capitalize" as 'capitalize'


  },
  statusArchived: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFD9DF",
    color: "#E70E6B",
    textTransform:"capitalize" as 'capitalize'
  },

  contentRoleTableStyle: {
    height:"72px !important",
    borderRadius: "20px !important",
    borderSpacing:"0 15px !important",
    padding:"0px !important"
    
  },
  crossIcon: {
    position: 'absolute' as 'absolute',
    right: "1px",
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 10
  },
}

const LightNewTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'rgba(133, 133, 133, 0.25) -6px -6px 28px 0px',
    border: '15px',
    height: 'auto',
    borderRadius: "14px",
  }
}))(Tooltip);

const StyledContentWrapper = styled(Box)({
  position: "relative",
  display: "flex",
  maxWidth: "520px",
  width: "100%",
 

  "& .filterIconBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FAFAFA",
    width: "63px",
    borderRadius: "8px",
    borderTopLeftRadius: "8px !important",
    borderBottomLeftRadius: "8px !important",
    cursor: "pointer",
    border: "1px solid #C9C6C5",
    position: "relative",
    zIndex: 999,
    left: "-1%"
  },
  "& .filterIconBox:hover": {
    borderColor: '#652786'
  },
  "& .allBorder": {
    borderColor: '#652786',
    backgroundColor: "#EEE8F2"
  }
});

const CustomTableRow = styled(TableRow)({
  borderRadius: '12px',
  marginBottom: '8px',
});

const MainDialogueComponent = styled(Grid)({
  height: "221px",
  backgroundColor: "#652786",
  width: "723px",
  "@media (max-width:960px)": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "723px !important",
    backgroundColor: "#652786",
    width: "221px !important",
    flexDirection:"column"
  },
  
  "@media (min-width:720px)": {
    display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  },

});


const BorderStyle =styled('div')({

  "@media (max-width:960px)": {
    borderBottom: '1px solid #f3f0f0',
     width: '100%', 
     margin: '0 auto',
    borderLeft:"none !important",
    height:"0% !important",
  },
  "@media (min-width:720px)": {
    borderLeft: '1px solid #f3f0f0', margin: '0 auto',
    height:"100%",

  },

})

const ImageComponentInner = styled("img")({
  width: "48px !important",
  height: "48px !important"
});

const NewToolTipMain = styled("div")({
  width: "100%",

})

const NewToolTipTextComponent = styled(Typography)({
  fontSize: "16px !important",
  fontWeight: 400,
  paddingLeft: "10px",
  color: "#484646",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontStyle: "normal",
  lineHeight: "24px"

})

const NewToolTipInner = styled("div")({
  display: "flex",
  width: "100%",
})

const HrComponent = styled("hr")({
  border: "1px solid #652786",
  margin: "6px 0",
  width: "100px"
})

const HrComponentNew = styled("hr")({
  border: "0.01px solid #E5E2E1",
  width: "100%",
  height: "1px"
})


const EditComponent = styled("div")({
  borderRadius: "8px !important",
  backgroundColor: "#fff",
  height: '12px',
  width: "100px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column"
})

const EditInnerComponent = styled(Typography)({
  fontSize: "18px !important",
  color: "#6C328B",
  fontWeight: 400
})

const IconButtonComponent = styled(IconButton)({
  fontSize: "15px",
  color: "#6C328B"
})

const ContentRoleMain = styled(Box)({
  display: "flex",
  width: "100%",
  height: "20px",
  borderRadius: "40px"
})

const ContentRoleInner = styled(Typography)({
  color: "#000",
  fontSize: "15px",
  display: "inline"
})

const UserEnrollMain = styled(Box)({
  margin: "0 20px",
  height: "270px",
})

const UserEnrollInner = styled(Typography)({
  color: "#000",
  fontSize: "11px",
  paddingRight: "5px"
})

const UserEnrollInnerHeading = styled(Typography)({
  color: "#000",
  fontWeight: 500,
  fontSize: '17px'
  ,
})

const CourseIdComponent = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center", "& .MuiInputBase-input": {
    padding: "6px 8px 7px"
  }
})

const GridComponentTwo = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  height: "100%",
  width: "100%",
  justifyContent: "center",
});

const ImageComponentInnerTwo = styled("img")({
  width: "48px !important",
  height: "48px !important"
});

const RenameComponent = styled(Typography)({
  fontSize: "14px !important",
  color: "#000",
  marginBottom: "10px !important",
  fontWeight: 400
})


const ButtonComponent = styled(Button)({
  color: "#FFF",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "capitalize",
  fontFamily: "Poppins, sans-serif !important"
});

const ButtonComponentTwo = styled(Button)({
  color: "#FFF",
  fontSize: "16px",
  fontWeight: 600,
  textTransform: "capitalize",
  fontFamily: "Poppins, sans-serif !important"
});

const InputMain = styled(Grid)({
  '& .MuiOutlinedInput-adornedEnd': {
    paddingRight: '0px',
  },

});

const CreateNewGrid = styled(Grid)({
  paddingRight: "0 !important",
  "& .btnBox": {
    width: "max-content",
    marginLeft: "auto"
  }
});

const TabGrid = styled(Grid)({
  paddingRight: "0 !important",
})

const DivVertical = styled("div")({
  width: "63px",
  border: "1px solid #C9C6C5",
  height: "54px",
  borderTopLeftRadius: "0px !important",
  borderBottomLeftRadius: "0px !important",
  borderRadius: "9px",
  '&:focus': {
    borderColor: '#652786'
  },
  '&:hover': {
    borderColor: '#652786'
  },
})

const SearchIcon = styled(Search)({
  color: "#ADAAAA"
})

const InputAdornmentComponent = styled(Box)({
  backgroundColor: "#EEE8F2",
  '&:focus': {
    borderColor: '#652786'
  },
  '&:hover': {
    borderColor: '#652786'
  },
});

const ToggleBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  backgroundColor: "#fcf6f6",
  height: "48px",
  alignItems: "center",
  borderRadius: "12px ",
  cursor: "pointer",
  width:"100%"
});

const MainAdorment = styled("div")({
  backgroundColor: "#EEE8F2",
  width: "63px",
  borderRadius: "10px",
  borderTopLeftRadius: "0px !important",
  borderBottomLeftRadius: "0px !important",
  position: "relative",
  '&:focus': {
    borderColor: '#652786'
  },
  '&:hover': {
    borderColor: '#652786'
  },
});

const FiterImage = styled("img")({
});

const ToggleInnerbox = styled(Box)({
  color: "#1c1818",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width:"173px"
});

const BadgeComponents = styled(Badge)({
  "& .MuiBadge-anchorOriginTopRightRectangle": {
    top: "-14px",
    left: "20px",
    right: " 0px",
    backgroundColor: " #FF883F",
    fontSize: "81px",
    width: "10px",
    height: "10px",
    borderRadius: "20px"
  }
});

const ImageComponent = styled("img")({
  width: "100%",
  height: "100%"
});

const MainNotFoundBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%"
});

const MainInnerBox = styled(Box)({
  width: "727px",
  height: "540px",
  marginTop: "40px"
});

const NotFoundText = styled(Typography)({
  marginTop: "26px",
  textAlign: "center",
  color: "#1C1B1B !important",
  fontFamily: "Poppins Arial, Helvetica, sans-serif",
  fontSize: "32px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  letterSpacing: "-1px"
});

const DialogBox = styled(Modal)({
  display: 'flex',
  justifyContent: "center",

  "& .MuiDialog-paperWidthSm": {
    maxWidth: "700px !important",

    "@media (max-width:960px)": {
      minWidth: "100px !important",
      height: "152px !important",
      padding: "16px",
    },
    "@media (min-width:720px)": {

    },
  },

})

const SecondDialogBox = styled(Box)({
  position: "relative",
  "@media (min-width:720px)": {
    maxWidth: "1211px",
    width:"85%",
    marginLeft: "clamp(15px, 135px, 10%)",
},
})

const ModalBox = styled(Box)({
  margin: "1rem",
  backgroundColor: "white",
  padding: "0px 32px 32px 32px",
  overflowY: "scroll",
  borderRadius: "16px",
  height: "97vh",

})

const ImageBox = styled(Box)({
  maxWidth: "771px",
  radius: "88px",
  height: "344px"
});

const Statuses = styled("div")({
  textAlign: "center",
  padding: "6px 9px",
  fontSize: "14px",
  borderRadius: "88px",
  gap: "10px",
  textTransform: "capitalize",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  width: "125px",
  height: "40px",
});

const DescriptionBox = styled(Box)({
  height: "fit-content",

  "@media (max-width:960px)": {
    height: "110px", overflowY: "scroll"
  },
  "@media (min-width:720px)": {

  },
});

const GridBox = styled(Box)({
  display: "flex",
  maxWidth: "352px", height: "fit-content", borderRadius: "16px", flexDirection: "column",
   boxShadow: "1px 2px 10px 2px rgba(0, 0, 0, 0.07)",
  gap: "24px",
  padding:"32px 24px"
})

const ContentBox = styled(Box)({
  display: "flex", flexDirection: "column", maxWidth: "304px", gap: "24px" ,
  "@media (max-width:960px)": {
   maxWidth: "360px",
 },
  "@media (min-width:720px)": {
   maxWidth: "1211px",
},
})

const WithOutPhase = styled(Box)({
  maxWidth: "304px",
  gap: "24px",
})

const TitleTypography = styled(Typography)({
  fontFamily: "Poppins",
  lineHeight: "22px",
  fontSize: "16px",
  color: "#1C1B1B",
  fontWeight: 700,
  wordWrap: "break-word"
})

const Styles = {
  Accordion: {
    height: '56px',
    borderRadius: '15px',
    boxShadow: 'none',
    position: 'relative',
  } as React.CSSProperties,
  accordionContent: {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 1,
  },
  AccordionDetails: {
    backgroundColor: '#fff',
    position: 'absolute',
    borderRadius: '10px',
    zIndex: 999,
  },
  Accordion_Typography: {
    margin: 'auto',
    fontStyle: 'normal',
    display: ' flex',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '16px',
    textAlign: 'center',
    color: '#FFFFFF',
    textTransform: 'capitalize',
  } as React.CSSProperties,
  AccordionSummary: {
    height: '56px',
    backgroundColor: '#6C328B',
    borderRadius: '15px',
    width: '192px',
  },
  course1_btns: {
    background: '#6C328B',
    height: "56px",
    borderRadius: "16px",
    width: "196px",
    fontWeight: 600,
    fontStyle: "normal",
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "16px",
    color: "#FFFFFF",
    textTransform: "capitalize"
  },
  course2_btns: {
    background: '#6C328B',
    borderRadius: "16px",
    width: "196px",
    height: "56px",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    textAlign: "center",
    lineHeight: "16px",
    color: "#FFFFFF",
    textTransform: "capitalize"
  }
}
// Customizable Area End