// Customizable Area Start
import React, { Component } from 'react';
import { withStyles, Theme, createStyles, WithStyles,styled } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
const emptyReward = require('./empty_reward.svg')

const StyledProgressBar = styled(LinearProgress)({
    height: "8px !important",
    borderRadius: "5px",
    backgroundColor: "#EEE8F2 !important",
    "& .MuiLinearProgress-barColorPrimary":{
        background: "linear-gradient(269.95deg, #6C328B 0.04%, #F42074 71.48%, #EF2B4B 99.95%)",
        borderRadius:"4px"
    }
  
  });


const styles: any = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    accordin:{
        boxShadow: '0px 0px 16px 0px #BFBEBE59 !important',
        marginBottom:'10px',
        borderRadius:'12px',
        backgroud:'#fff',
        width: "100%"

    },
    contentContainer: {
        display:'flex',
        gap:'15px',
        width:'100%'
    },
    rewardImg:{
        width:'70px',
        height:'70px',
        borderRadius:'8px'
    },
    rewardName:{
        fontSize:'16px',
        fontWeight:700,
        color:'#1C1B1B',
        lineHeight:'22px',
        '&::first-letter':{
          textTransform: 'uppercase'
        }
    },
    rewardDate:{
        fontSize:'14px',
        fontWeight:500,
        lineHeight:'22px',
        margin:'10px 0'
    },
    rewardDueDate:{
        fontSize:'14px',
        fontWeight:500,
        color:'#E47004',
        lineHeight:'22px',
        margin:'10px 0'
    },
    norewardText:{
        fontSize:'18px',
        fontWeight:600,
        color:' #484646',
        lineHeight:'24px',
        padding:'20px'
    }
  });

interface RewardAttibute1 {
    name:string
    icon:{
      id:number
      url:string
    }
    timeline:{
      tag:string
      color:string
    }[]
    progress_bar:number
}
interface RewardDataType1 {
    id:string
    attributes:RewardAttibute1
}

interface Props extends WithStyles<typeof styles> {
    rewardData:RewardDataType1[]
}

interface State {
  expanded: string | false;
}

class RewardsFrame extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({
      expanded: isExpanded ? panel : false,
    });
  };

  render() {
    const { classes,rewardData } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        {rewardData.length > 0 ?
        <>
        {rewardData.map(data =>
        <Accordion 
            expanded={expanded === data.id} onChange={this.handleChange(data.id)}
            className={classes.accordin}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color:'#1C1B1B'}}/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box className={classes.contentContainer}>
                <img src={data.attributes.icon.url} className={classes.rewardImg}/>
                <Box style={{width:'100%'}}>
                    <Typography className={classes.rewardName}>{data.attributes.name}</Typography>
                    <Typography className={classes.rewardDate} style={{color:data.attributes.timeline[0].color}}>{data.attributes.timeline[0].tag}</Typography>
                    <Box>
                    <StyledProgressBar variant="determinate" value={data.attributes.progress_bar * 100} />
                    </Box>
                </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
          </AccordionDetails>
        </Accordion>
         )}
         </> :
         <div style={{textAlign:'center'}}>
            <img src={emptyReward} alt="no data"/>
            <Typography className={classes.norewardText}>No Data found</Typography>
        </div>}
      </div>
    );
  }
}

export default withStyles(styles)(RewardsFrame);
// Customizable Area End