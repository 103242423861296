import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
import * as H from "history";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
const navigation = require("react-navigation");
import { toast } from "react-toastify";

interface User {
name: string;
age: number;
email: string;
}

 export interface UserRewardListing {
    id: number;
    type: string;
    attributes: {
      id: number;
      user_name: string;
      branch_name: string | null;
      user_progress: string | string;
      redemption_status: boolean;
      profile_image: {
          id: number,
          url: string
      },
    };
  };
  
interface RewardListData {
    data: UserRewardListing[];
};

interface StatusSuccess {
    message: string
}

interface ErrorStatus {
    errors: Array<StatusSuccess>
}
// Customizable Area End

export interface Props {
    // Customizable Area Start
    history: H.History;
    location: typeof navigation;
    navigation: typeof navigation;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    orderDirection: string;
    orderBy: keyof User | string;
    rewardsData: RewardListData;
    rewardId: string;
    rewardName: string;
    isLeave: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}


export default class CreateRewardController extends BlockComponent<Props, S, SS> {

    // Customizable Area Start
    getRewardsDataCallId: string = "";
    putChangeStatusCallId: string = "";
    getAchievementDataCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            orderDirection: "asc",
            orderBy: "name",
            rewardsData: {
                data: []
            },
            rewardId: "",
            rewardName: "",
            isLeave: false
            // Customizable Area End
        };

        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
       const rowId = `${this.props.location.state.rewardId}`;
       const rowName = this.props.location.state.rewardName;
       const checkReward = this.props.location.state.isRewardsActive;
       
       this.setState({
        rewardId: rowId,
        rewardName: rowName
        } , () => {
        checkReward === "reward" ? 
        this.getRewardsDataApiCall()
        :
        this.getAchievementDataApiCall();
       });
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            if (responseJson) {
                if (responseJson.data || responseJson.message) {
                    this.apiSuccessFunctions(apiRequestCallId, responseJson);
                }
                if (responseJson.errors) {
                    this.apiFailureFunctions(apiRequestCallId, responseJson);
                }
            }
        }
    }

    apiSuccessFunctions = async (apiCallId: string, response: RewardListData & StatusSuccess) => {
      if(apiCallId === this.getRewardsDataCallId){
        this.getRewardsDataSuccess(response)
      }
      if(apiCallId === this.putChangeStatusCallId){
        this.putChangeStatusSuccess(response)
      }
      if(apiCallId === this.getAchievementDataCallId){
        this.getAchievementDataSuccess(response)
      }
    };
    

    apiFailureFunctions = async (apiCallId: string, response: ErrorStatus & object) => {
        if(apiCallId === this.putChangeStatusCallId){
            this.putChangeStatusFailure(response)
        }
    };

    rewardApiCallId = async (valueData: {
        body?: {};
        type?: string;
        contentType?: string;
        method?: string;
        endPoint?: string;
    }) => {
        let token = localStorage.getItem("token");
        let { contentType, method, endPoint, body, type } = valueData;
        let requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        let header = {
            "Content-Type": contentType,
            token,
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                type === "" ? JSON.stringify(body) : body
            );
        requestMessage.addData(

            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    handleSortAsc = (property: keyof UserRewardListing['attributes']) => {
        this.setState({
          orderDirection: "asc",
          orderBy: property,
        },()=>{
                const sortedUsers = this.state.rewardsData.data.sort((a, b) => {
                return `${a?.attributes[property]}`?.localeCompare(`${b?.attributes[property]}`);
        });
            this.setState({rewardsData:{data:sortedUsers}})
        });
    };
    
    handleSortDesc = (property: keyof UserRewardListing['attributes']) => {
        this.setState({
          orderDirection: "desc",
          orderBy: property,
        },()=>{
            const sortedUsers = this.state.rewardsData.data.sort((a, b) => {
                return `${b?.attributes[property]}`?.localeCompare(`${a?.attributes[property]}`);
            });
            this.setState({rewardsData:{data:sortedUsers}})
        });
    }; 
      
    getRewardsDataApiCall = async () => {
        this.getRewardsDataCallId = await this.rewardApiCallId({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.exampleApiContentType,
            endPoint: `${configJSON.manageRewardsEndPoint}?id=${this.state.rewardId}`
        });
    };

    getRewardsDataSuccess = (response: RewardListData) => {  
      this.setState({rewardsData: response});
    };

    handleBackAchievement = () => {
        this.props.history.push("/RewardsAndAchievement")
    };

    putChangeStatusApiCall = async (id: number) => {
        this.putChangeStatusCallId = await this.rewardApiCallId({
            method: configJSON.examplePutMethod,
            contentType: configJSON.exampleApiContentType,
            endPoint: `${configJSON.changeRewardStatusEndPoint}?id=${id}`
        });
    };

    putChangeStatusSuccess = (response: StatusSuccess) => {
     if(response.message){
       toast.success("Status update successfully")
     }  
      this.getRewardsDataApiCall();
    };

    putChangeStatusFailure = (errorResponse: ErrorStatus) => {
        if (errorResponse.errors.length > 0) {
            errorResponse.errors.forEach((error) => {
                toast.error(error.message, { autoClose: 3000 })
            });
        }
    };

    getAchievementDataApiCall = async () => {
        this.getAchievementDataCallId = await this.rewardApiCallId({
            method: configJSON.validationApiMethodType,
            contentType: configJSON.exampleApiContentType,
            endPoint: `${configJSON.manageAchievementEndPoint}?id=${this.state.rewardId}`
        });
    };

    getAchievementDataSuccess = (response: RewardListData) => { 
     this.setState({rewardsData: response});
    };

    // Customizable Area End
}