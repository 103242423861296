import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible, slider1, slider2, slider3 } from "./assets";
import { toast } from 'react-toastify';
import { Button } from "@material-ui/core";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DoneIcon from '@material-ui/icons/Done';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import { removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
const group = require("../assets/Group 8.png");
import {  FileWithPath } from 'react-dropzone';
import { ApiResponse } from "../../../blocks/customform/src/ultils";
// import 'react-toastify/dist/ReactToastify.css';

interface NoteEditResponse {
  data:{
    id:string;
    attributes:{
      chapter_title:string
    }
  },
  error: { message: string };
  errors: Array<ApiResponse["error"]>
}

interface DeleteResponse1 {
  message:string;
  error: { message: string };
  errors: Array<ApiResponse["error"]>
}

interface NoteType1 {
  id:string;
  type:string;
  attributes:{
    title:string;
    description:string;
    is_private:boolean;
    created_at:string;
    link:string | null;
    note_type:string;
    course_timestamp:string;
    is_read:boolean;
    image:{
        id:number;
        file_name:string;
        url:string;
    }
    is_editable:boolean;
    course:{
      course_id:number;
      chapter_id:number;
      lesson_name:string
    },
    created_date:string;
  }
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history?: any;
  location?: any;
  // Customizable Area Start
  handleCartItemsCountInTopbar?: (count: number) => void;
  // Customizable Area End
}

interface S {
  startCourseModal:boolean;
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  currRating: any;
  grapValues: any;
  allCourseLibraryData: any;
  open: any;
  priceFree: any;
  style: boolean,
  cartItemsCount: number;
  activeTab: number;
  activeLessonId:number|null;
  lockedPhases:any[]
  noteData:NoteType1[];
  openNoteModal:boolean;
  noteId:string;
  isShowBtn:boolean;
  editNoteModal:boolean;
  titleValue:string;
  descriptionValue:string;
  imageFile:FileWithPath | { id: number; url: string; file_name: string; } |null;
  isAttachmentChange:boolean;
  validationError:boolean;
  titleError:boolean;
  titleErrorMessage:string;
  descriptionError:boolean;
  descriptionErrorMessage:string;
  FileType:string;
  uploadFileProgress:number;
  uploadFileStatus:string;
  deleteImageModal:boolean;
  timeStamp:string;
  deleteNoteModal:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetCourseLibraryData: any
  PostCourseLibraryData: any
  param_id: any
  param_id_title: any;
  AddCartItemID: any;
  getCartItemsApiCallId: string | Message = "";
  getNoteApiId: string = "";
  addToCartApiCallId: string = "";
  editNoteApiId:string = "";
  deleteNoteApiId:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      startCourseModal:false,
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: '',
      grapValues: 'Week',
      currRating: '',
      isShowBtn: false,
      allCourseLibraryData: {},
      priceFree: false,
      open: false,
      style: true,
      cartItemsCount: 0,
      activeTab:0,
      activeLessonId:null,
      lockedPhases:[],
      noteData:[],
      openNoteModal:false,
      noteId:'',
      editNoteModal:false,
      titleValue:'',
      descriptionValue:'',
      imageFile:null,
      isAttachmentChange:false,
      validationError:false,
      titleError:false,
      titleErrorMessage:'',
      descriptionError:false,
      descriptionErrorMessage:'',
      FileType:"images",
      uploadFileProgress:0,
      uploadFileStatus: "notStarted",
      deleteImageModal:false,
      timeStamp:'',
      deleteNoteModal:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.param_id = this.props.navigation.getParam("id");
    this.param_id_title = this.props.navigation.getParam("title");

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.getCourseLibraryData(this.param_id);
    this.getCartItems();
    this.getNoteData(this.param_id)
    window.scrollTo(0, 0)
    await removeStorageData("programId")
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.getCourseLibraryData();
    }
  }

  async receive(from: string, message: Message) {
    const messageId = message.id;
    const restApiResponceMessage = getName(MessageEnum.RestAPIResponceMessage);
    const restApiResponceDataMessage = getName(MessageEnum.RestAPIResponceDataMessage);
    const restApiResponceSuccessMessage = getName(MessageEnum.RestAPIResponceSuccessMessage);

    if (messageId === getName(MessageEnum.AccoutLoginSuccess)) {
      this.accountLoginSuccessHandle(message);
    }

    // Customizable Area Start
    if (messageId === restApiResponceMessage) {
      const apiRequestCallId = message.getData(restApiResponceDataMessage);
      const responseJson = message.getData(restApiResponceSuccessMessage);

      if (apiRequestCallId && responseJson.data) {
        switch (apiRequestCallId) {
          case this.GetCourseLibraryData:
            this.setState({
              allCourseLibraryData: responseJson,
              currRating: responseJson.data.attributes.average_rating,
              open: false,
              priceFree: responseJson.data.attributes.button === "cart",
            },()=>this.setLockPhases());
            break;

          case this.PostCourseLibraryData:
            this.getCourseLibraryData();
            if (this.state.allCourseLibraryData?.data?.attributes?.button === "enroll") {
              toast('Course Add in Personal');
            }
            break;

          case this.AddCartItemID:
            this.handleAddCartItemResponse(responseJson);
            break;

          case this.getCartItemsApiCallId:
            this.handleGetCartItemsApiResponse(responseJson);
            break;

          case this.getNoteApiId:
            this.setState({
              noteData:responseJson.data
            });
            break;

          case this.editNoteApiId:
            this.handleEditApiResponse(responseJson)
            break;

          default:
            break;
        }
      }
      if(apiRequestCallId === this.deleteNoteApiId){
        this.handleDeleteApiResponse(responseJson)
      }
    }
    // Customizable Area End
  }


  setLockPhases = () => {
    if(this.state.allCourseLibraryData.data && this.state.allCourseLibraryData.data.attributes.phases && this.state.allCourseLibraryData.data.attributes.phases.length > 0){
       const lockedData = this.state.allCourseLibraryData.data.attributes.phases.filter((phase:any)=>phase.is_lock).map((phase:any)=>phase.phase_id)
       this.setState({lockedPhases:lockedData})
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };
  
  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  accountLoginSuccessHandle = (message: Message) => {
    let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

    this.showAlert(
      "Change Value",
      "From: " + this.state.txtSavedValue + " To: " + value
    );

    this.setState({ txtSavedValue: value });
  }
  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  startPlay = (id: any) => {
    this.props.history.push(`/title/${id?.attributes?.course_video_track?.last_video_id}${id.attributes?.title.replace(/\s/g, '-')}`, { program: this.props.history.location.state.program })
  }

  startButton = (ele: any) => {
    this.props.history.push(`/title/${ele?.id}${this.state.allCourseLibraryData?.data?.attributes?.title.replace(/\s/g, '-')}`, { program: this.props.history.location.state.program })
  }

  handleSubscription = (data: any) => {
    this.props.history.push('/Subscription', { name: data, type: 'Course' })
  }
  handleShowButton = () =>{
    this.setState({isShowBtn:!this.state.isShowBtn})
  }

  getCourseLibraryData = async (id?: any) => {

    this.setState({ open: true });
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetCourseLibraryData = requestMessage.messageId;

    const p_id = id || this.props.navigation.getParam('id');
    const p_title = this.props.navigation.getParam("title")
    const p_course = this.props.navigation.getParam("type")

    const endpoint = p_course === 'Program'
      ? `bx_block_playlist/user_courses?program_id=${this.props.history.location.state.program}&id=${p_id}&title=${p_title}`
      : `bx_block_playlist/user_courses?id=${p_id}&title=${p_title}`;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  postXpandLibraryData = async (id: any) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const httpBody = {
      "courseable_id": id,
      "courseable_type": "BxBlockCoursecreation::Course" // course: "BxBlockCoursecreation::Course, program: "BxBlockCoursecreation::Program"
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PostCourseLibraryData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_playlist/enroll_courses`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'Post'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  // Add item to cart
  addCartItem = async (id: any, type: string) => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };

    const payload = { course_id: id };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AddCartItemID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "Post"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Handle add to cart response
  handleAddCartItemResponse = (response: any) => {
    if (response?.errors) {
      toast.error("Something went wrong");
      return;
    }

    if (response.message) {
      toast.warning('Item already in cart');
    } else {
      toast.success('Item has been added to your cart.')
      this.setState({
        cartItemsCount: this.state.cartItemsCount + 1
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  }

  // Get current cart items
  getCartItems = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addCartItemApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Handle get cart items api response
  handleGetCartItemsApiResponse = (response: any) => {
    if (response?.error) {
      console.log("ERROR FETCHING CART ITEMS");
    } else if (response?.message) {
      this.setState({
        cartItemsCount: 0,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    } else if (response?.data) {
      const oneTimePaymentCartItems = response.data.attributes.cart_item;
      const subscriptionCartItems = response.data.attributes.subscription_cart_item;
      const totalCartItemsCount = oneTimePaymentCartItems.length + subscriptionCartItems.length;
      this.setState({
        cartItemsCount: totalCartItemsCount,
      }, () => {
        this.props?.handleCartItemsCountInTopbar &&
          this.props.handleCartItemsCountInTopbar(this.state.cartItemsCount);
      });
    }
  };

  renderBuyButton = (handleApi:any, styles:any) => {
    if (this.state.allCourseLibraryData.data?.attributes?.button === "subscription") {
        return (
            <Button
                id="start-course-id-tst1"
                onClick={() => this.addCartItem(this.state.allCourseLibraryData?.data?.id, this.state.allCourseLibraryData?.data?.type)}
                style={styles.course_buy}
            >
                Buy Subscription ${this.state.allCourseLibraryData?.data?.attributes?.price}
            </Button>
        )
    } else {
        return (
            <Button
                id="start-course-id-tst2"
                onClick={() => handleApi()}
                startIcon={this.state.allCourseLibraryData?.data?.attributes?.button === "enroll" ? null : <ShoppingCartOutlinedIcon />}
                style={styles.course_start}
            >
                {this.state.allCourseLibraryData?.data?.attributes?.button === "enroll" ? (
                    <img src={group} alt="Enroll" />
                ) : (
                    <>${this.state.allCourseLibraryData?.data?.attributes?.price}</>
                )}
            </Button>
        )
    }
}

playeTheLesson = async (btnType:string) => {
  const videos = this.state.allCourseLibraryData.data.attributes.videos.length > 0 ? this.state.allCourseLibraryData.data.attributes.videos : this.state.allCourseLibraryData.data.attributes.phases.filter((phase:any)=>!phase.is_lock).map((phase:any)=> [...phase.videos]).flat()
  const nextLesson = btnType === "start" ? videos[0] : videos.find((videos:any,index:number)=> !videos.is_lock && !videos.is_completed) || videos.slice().reverse().find((videos:any,index:number)=> videos.is_completed)
  if(nextLesson.id){
  await setStorageData("lessonId",nextLesson.id)
   }
   if(this.props.history?.location?.state?.program){
    await setStorageData("programId",this.props.history.location.state.program)
   }
  this.props.history.push(`/lesson-detail`)
}

backBtn = () => {
    window.history.back()
}

handleNavigateToLesson = async (productCard:any) => {
  if(this.props.history?.location?.state?.program){
    await setStorageData("programId",this.props.history.location.state.program)
   }
  await setStorageData("lessonId",productCard.id)
  await setStorageData("courseId",this.param_id)
      return this.props.history.push(`/lesson-detail`)
  }

  handleToggleTab = (activeTab:number) => {
    this.setState({
      activeTab:activeTab
    })
  }

getNoteData = async (id: number) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getNoteApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.noteCourseApiEndpoint + id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleOpenNote = (id:string) => {
    this.setState({
      openNoteModal:!this.state.openNoteModal,
      noteId:id
    })
  }
  handleOpenEditNote = (noteId:string) => {
    this.setState({
      editNoteModal:true,
      noteId:noteId
    })
    this.state.noteData.filter(data => data.id === noteId).forEach(item => {
      return(
        this.setState({
          titleValue:item.attributes.title,
          descriptionValue:item.attributes.description,
          imageFile:item.attributes.image ? { ...item.attributes.image, name: item.attributes.image?.file_name, type: "image/png" }as { id: number; url: string; file_name: string; } : null,
          timeStamp:item.attributes.course_timestamp
        })
      )
    })
  }
  handleCloseEditNote = () => {
    this.setState({
      editNoteModal:false,
      titleValue:'',
      descriptionValue:'',
      isAttachmentChange:true,
      imageFile:null,
      validationError:false,
      titleError:false,
      descriptionError:false,
      titleErrorMessage:'',
      descriptionErrorMessage:'',
      openNoteModal:false
    })
  }
  handleChangeTitle = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      titleValue:event.target.value.replace(/^\s+/, ''),
    })
    if(event.target.value.length <= 20 && event.target.value.length >= 5){
      this.setState({
        titleError:false,
        titleErrorMessage:''
      })
      
    }else{
      this.setState({
        titleError:true,
        titleErrorMessage:'Title should be more than 5 character and less than 20 character',
        validationError:false
      })
    }
    
  }
  onSelectFile = (file: File) => {
    this.setState({ isAttachmentChange: true,FileType:"image", imageFile: file, uploadFileProgress: 0, uploadFileStatus: 'uploaded', })
  }
  SelectedIconFileDeleted=()=>{
    this.setState({ isAttachmentChange: true, imageFile: null,deleteImageModal:false})
  }

  handleChangeDescription = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      descriptionValue:event.target.value.replace(/^\s+/, '')
    })
    if(event.target.value.length <= 500 && event.target.value.length >= 5){
      this.setState({
        descriptionError:false,
        descriptionErrorMessage:''
      })
      
    }else{
      this.setState({
        descriptionError:true,
        descriptionErrorMessage:'Description should be more than 5 character and less than 500 character',
        validationError:false
      })
    }
  }
  deleteImagePopup = () => {
    this.setState({
      deleteImageModal:!this.state.deleteImageModal
    })
  }
  handleEditAPI = async() => {
    const header = {
      token: localStorage.getItem("token")
    };
    const formData = new FormData()
    formData.append("note[title]",this.state.titleValue)
    formData.append("note[description]",this.state.descriptionValue)
    if (this.state.imageFile && 'url' in this.state.imageFile) {
      const responseFile = await fetch(this.state.imageFile.url);
      const blobResponse = await responseFile.blob();
      const file = new File([blobResponse], this.state.imageFile.file_name || 'file', { type: blobResponse.type });
      
      formData.append("note[image]", file);
    }
    if (this.state.imageFile instanceof File) {
      formData.append("note[image]", this.state.imageFile);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editNoteApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateNoteEndPoint + this.state.noteId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleEditApiResponse = (responseJson: NoteEditResponse) => {
    if(responseJson.data){
      toast.success(`${responseJson.data.attributes.chapter_title} edit has been saved correctly`);
      this.setState({
        editNoteModal:false
      })
      this.handleOpenNote(responseJson.data.id)
      this.getNoteData(this.param_id)
    }
  }
  handleDeleteNote = () => {
    this.setState({
      deleteNoteModal:!this.state.deleteNoteModal
    })
  }
  handleDeleteApiCall = (noteId:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType
    };
    this.deleteNoteApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteNoteEndPoint + noteId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDeleteApiResponse = (response:DeleteResponse1) =>{
    if(response.message){
      toast.success(`${response.message}`);
      this.setState({
        deleteNoteModal:false,
        openNoteModal:false
      })
      this.getNoteData(this.param_id)
    }else{
      toast.error(response?.error?.message ?? response.errors[0].message);
    }
  }

  addToCart = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType
    };

    const data = {
           "course_id": this.state.allCourseLibraryData.data.id
      }
    this.addToCartApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addToCartApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
