import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { ApiResponse } from "../../../blocks/customform/src/ultils";
import { toast } from "react-toastify";
import {  FileWithPath } from 'react-dropzone';

interface PersonalNoteType1 {
  id:string;
  type:string;
  attributes:{
    id:number;
    title:string;
    description:string;
    note_type:string;
    course_timestamp:string;
    created_at:string;
    is_private:boolean;
    link:string | null;
    is_read:boolean;
    image:{
        id:number;
        file_name:string;
        url:string;
    }
    is_editable:boolean;
    created_date:string;
    course:{
      course_id:number;
      chapter_id:number;
      lesson_name:string
    }
  }
}
interface DeleteApiresponseType1 {
  message:string;
  error: { message: string };
  errors: Array<ApiResponse["error"]>
}
interface PersonalNote1 {
  data:{
    id:string;
    type:string;
    attributes:{
      chapter_title:string;
      image:null | {
        id:number;
        file_name:string;
        url:string;
    }
    }
  }
  error: { message: string };
  errors: Array<ApiResponse["error"]>
}

interface RewardAttibute1 {
  name:string
  icon:{
    id:number
    url:string
  }
  timeline:{
    tag:string
    color:string
  }[]
  progress_bar:number
}
interface RewardDataType1 {
  id:string
  attributes:RewardAttibute1
}
interface RewardResponse1{
  data:{
  id:string
  attributes:RewardAttibute1
  }[]
  reward_redeemed:number
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handelClose: any;
  history?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isModalOpen: boolean;
  isNoteModalOpen: boolean;
  userProfileData: any,
  allNotesData: PersonalNoteType1[],
  userloading: boolean,
  isloading: any,
  handleTabValue: any,
  searchValue: any,
  recentInsightsData: any,
  recentActivityData: any,
  listWatchedData: any;
  listTimeData: any,
  progressLoading: any,
  videoWatchLoading: any,
  sort:boolean,
  allReportsData: any,
  reportsLoading: any,
  reportsSearch: any,
  recentSearchValue: any,
  recentTabValue: any,
  recentLoading: any,
  recentInsightLoading: any,
  timeSelected: any,
  videoSelected: any,
  values: any,
  listTimeHours: any,
  userData_Admin: any,
  chartData: any,
  watchChartData: any,
  listReportTimeData: any,
  listReportTimeHours: any,
  listReportWatchedData: any,
  libraryCheckedData2:any,
  selectedIndices:any,
  filter:boolean,
  libraryCheckedData3:any,
  checkboxChecked1:any,
  checked: any,
  reportCategaries:any,
  checkboxChecked:any,
  notePreviewId:string
  deletePopUpOpen:boolean
  title:string;
  description:string;
  editNoteModal:boolean;
  attachedFile:FileWithPath | { id: number; url: string; file_name: string; } |null,
  isAttachmentChange:boolean,
  FileType:string,
  uploadFileProgress:number;
  uploadFileStatus:string;
  titleValidationError:boolean;
  titleErrorMessage:string;
  descriptionValidateError:boolean;
  descriptionValidateMessage:string;
  validationFieldError:boolean;
  deleteImageModal:boolean;
  achiveTab:string;
  statusTab:string;
  rewardLoading:boolean;
  rewardData:RewardDataType1[];
  rewardCounter:number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileID: string = ""
  getAllNotesID: string = ""
  getRecentInsightsID: string = ""
  getRecentActivityID: string = ""
  getListWatchedID: string = ""
  getReportListWatchedID: string = ""
  getReportListTimeID: string = ""
  getListTimeID: string = ""
  getAllReportsID: string = ""
  addTimeSpentID: string = ""
  GetUserDetail: string = ""
  video_web_track: string = ""
  video_web_Watch: string = ""
  libraryCheckedDataSort: string = ""
  GetAllcategoriesData:string = ""
  deleteNoteId:string = ""
  addPersonalNoteApiId:string = ""
  editPersonalNoteApiId:string = ""
  rewardAchievApiID:string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isModalOpen: false,
      isNoteModalOpen: false,
      sort:false,
      filter:false,
      userProfileData: {},
      allNotesData: [],
      userloading: true,
      isloading: true,
      handleTabValue: 0,
      searchValue: '',
      listReportWatchedData: [],
      recentInsightsData: [],
      recentActivityData: [],
      listWatchedData: [],
      listReportTimeHours: [],
      listTimeData: [],
      listReportTimeData: [],
      progressLoading: true,
      videoWatchLoading: true,
      allReportsData: [],
      reportsLoading: true,
      reportsSearch: '',
      recentSearchValue: '',
      recentTabValue: 0,
      recentLoading: true,
      recentInsightLoading: true,
      userData_Admin: {},
      chartData: {},
      checked:[],
      watchChartData: {},
      timeSelected: 'week',
      videoSelected: 'week',
      values: ["week", "month", "year"],
      listTimeHours: 0,
      selectedIndices:'',
      libraryCheckedData2: [
        {
          id: 1,
          text: 'Course/Program',
          name: 'course_name',
          checked1: true,
        },
        {
          id: 2,
          text: 'Xpert Name',
          name: 'expart_name',
          checked3: true,
        },
      ],
      checkboxChecked1: {},
      reportCategaries: {},
      checkboxChecked:{},
      libraryCheckedData3: [
        {
          id: 0,
          text: 'Due this week',
          name: 'due_this_week',
          checked1: true,
        },
        {
          id: 1,
          text: 'Due this mounth',
          name: 'due_this_mounth',
          checked3: true,
        },
        {
          id: 2,
          text: 'Past Due',
          name: 'all_due',
          checked4: true,
        },
        {
          id: 3,
          text: 'Newly Added',
          name: 'new_added',
          checked4: true,
        },

      ],
      notePreviewId:'',
      deletePopUpOpen:false,
      title:'',
      editNoteModal:false,
      description:'',
      attachedFile:null,
      isAttachmentChange:false,
      FileType:"images",
      uploadFileProgress:0,
      uploadFileStatus: "notStarted",
      titleValidationError:false,
      titleErrorMessage:'',
      descriptionValidateError:false,
      descriptionValidateMessage:'',
      validationFieldError:false,
      deleteImageModal:false,
      achiveTab:'achievements',
      statusTab:'all_courses',
      rewardLoading:false,
      rewardData:[],
      rewardCounter:0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    //this.handlechange = this.handlechange.bind(this) 
    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
    this.handleAddNotesOpenModal = this.handleAddNotesOpenModal.bind(this)
    this.handleAddNotesCloseModal = this.handleAddNotesCloseModal.bind(this)
    // Customizable Area End
  }



  getDataFromAPi = () => {
    this.getReportListWatchedData();
    this.getReportListTimeData();
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      // api call start =======================================
      // user profile api response 
      if (apiRequestCallId === this.GetAllcategoriesData){
        this.setState({reportCategaries: responseJson})
      }
      if (apiRequestCallId === this.getUserProfileID) {
        if (responseJson && !responseJson.error) {
          this.getReportListWatchedData();
          // console.log(responseJson.user_info.avatar)
          const profile = responseJson.user_info
          localStorage.setItem('profile', JSON.stringify(profile))
          this.setState({
            userloading: false,
            userProfileData: responseJson
          })
        } else {
          this.setState({
            userloading: false,
          });
        }
      }
      // all notes api response 
      if (apiRequestCallId === this.getAllNotesID) {


        if (responseJson && !responseJson.error) {

          this.setState({
            allNotesData: responseJson.data,
            isloading: false
          })
        } else {
          this.setState({
            isloading: false,
          });
          console.log(errorReponse)
        }
      }
      // all recent insights response 
      if (apiRequestCallId === this.getRecentInsightsID) {


        if (responseJson && !responseJson.error) {
          // console.log(responseJson.recent_insight , "recent insight")     
          this.setState({
            recentInsightsData: responseJson.recent_insight,
            recentInsightLoading: false
          })
        } else {
          this.setState({
            recentInsightLoading: false
          });
          console.log(errorReponse)

        }
      }
      // all recent activity response 
      if (apiRequestCallId === this.getRecentActivityID) {


        if (responseJson && !responseJson.error) {
          // console.log(responseJson.data , "recent activity")     
          this.setState({
            recentActivityData: responseJson.data,
            recentLoading: false
          })
        } else {
          this.setState({
            recentLoading: false,
          });
          console.log(errorReponse)
        }
      }
      // all list watched response 
      if (apiRequestCallId === this.getListWatchedID) {


        if (responseJson && !responseJson.error) {
          // console.log(responseJson , "time watched")   
          this.setState({
            listWatchedData: responseJson,
            videoWatchLoading: false
          })
        } else {


          this.setState({
            videoWatchLoading: false,
          });
          console.log(errorReponse)

        }
      }
      if (apiRequestCallId === this.getReportListWatchedID) {
        if (responseJson && !responseJson.error) {
          this.setState({
            listReportWatchedData: responseJson,
            videoWatchLoading: false
          }, () => { })
        } else {
          this.setState({
            videoWatchLoading: false,
          });
        }
      }
      if (apiRequestCallId === this.getReportListTimeID) {
        if (responseJson && !responseJson.error) {
          let newarr = responseJson?.data?.map((data: any) => {
            return [data?.track_date, data?.track_time]
          })
          this.setState({
            listReportTimeData: newarr,
            listReportTimeHours: responseJson.total_time,
            progressLoading: false
          })
        } else {
          this.setState({
            progressLoading: false,
          });
          console.log(errorReponse)
        }
      }
      // all list time response 
      if (apiRequestCallId === this.getListTimeID) {


        if (responseJson && !responseJson.error) {

          this.setState({
            listTimeData: responseJson.data,
            listTimeHours: responseJson.data,
            progressLoading: false
          })



        } else {


          this.setState({
            progressLoading: false,
          });

        }
      }

      // AdminUserProfile
      if (apiRequestCallId === this.GetUserDetail) {
        this.setState({ userData_Admin: responseJson })
      }
      //video_web_track
      if (apiRequestCallId === this.video_web_track) {
        this.setState({ chartData: responseJson })
      }

      //video_web_watch
      if (apiRequestCallId === this.video_web_Watch) {
        this.setState({ watchChartData: responseJson })
      }
      // all recent activity response 
      if (apiRequestCallId === this.getAllReportsID) {
        if (responseJson && !responseJson.error) {
          this.getReportListWatchedData();
          this.closeSearchSort();
          this.setState({
            allReportsData: responseJson.data,
            reportsLoading: false
          })
        }
      }
      // add time spent 
      if (apiRequestCallId === this.addTimeSpentID) {

        if (responseJson) {
          console.log(responseJson, "add time spent ")
        } else {
          console.log(errorReponse, 'add time spent');

        }
      }
      if(apiRequestCallId === this.deleteNoteId){
        this.handleDeleteNoteResponse(responseJson)
      }
      if(apiRequestCallId === this.addPersonalNoteApiId){
        this.getAddPersonalNoteResponse(responseJson)
      }
      if(apiRequestCallId === this.editPersonalNoteApiId){
        this.getEditPersonalNoteResponse(responseJson)
      }
      if(apiRequestCallId === this.rewardAchievApiID){
        this.getRewrdAchiveresponse(responseJson)
      }
    }



    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }


  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  searchShort = () => {
    this.setState({ sort: true })
  }
  closeSearchSort = () => {
    this.setState({ sort: false })
  }
  closeSearchFilter = () => {
    this.setState({filter: false})
  }
  OpenSearchFilter = () => {
    this.setState({filter: true})
  }
  checkedhandleChange2 = (event: any) => {
    this.setState({ selectedIndices: event.target.value });
  };

  // checkedhandleChange3 = (id: any, event: any) => {
  //   event.persist();
  //   this.setState((prevState) => {
  //     const { checkboxChecked1, checked } = prevState;
  //     checkboxChecked1[id.checked1] = event.target.checked;
  //     let updatedChecked;
  //     if (event.target.checked) {
  //       updatedChecked = [...checked, id];
  //     } else {
  //       updatedChecked = checked.filter((item: any) => item !== id);
  //     }
  //     const params = updatedChecked.map((id: any) => `all_due=${id}`).join('&');
  //     this.libraryCheckedDataSort = params;
  //     return { checkboxChecked1, checked: updatedChecked };
  //   });
  // };

  // checkedhandleChange3 = (data: any, event: any) => {
  //   event.persist();
  //   this.setState((prevState) => {
  //     const { checkboxChecked1, checked } = prevState;
  //     checkboxChecked1[data.checked1] = event.target.checked;
  //     let updatedChecked;
  //     if (event.target.checked) {
  //       updatedChecked = [...checked, data.checked1];
  //     } else {
  //       updatedChecked = checked.filter((item: any) => item !== data.checked1);
  //     }
  //     const params = updatedChecked.map((id: any) => `all_due=${id}`).join('&');
  //     this.libraryCheckedDataSort = params;
  //     return { checkboxChecked1, checked: updatedChecked };
  //   });
  // };

  checkedhandleChange3 = (id: any, event: any) => {
    event.persist();
    this.setState((prevState) => {
      const { checkboxChecked1, checked } = prevState;
      checkboxChecked1[id] = event.target.checked;
      let updatedChecked;
      if (event.target.checked) {
        updatedChecked = [...checked, id];
      } else {
        updatedChecked = checked.filter((item:any) => item !== id);
      }
      const params = updatedChecked.map((id:any) => `all_due=${id}`).join('&');
      this.libraryCheckedDataSort = params;
      return { checkboxChecked1, checked: updatedChecked };
    });
  };
  
  
  
  
  
  

  checkedhandleChange = (id: any, is_selected: any, event: any) => {
    const { checkboxChecked } = this.state;
    checkboxChecked[id] = event.target.checked;
    let data = this.state.checked;
    if (event.target.checked) {
      data.push(id);
    } else {
      const index = data.indexOf(id);
      if (index !== -1) {
        data.splice(index, 1);
      }
    }
    this.setState({ checkboxChecked, checked: data });
    const params = data.map((id: any) => `category_ids=${id}`).join('&');
    this.libraryCheckedDataSort = params;
  }

  // Customizable Area Start

  Allcategories_list = () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetAllcategoriesData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_categories/categories`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  CheckedDataSort = () => {
    this.setState({ reportsLoading: true })
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllReportsID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReportsEndPoint + `?${this.libraryCheckedDataSort}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'get'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // get api for user profile data 
  getUserProfileData() {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyProfileAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for all notes data 
  getAllNotesData(query: any = '', noteType: any = '') {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllNotesID = requestMessage.messageId;
    {localStorage.getItem('user_role') === "super_admin" ? 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotesAPiEndPoint + `?search=${query}&note_type=${noteType}&user_id=${this.props.navigation.getParam("id")}`
    ) : 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllNotesAPiEndPoint + `?search=${query}&note_type=${noteType}`
    )}

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  // get api for recent insights data 
  getRecentInsightsData(searchVal: any = '') {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecentInsightsID = requestMessage.messageId;
    {localStorage.getItem('user_role') === "super_admin" ? 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentInsightsAPiEndPoint + `?search=${searchVal}&user_id=${this.props.navigation.getParam("id")}`
    ) :
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentInsightsAPiEndPoint + `?search=${searchVal}`
    )}

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for recent activity data 
  getRecentActivityData(searchVal: any = '') {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRecentActivityID = requestMessage.messageId;

    {localStorage.getItem('user_role') === "super_admin" ? 
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentActivityAPiEndPoint + `?search=${searchVal}&user_id=${this.props.navigation.getParam("id")}`
    ) :
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRecentActivityAPiEndPoint + `?search=${searchVal}`
    );}

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for list watched data 
  getListWatchedData(filter: any = 'year') {
    console.log(filter, '7777777777777777');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListWatchedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllVideoWatchDataEndPoint + `?filter=week`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for list time data 
  getListTimeData = (filter: any = 'year') => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListTimeID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTimeSpanddataAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for list time data 
  getAllReportsData(title: any = "") {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllReportsID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReportsEndPoint + `?title=${title}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  applyShorting = () => {
    this.setState({ reportsLoading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllReportsID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllReportsEndPoint + `?sort_by=${this.state.selectedIndices}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // add time spent traning track data
  addTimeSpent(tiem: any = '', date: any = '') {
    console.log("called API ")
    let data = new FormData();
    data.append("track_time", "100");
    data.append("track_date", "2022-12-19");


    const header = {
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.addTimeSpentID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAddTimeSpentAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return requestMessage.messageId;

  }

  routeChange = () => {
    this.props.navigation.navigate("CourseNotes");
  }

  handleOpenModal =(id:string) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      notePreviewId:id
    })
  }
  handleNoteOpenModal = () => {
    this.setState({
      isNoteModalOpen: !this.state.isNoteModalOpen,
    })

    // console.log("model open")
  }

  handleCloseModal() {
    this.setState({
      isModalOpen: false
    })
  }

  handleAddNotesOpenModal() {
    this.setState({
      isNoteModalOpen: true,
      editNoteModal:false,
      title:'',
      description:'',
      isAttachmentChange:true,
      attachedFile:null
    })

  }
  handleAddNotesCloseModal() {
    this.setState({
      isNoteModalOpen: false,
      title:'',
        description:'',
        isAttachmentChange:true,
        attachedFile:null,
        isModalOpen:false,
        validationFieldError:false,
        titleValidationError:false,
        descriptionValidateError:false,
        titleErrorMessage:'',
        descriptionValidateMessage:''
    })
  }

  handleChangeTime = (event: any) => {
    this.setState({ timeSelected: event.target.value });
  }

  handleChangeVideo = (event: any) => {
    this.setState({ videoSelected: event.target.value });
  }

  /* istanbul ignore next */
  getAdminUserProfileData = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetUserDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_admin/user/${this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /* istanbul ignore next */
  getVideo_web_track_Admin = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.video_web_track = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_adhocreporting/course_video_track/video_web_track?filter=week&user_id=${this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /* istanbul ignore next */
  getVideo_web_Watch_Admin = async () => {
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.video_web_Watch = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_adhocreporting/training_track/web_track?user_id=${this.props.navigation.getParam("id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "get"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getReportListWatchedData = (filter: any = 'week') => {
    console.log(filter, '7777777777777777');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportListWatchedID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListWatchedAPiEndPoint + `?filter=${filter}&web=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  // get api for list time data 
  getReportListTimeData = (filter: any = 'week') => {
    console.log(filter, '5555555555555555555555');

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: localStorage.getItem('token')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getReportListTimeID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getListTimeAPiEndPoint + `?web=true&filter=${filter || this.state.timeSelected}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }
  handleOpendeleteModal = () => {
    this.setState({
      deletePopUpOpen:!this.state.deletePopUpOpen
    })
  }
  deleteNoteApiCall = (noteId:string) => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token: localStorage.getItem("token"),
      "Content-Type": configJSON.validationApiContentType
    };
    this.deleteNoteId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteNoteEndPoint + noteId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleDeleteNoteResponse = (response:DeleteApiresponseType1) => {
    if(response.message){
      toast.success(`${response.message}`);
      this.setState({
        deletePopUpOpen:false,
        isModalOpen:false,
        handleTabValue:0
      })
      this.getAllNotesData();
    }else{
      toast.error(response?.error?.message ?? response.errors[0].message);
    }
  }

  handleTitle = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      title:event.target.value.replace(/^\s+/, ''),
    })
    if(event.target.value.length <= 20 && event.target.value.length >= 5){
      this.setState({
        titleValidationError:false,
        titleErrorMessage:''
      })
      
    }else{
      this.setState({
        titleValidationError:true,
        titleErrorMessage:'Title should be more than 5 character and less than 20 character',
        validationFieldError:false
      })
    }
  }

  handleDescription = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      description:event.target.value.replace(/^\s+/, '')
    })
    if(event.target.value.length <= 500 && event.target.value.length >= 5){
      this.setState({
        descriptionValidateError:false,
        descriptionValidateMessage:''
      })
      
    }else{
      this.setState({
        descriptionValidateError:true,
        descriptionValidateMessage:'Description should be more than 5 character and less than 500 character',
        validationFieldError:false
      })
    }
  }

  onSelectFile = (file: File) => {
    this.setState({ isAttachmentChange: true,FileType:"image", attachedFile: file, uploadFileProgress: 0, uploadFileStatus: 'uploaded', })
  }
  SelectedIconFileDeleted=()=>{
    this.setState({ isAttachmentChange: true, attachedFile: null,deleteImageModal:false})
  }
  deleteImagePopup = () => {
    this.setState({
      deleteImageModal:!this.state.deleteImageModal
    })
  }
  handleAddNote = () => {
    if(this.state.title === "" || this.state.description === ""){
      this.setState({
        validationFieldError:true,
        descriptionValidateError:false,
        titleValidationError:false,
        descriptionValidateMessage:'',
        titleErrorMessage:''
      })
    }else if(this.state.descriptionValidateError || this.state.titleValidationError){
      this.setState({
        validationFieldError:false
      })
    }else{
      this.setState({
        validationFieldError:false
      })
     
      this.handleCreateNoteApiCall();
    }
  }
  handleCreateNoteApiCall = async() => {
    const header = {
      token: localStorage.getItem("token")
    };
    const formData = new FormData()
    formData.append("note[title]",this.state.title)
    formData.append("note[description]",this.state.description)
    if(this.state.attachedFile && !(this.state.attachedFile as { id: number; url: string; file_name: string; } )?.url){
      formData.append("note[image]",this.state.attachedFile as FileWithPath)
    }
    formData.append("note[note_type]",'personal')
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addPersonalNoteApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addPrsonalNoteEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getAddPersonalNoteResponse = (responseJson:PersonalNote1) => {
    if(responseJson.data){
      toast.success(`A note has been added to your profile correctly`);
      this.handleAddNotesCloseModal();
      this.setState({
        handleTabValue:0
      })
      this.getAllNotesData();
    }else{
      toast.error(responseJson?.error?.message ?? responseJson.errors[0].message);
    }
  }
  handleEditOpenModal = (noteId:string) => {
    this.setState({
      isNoteModalOpen:true,
      editNoteModal:true,
      notePreviewId:noteId
    })
    this.state.allNotesData.filter(data => data.id === noteId).forEach(item => {
      return(
        this.setState({
          title:item.attributes.title,
          description:item.attributes.description,
          attachedFile:item.attributes.image ? { ...item.attributes.image, name: item.attributes.image?.file_name, type: "image/png" }as { id: number; url: string; file_name: string; } : null
        })
      )
    })
  }
  handleEditPersonalNote = async() => {
    const header = {
      token: localStorage.getItem("token")
    };
    const formData = new FormData()
    formData.append("note[title]",this.state.title)
    formData.append("note[description]",this.state.description)
    if (this.state.attachedFile instanceof File) {
      formData.append("note[image]", this.state.attachedFile);
    }
    if (this.state.attachedFile && 'url' in this.state.attachedFile) {
      const response = await fetch(this.state.attachedFile.url);
      const blob = await response.blob();
      const file = new File([blob], 
        this.state.attachedFile.file_name || 'file', { type: blob.type });
        formData.append("note[image]", file);
      }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editPersonalNoteApiId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteNoteEndPoint + this.state.notePreviewId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getEditPersonalNoteResponse = (responseJson:PersonalNote1) => {
    if(responseJson.data){
      toast.success(`A note has been edited correctly`);
      this.handleAddNotesCloseModal();
      this.setState({
        handleTabValue:0
      })
      this.getAllNotesData();
    }else{
      toast.error(responseJson?.error?.message ?? responseJson.errors[0].message);
    }
  }
  handleAchiveTab = (tab:string) => {
    this.setState({
      achiveTab:tab
    },() => this.rewardAchievementApiCall())
  }
  handleActiveStatus = (status:string) => {
      this.setState({
        statusTab:status
      },() => this.rewardAchievementApiCall())
  }

  rewardAchievementApiCall = () => {
    this.setState({
      rewardLoading:true
    })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": localStorage.getItem("token")
    };
    this.rewardAchievApiID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 
      configJSON.rewardAchievEndPoint + '?filter_status=' + this.state.statusTab + '&ach_or_rew=' + this.state.achiveTab
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getRewrdAchiveresponse = (response:RewardResponse1) => {
    if(response.data){
      this.setState({
        rewardLoading:false,
        rewardData:response.data,
        rewardCounter:response.reward_redeemed
      })
    }else{
      this.setState({
        rewardLoading:true
      })
    }
  }

  // Customizable Area End
}