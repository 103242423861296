import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Dialog,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const editIcon = require("./edit_title.svg");
import DragAndDropFileUploadWithProgress from "../DragAndDropFileUploadWithProgress"
const uploadImg = require('./upload.svg')
const uploadeError = require('./upload_error.svg')
const editImage = require('./edit_image.svg')
const deleteImage = require('./trash_img.svg')
const closeImage = require('./icon_close.svg')
import {  FileWithPath } from 'react-dropzone';
interface IProps {
  classes?: any;
  open:boolean;
  handleCloseModal:() => void;
  lessonName:string;
  selectedThumbnailFile:() => void;
  type:string
  onSelectFile:(file:File) => void;
  selectedFile:FileWithPath | { id: number; url: string; file_name: string; } |null,
  progress:number
  uploadStatus:string
  timeStamp:string;
  iconType:string;
  titleValue:string;
  handleChangeTitle:(event: React.ChangeEvent<HTMLInputElement>) => void;
  titleError:boolean;
  titleErrorMessage:string;
  handleChangeDescription:(event: React.ChangeEvent<HTMLInputElement>) => void;
  descriptionError:boolean;
  descriptionErrorMessage:string;
  descriptionValue:string;
  handleCreateNote:() => void;
  validationError:boolean;
  editNoteModal:boolean;
  deleteImagePopup:() => void;
  deleteImageModal:boolean;
  handleEdit:() => void;
}

interface IState {
    isFocused: boolean;
}

class AddNote extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
          isFocused: false,
        };
      }
      handleFocus = () => {
        this.setState({ isFocused: true });
      };
      handleBlur = () => {
        this.setState({ isFocused: false });
      };

  render() {
    const {
      classes,
      open,
      handleCloseModal,
      lessonName,
      selectedThumbnailFile,
      type,
      onSelectFile,
      selectedFile,
      progress,
      uploadStatus,
      timeStamp,
      iconType,
      titleValue,
      handleChangeTitle,
      titleError,
      titleErrorMessage,
      descriptionValue,
      handleChangeDescription,
      descriptionError,
      descriptionErrorMessage,
      handleCreateNote,
      validationError,
      editNoteModal,
      deleteImagePopup,
      deleteImageModal,
      handleEdit
    } = this.props;
    return (
        <Dialog 
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="simple-dialog-title"
        style={{padding:'20px'}}
        PaperProps={{
            style: {
              margin: 'auto',
              maxWidth: '100%',
              overflowY: 'auto',
              zIndex: 9999,
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
              borderRadius: "16px",
              padding:'25px 25px'
            },
          }}
        >
            <Box>
                <Box className={classes.firstContainer}>
                    <Box className={classes.secondBox}>
                    {editNoteModal ? 
                    <Typography className={classes.previewNote}>Edit note</Typography> :
                    <Typography className={classes.previewNote}>Add note {lessonName}</Typography>

                    }
                   {(iconType === "video" || iconType === "audio") && <Typography className={classes.timeStamp}>{timeStamp}</Typography>} 
                    </Box>
                   <img src={closeImage} alt="close" className={classes.arrowBackImg} onClick={handleCloseModal}/>
                </Box>
                <Box className={classes.titleBox}>
                    <Typography className={classes.noteLabel}>Note title</Typography>
                    {titleValue ? 
                     <TextField 
                     id="standard-basic" 
                     placeholder="Course Note Name"
                     name="titleValue"
                     fullWidth
                     value={titleValue}
                     onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(event)}
                     InputProps={{
                         style:{
                             border:'none',
                             borderBottom:'1px solid #E5E2E1',
                             borderRadius: 0,
                             width:'100%'
                         }
                       }}
                       inputProps={{
                         style:{
                             padding: '10px 10px 10px 30px',
                             fontSize:'16px'
                         }
                     }}  
                     error={titleError}
                     helperText={titleErrorMessage}
                 />
                    :
                    <TextField 
                        id="standard-basic" 
                        placeholder="Course Note Name"
                        name="titleValue"
                        fullWidth
                        value={titleValue}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(event)}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                    <img src={editIcon} alt="edit"/>
                              </InputAdornment>
                            ),
                            style:{
                                border:'none',
                                borderBottom:'1px solid #E5E2E1',
                                borderRadius: 0,
                                width:'100%'
                            }
                          }}
                          inputProps={{
                            style:{
                                padding: '10px 10px 10px 30px',
                                fontSize:'16px'
                            }
                        }}  
                        error={titleError}
                        helperText={titleErrorMessage}
                    />
                    }
                    {(titleValue === "" && validationError) &&
                    <Typography className={classes.validationMessage}>Title is required</Typography>
                    }
                </Box>
                <Box className={classes.imageBox}>
                    <Typography className={classes.noteLabel}>Image</Typography>
                    {
                        selectedFile && typeof selectedFile !== 'string' && 'url' in selectedFile ?
                        (
                        <Box className={classes.imageMainBox}>
                            <img
                            className={classes.uploadedImg}
                            src={selectedFile.url}
                            />
                             {deleteImageModal &&
                <Box className={classes.deleteModal}>
                     <Typography className={classes.deleteImageText}>Delete image</Typography>
                    <Box className={classes.deleteButtonBox}>
                        <Button
                        variant="contained"
                        className={classes.cancelDeleteButton}
                        onClick={deleteImagePopup}
                        >Cancel</Button>
                        <Button
                        variant="contained"
                        className={classes.deletButton}
                        onClick={selectedThumbnailFile}
                        >Delete</Button>
                    </Box>
                </Box>
                    }
                            <Box className={classes.imageIconBox}>
                                <img src={deleteImage} alt="delete" className={classes.imageIcon} onClick={deleteImagePopup}/>
                            </Box>
                        </Box> ):(
                           selectedFile && selectedFile instanceof File ?
                            (
                                <Box className={classes.imageMainBox}>
                                <img
                                className={classes.uploadedImg}
                                src={URL.createObjectURL(selectedFile)}
                                />
                                  {deleteImageModal &&
                <Box className={classes.deleteModal}>
                     <Typography className={classes.deleteImageText}>Delete image</Typography>
                    <Box className={classes.deleteButtonBox}>
                        <Button
                        variant="contained"
                        className={classes.cancelDeleteButton}
                        onClick={deleteImagePopup}
                        >Cancel</Button>
                        <Button
                        variant="contained"
                        className={classes.deletButton}
                        onClick={selectedThumbnailFile}
                        >Delete</Button>
                    </Box>
                </Box>
                    }
                                <Box className={classes.imageIconBox}>
                                   {editNoteModal ?
                                <img src={deleteImage} alt="delete" className={classes.imageIcon} onClick={deleteImagePopup}/>
                            :<> <img src={editImage} alt="edit" className={classes.imageIcon} onClick={selectedThumbnailFile}/>
                                    <img src={deleteImage} alt="delete" className={classes.imageIcon} onClick={selectedThumbnailFile}/>
                                    </>}
                                </Box>
                            </Box> 
                            ):(
                                <>
                                <DragAndDropFileUploadWithProgress
                                selectedThumbnailFile={selectedThumbnailFile}
                                type={type}
                                image={uploadImg}
                                onSelectFile={onSelectFile}
                                errorImage={uploadeError}
                                selectedFile={selectedFile}
                                progress={progress}
                                uploadStatus={uploadStatus}
                                uploadingMessage={"This can take a few minutes depending on the file size and connection speed"}
                            />
                            </>
                            )
                        )
                       
                    }

                </Box>
                <Box>
                <Typography className={classes.noteLabel}>Description</Typography>
                <TextField 
                        id="outlined-basic" 
                        variant="outlined"
                        fullWidth
                        value={descriptionValue}
                        multiline
                        rows={3}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => handleChangeDescription(event)}
                        placeholder="Please write your description"
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        InputProps={{
                            style:{
                                borderColor:'#E5E2E1',
                                borderRadius: '8px',
                                background: this.state.isFocused || descriptionValue.length > 0 ? '#fff' : 'rgb(250, 240, 240)',
                                opacity:this.state.isFocused || descriptionValue.length > 0 ? '1':'0.74'
                            }
                          }}
                          inputProps={{
                            style:{
                                fontSize:'16px'
                            }
                        }} 
                        error={descriptionError} 
                        helperText={descriptionErrorMessage}
                        FormHelperTextProps={{
                            style:{
                                margin:'0'
                            }
                        }}
                    />
                    {(descriptionValue === "" && validationError) &&
                    <Typography className={classes.validationMessage}>Description is required</Typography>
                    }
                </Box>
                {editNoteModal ?
                <Box className={classes.editButtonBox}>
                    <Button className={classes.cancelButton} onClick={handleCloseModal}>Cancel</Button>
                    <Button variant="contained" className={classes.SaveButton} onClick={handleEdit}>Save Changes</Button>
                </Box> :
                <Box className={classes.buttonBox}>
                <Button
                variant="contained"
                className={classes.createButton}
                onClick={handleCreateNote}
                >
                    Create Note
                </Button>
                </Box>
                }
            </Box>
        </Dialog >
    );
  }
}


const styles = createStyles({
    firstContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between'
    },
    secondBox:{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
    },
    arrowBackImg:{
        cursor:'pointer'
    },
    previewNote:{
        color: '#1C1B1B',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '36px'
    },
    titleBox:{
        margin:'20px 0 10px'
    },
    noteLabel:{
        color: '#1C1B1B',
        lineHeight: '24px',
        paddingBottom:'10px'
    },
    lessonName:{
        color:' #787776',
        lineHeight: '22px'
    },
    timeStamp:{
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        color: '#1C1B1B',
        boxShadow: '0px 0px 16px 0px #22222000',
        background: '#EEE8F2',
        padding: '5px 15px',
        borderRadius: '10px'
    },
    description:{
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '24px',
        margin: '15px 0 5px'
    },
    imageBox:{
        margin:'20px 0'
    },
    uploadedImg:{
        width:'100%',
        height:'260px',
        borderRadius:'8px',
    },
    imageMainBox:{
        position: 'relative'
    },
    imageIconBox:{
        display:'flex',
        gap:'5px',
        position:'absolute',
        top:0,
        right:0,
        padding:'12px'
    },
    imageIcon:{
        cursor:'pointer'
    },
    createButton:{
        background: '#E47004',
        borderRadius:'88px',
        fontSize:'16px',
        fontWeight:600,
        lineHeight:'16px',
        color:'#FFF',
        textTransform:'capitalize',
        boxShadow:'none',
        width:'300px',
        height:'48px',
        '&:hover':{
            background: '#E47004',
            boxShadow:'none'
        }
    },
    buttonBox:{
        textAlign:'end',
        marginTop:'15px',
        '@media(max-width: 679px)': {
            textAlign:'center'
        },
    },
    validationMessage:{
        color: '#f44336',
        fontSize: '0.75rem',
        lineHeight:'1.66'
    },
    editButtonBox:{
        display:'flex',
        justifyContent:'end',
        gap: '80px',
        marginTop:'15px'
    },
    cancelButton:{
        color: '#652786',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '16px',
        textTransform: 'capitalize'
    },
    SaveButton:{
        background: '#E47004',
        boxShadow: 'none',
        borderRadius: '88px',
        color: '#fff',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '16px',
        textTransform: 'inherit',
        width:'300px',
        height:'48px',
        '&:hover':{
            background: '#E47004',
            boxShadow: 'none',
        }
    },
    deleteButtonBox:{
        display:'flex',
        justifyContent:'center',
        gap:'20px'
    },
    cancelDeleteButton:{
        background:'#E47004',
        borderRadius:'88px',
        boxShadow:'none',
        color:'#fff',
        fontWeight:600,
        padding: '8px 35px',
        textTransform: 'capitalize',
        '&:hover':{
            background:'#E47004',
            boxShadow:'none',
        }
    },
    deletButton:{
        background:' linear-gradient(270deg, #6C328B 0%, #F42074 50.5%, #EF2B4B 100%)',
        borderRadius:'88px',
        color:'#fff',
        fontWeight:600,
        padding: '8px 35px',
        textTransform: 'capitalize',
    },
    deleteModal:{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: "translate(-50%,-50%)",
        background: '#fff',
        borderRadius: '12px',
        boxShadow: '-6px -6px 28px 0px #85858540',
        padding: '20px 70px',
        textAlign: 'center'
    },
    deleteImageText:{
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '33px',
        color: '#1C1B1B',
        marginBottom: '25px'
    }
    
  });

export default withStyles(styles)(AddNote);
// Customizable Area End