export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const FORWARD_ICON = require("../assets/forward.png");
export const EDIT_ICON = require("../assets/edit_mobile.png");
export const Display_ICON = require("../assets/display.png");
export const Right_ICON = require("../assets/ic_right.png");
export const Avatar = require("../assets/avatar.jpg");
export const achivement = require("../assets/achivement.png");
export const profile = require("../assets/profile.webp");
export const emptyRewardImg = require("../assets/empty_reward.svg")
export const rewardCup = require("../assets/cup.svg")
export const rewardCrown = require("../assets/reward_crown.svg")
