import React from "react";
// Customizable Area Start
import ManageRewardController, {
    Props
} from "./ManageRewardController.web";
import { Box, Button, Avatar, Chip ,Typography} from "@material-ui/core";
import { styled } from '@material-ui/core/styles';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {manageImg,NotFoundImage} from "./assets";
import {UserRewardListing} from "./ManageRewardController.web"
// Customizable Area End

export default class ManageReward extends ManageRewardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderHeadingTitle = () => {
      return(
        <div style={{display: "flex",alignItems: "center"}}>
          <IconBack
            onClick={this.handleBackAchievement}
            />
          <ManageTitle className="manage Class">Manage [{this.state.rewardName}] <img src={manageImg} style={{width: "32px",height: "32px"}}/></ManageTitle>
        </div>
      )
    };

    renderSortIcons(column: keyof UserRewardListing['attributes']) {
        const { orderBy, orderDirection } = this.state;
    
        return (
          <span style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px', alignItems: "center", justifyContent: "center" }}>
            <IoIosArrowUp data-test-id="sortAscId" onClick={() => this.handleSortAsc(column)} color={orderBy === column && orderDirection === "asc" ? "#1C1B1B" : "#1C1B1B"} style={{fontSize: "14px", color: "#1C1B1B",fontWeight: 600}}/>
            <IoIosArrowDown
              data-test-id="sortdescId"
              onClick={() =>{
                 this.handleSortDesc(column)}} color={orderBy === column && orderDirection === "desc" ? "#1C1B1B" : "#1C1B1B"} style={{fontSize: "14px", color: "#1C1B1B", fontWeight: 600}}/>
    
          </span>
        );
      };

    renderNoData = () => {
        return (
            <>
                <MainNotFoundBox>
                    <MainInnerBox>
                        <Box>
                            <ImageComponent src={NotFoundImage} />
                        </Box>
                        <NotFoundText>No results founds</NotFoundText>
                    </MainInnerBox>
                </MainNotFoundBox>
            </>
        )
    };

    rernderTableTitle = () => {
        return (
            <Box display="grid" gridTemplateColumns="repeat(4,1fr)" padding="16px" marginTop="30px">
                <StyledTableHeading>
                    <span style={{ fontSize: "20px", fontWeight: 600, color: "#1E253C" }} data-test-id="nameId">Name</span>
                    {this.renderSortIcons("user_name")}
                </StyledTableHeading>
                <StyledTableHeading>
                    <span style={{ fontSize: "20px", fontWeight: 600, color: "#1E253C" }}>Branch name</span>
                    {this.renderSortIcons("branch_name")}
                </StyledTableHeading>
                <StyledTableHeading>
                    <span style={{ fontSize: "20px", fontWeight: 600, color: "#1E253C" }}>User progress</span>
                    {this.renderSortIcons("user_progress")}
                </StyledTableHeading>
                <StyledTableHeading className="endPosition">
                    <span style={{ fontSize: "20px", fontWeight: 600, color: "#1E253C" }}>Redemption status</span>
                    {this.renderSortIcons("user_progress")}
                </StyledTableHeading>
            </Box>
        )
    };

    renderMainTable = () => {
        return (
            <Box style={{ display: "grid", gap: "12px" }}>
                {this.state.rewardsData?.data?.map((user, index: number) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell style={{ placeSelf: "flex-start" }}>
                            {
                                user.attributes?.profile_image == null ?
                                    <Avatar src="/broken-image.jpg" />
                                    :
                                    <Avatar src={user.attributes?.profile_image?.url} style={{ width: "56px", height: "56px" }} />
                            }
                            <Box marginLeft="25px" style={{ fontSize: "16px", fontWeight: 400, color: "#1C1B1B" }}>{user.attributes.user_name}</Box>
                        </StyledTableCell>
                        <StyledTableCell>
                            {user.attributes.branch_name}
                        </StyledTableCell>
                        <StyledTableCell>
                            {user.attributes.user_progress === "earned" &&
                                <StyledChip
                                    label={user.attributes.user_progress}
                                    style={{ fontSize: "14px", fontWeight: 400, color: "#542070", textTransform: "capitalize", backgroundColor: "#E0D4E7", width: "110px", height: "40px", borderRadius: "88px", border: "1px solid transparent" }}
                                />
                            }
                            {(user.attributes.user_progress === "progress") &&
                                <StyledChip
                                    label={"In progress"}
                                    style={{
                                        fontSize: "14px", fontWeight: 400, color: "#E47004", textTransform: "capitalize", width: "110px", height: "40px", borderRadius: "88px", border: "1px solid transparent", background: "var(--sys-color-SecondaryContainer, #FFEDE4)"
                                    }}
                                />
                            }
                            {user.attributes.user_progress === "redeemed" &&
                                <StyledChip
                                    label={user.attributes.user_progress}
                                    style={{ fontSize: "14px", fontWeight: 400, color: "#005B1D", textTransform: "capitalize", width: "110px", height: "40px", borderRadius: "88px", border: "1px solid transparent", backgroundColor: "#D7FFE7" }}
                                />
                            }
                            {user.attributes.user_progress === "pending" &&
                                <StyledChip
                                    label={"Unstarted"}
                                    style={{
                                        fontSize: "14px", fontWeight: 400, color: "#BF0032"
                                        , textTransform: "capitalize", width: "110px", height: "40px", borderRadius: "88px", border: "1px solid transparent", backgroundColor: "#FFD9DF"
                                    }}
                                />
                            }
                        </StyledTableCell>
                        <StyledTableCell className="endPosition">
                            {user.attributes.user_progress === "redeemed" ? (
                                <RedeemedIcon><CheckCircleIcon /> Redeemed</RedeemedIcon>
                            ) : (
                                <RedeemBtn className={user.attributes.user_progress === "earned" ? "redeemBtnActive" : "redeemBtn"} variant="outlined" disabled={user.attributes.user_progress !== "earned"} data-test-id="redeemBtnId" onClick={() => this.putChangeStatusApiCall(user.id)}>
                                    Redeem
                                </RedeemBtn>
                            )}
                        </StyledTableCell>
                    </StyledTableRow>
                ))}
            </Box>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <>
                {this.renderHeadingTitle()}
                <Box style={{ minWidth: "750px" }}>
                    {
                        this.state.rewardsData?.data.length > 0 ?
                            <>
                                {this.rernderTableTitle()}
                                {this.renderMainTable()}
                            </>
                            :
                        this.renderNoData()
                    }
                </Box>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const ManageTitle = styled("span")({
    position: "absolute",
    top: "8px",
    zIndex: 9999,
    left: "175px",
    fontSize: "48px",
    fontWeight: 700,
    margin: "0px"
});

const StyledTableRow = styled(Box)({
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    borderRadius: "8px",
    overflow: "hidden",
    padding: "4px 16px",
    background: "#FFF",
    boxShadow: "0px -2px 6px 0px #0001, 0px 2px 6px 0px #0001",
    "& .redeemBtn":{
      border: "1px solid var(--sys-color-DisabledText, #E5E2E1)",
      color: "#929090"
    },
    "& .redeemBtnActive":{
      border: "1px solid var(--sys-color-Secondary, #E47004)",
      color: "#E47004"
    }
  });
  
  const StyledTableCell = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px",
    fontSize: "16px",
    fontWeight: 400,
    "&.endposition":{
        justifyContent: "flex-end"
    }
  });
  
  const StyledChip = styled(Chip)({
    width: "150px",
    borderRadius: "16px",
    background: "transparent",
  });
  
  const RedeemedIcon = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "4px",
    color: "green",
  });
  
  const StyledTableHeading = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&.endPosition":{
      justifyContent: "flex-end",
    }
  });
  
  const RedeemBtn = styled(Button)({
    textTransform: "none",
    borderRadius: "12px",
    minWidth: "186px",
    height: "42px",
    fontSize: "16px",
    fontWeight: 600
  });

  const DivModal = styled("div") ({
    backgroundColor: "white", 
    height: "150px", 
    borderRadius: "12px", 
    padding: "30px 30px", 
    position: 'relative', 
    left: -28,
    "@media(max-width: 395px)": {
        height: "186px",
        width: "360px" ,
        left: -20,
      }
  });


  const ButtonModal = styled(Button) ({
    width: "214px",
    height: "42px",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "12px",
    border : "1px solid #E47004",
    color: "#E47004",
    textTransform: "capitalize",
    "@media(max-width: 535px)": {
      width: "120px",
    },
    "@media(max-width: 398px)": {
      width: "110px",
    }
  });
  
  const ButtonDarkModal = styled(Button) ({
    width: "214px",
    height: "42px",
    fontSize: "16px",
    fontWeight: 600,
    borderRadius: "12px",
    color: "white",
    background: "#652786",
    textTransform: "capitalize",
    marginLeft: "20px",
    "&.MuiButton-contained:hover": {
     backgroundColor: "#652786 !important",
     color: "white !important"
    },
    "@media(max-width: 520px)": {
      width: "135px",
    }
  });

  const IconBack = styled(ArrowBackIosIcon) ({
    cursor: "pointer",
     color: "#92929D", 
     position: "absolute",
    zIndex: 1300,
    left: "135px",
    top: "33px",
    "@media(max-width: 600px)": {
      left: "35px",
    },
    "@media(max-width: 400px)": {
      left: "35px",
    }
  });

  const ImageComponent = styled("img")({
    width: "100%",
    height: "90%",
  });
  
  const MainNotFoundBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  });
  
  const MainInnerBox = styled(Box)({
    width: "727px",
    height: "540px",
    marginTop: "40px",
  });
  
  const NotFoundText = styled(Typography)({
    marginTop: "26px",
    textAlign: "center",
    color: "#1C1B1B !important",
    fontFamily: "Poppins Arial, Helvetica, sans-serif",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "-1px",
  });
// Customizable Area End