import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Modal, Typography, styled } from "@material-ui/core";
import React from "react";
import moment from "moment";

import Title from "../../../../packages/components/src/DynamicTitle";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { audio, coursePreview, crossIcon, docIcon, document, editIconPreview, home, lessonpic, video, editIconPurple} from "./assets";
import CoursePreviewController, { Props } from "./CoursePreviewController";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';


class CoursePreview extends CoursePreviewController {

  constructor(props: Props) {

    super(props);
  }


  statusOptionData = (dialogContent: any) => {
    if (dialogContent?.attributes?.status === "active") {
      return webStyle.statusNew
    } else if (dialogContent?.attributes?.status === "draft") {
      return webStyle.statusDraft
    } else {
      return webStyle.statusArchived
    }
  }


  RenderImges = (data: string) => {
    switch (data) {
      case 'video':
        return video;
      case 'home':
        return home;
      case 'document':
        return document;
      case 'audio':
        return audio;
      default:
        return null;
    }
  }

  showCategories = () => {
    return (
      <Typography style={{
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "Poppins",
        lineHeight: "22px",
        color: "#ADAAAA"
      }}>
        {(this.props.dialogContent?.attributes && this.props.dialogContent.attributes.categories.length > 1) ? `${this.props.dialogContent?.attributes.categories[0].name} & ${this.props.dialogContent.attributes && this.props.dialogContent?.attributes.categories.length - 1} more` : this.props.dialogContent?.attributes.categories[0].name}
      </Typography>
    )
  }

  renderAssociatedPrograms() {
    return (
      this.props.dialogContent?.attributes.associated_programs.map((data, index) => {
        return (
          <li 
          key={index}
          data-test-id={`program-included-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            padding: "6px 8px 6px 8px",
            lineHeight: "24px",
            color: "#484646",
            fontWeight: 400,
            fontSize: "16px",
            fontFamily: "Poppins",
            ...(this.state.hoveredIndex === index ? webStyle.programListItemHover : {})
          }}
          onMouseEnter={() => this.setState({hoveredIndex: index})}
          onMouseLeave={() => this.setState({hoveredIndex: null})}
          >
            <FiberManualRecordIcon style={{ height: "7px", width: "7px" }} />
            &nbsp; &nbsp;
            {data.title.length > 28 ? data.title.substring(0, 28) + "..." : data.title} <KeyboardArrowRightIcon style={{ height: "24px", width: "24px" }} />
            <Typography style={{fontSize: "16px", fontWeight: 400, lineHeight: "24px"}}>{data.phases.length > 20 ? data.phases.substring(0, 20) + "..." : data.phases}</Typography>
            <img
            data-test-id="program-included-edit-btn"
            onClick={() => this.props.onProgramPreview?.(String(data.id), "Program")} 
            style={{
              ...webStyle.programListEditIcon,
              ...(this.state.hoveredIndex === index ? webStyle.programListEditIconVisible : {}),
            }}
            src={editIconPurple} 
            />
          </li>
        )
      })
    )
  }

  render() {

    return (
      <>
        <DialogBox
          data-test-id="dialogPreview"
          open={this.props.isPreview}
        >

          <SecondDialogBox>
            <Box style={webStyle.crossIcon}
              data-test-id="testcase1"
              onClick={() => { this.props.handlePreviewClose(); this.setState({ onArrclick: [] }) }}>
              <img src={crossIcon} />
            </Box>
            <ModalBox>
              <Box style={{ padding: "10px" }}>
                {<Title
                  showBackButton title1="Course preview" onBackClick={this.props.handlePreviewClose} />}
              </Box>


              <Grid container style={{ display: "flex", flexWrap: "wrap", }} spacing={2} >
                <Grid item xs={12} md={7} lg={8}>
                  <Box style={{
                    display: "flex", flexDirection: "column", gap: "24px", maxWidth: "771px",
                    height: "fit-content"
                  }}>
                    <ImageBox >
                      <img style={{ width: "100%", height: "344px", borderRadius: "20px 20px 0px 0px" }}
                        src={this.props.dialogContent?.attributes?.thumbnail_image?.url ?
                          this.props.dialogContent.attributes?.thumbnail_image?.url : coursePreview} />
                    </ImageBox>

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                      <Box style={{ display: "flex", justifyContent: "space-between", flexDirection: "column", gap: "12px" }}>
                        <Typography style={{
                          fontWeight: 700,
                          fontSize: "24px",
                          fontFamily: "Poppins",
                          lineHeight: "36px",
                          color: "#1C1B1B"
                        }}>
                          {this.props.dialogContent?.attributes?.title} </Typography>

                        <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>

                          <Typography style={{
                            borderRight: "1px solid rgba(173, 170, 170, 1)",
                            color: "#787776",
                            paddingRight: "12px",
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            lineHeight: "22px",

                          }}>
                            {this.props.dialogContent?.attributes.created_by}
                          </Typography>

                          {this.showCategories()}

                        </Box>

                      </Box>

                      <Box>
                        <Statuses style={this.statusOptionData(this.props.dialogContent)}>{this.props.dialogContent?.attributes?.status}</Statuses>
                      </Box>
                    </Box>

                    <Box >
                      <Box style={{
                        display: "flex", justifyContent: "space-between", gap: "12px", alignItems: "center",
                        flexWrap: "wrap"
                      }}>
                        <Typography style={{
                          fontWeight: 600,
                          fontSize: "16px",
                          fontFamily: "Poppins",
                          lineHeight: "16px",
                          color: "#652786"
                        }}>
                          Last Modified:  &nbsp; <span style={{
                            color: "#484646",
                            fontWeight: 400,
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            lineHeight: "22px",
                          }}>
                            {moment.utc(this.props.dialogContent?.attributes.updated_at).format('MM/DD/YYYY')}</span> </Typography>
                        <Box style={{ display: "flex", alignItems: "center", gap: "12px", flexWrap: "wrap" }}>

                          <Button data-test-id="editCourseBtn" style={{
                            border: "1px solid #E47004", padding: "12px 24px 12px 16px", borderRadius: "16px", color: "#E47004",
                            textTransform: "capitalize", whiteSpace: "nowrap", gap: "16px"
                          }} onClick={() => this.props.gotoEditCourse(Number(this.props.dialogContent?.id))}
                          >
                            <img src={editIconPreview} />
                            &nbsp;  &nbsp;
                            <Typography
                              style={{
                                fontWeight: 600,
                                fontSize: "16px",
                                fontFamily: "Poppins",
                                lineHeight: "16px",
                                color: "#E47004"
                              }}
                            >Edit Course</Typography>
                          </Button>
                          <Button data-test-id="editContentBtn" style={{
                            border: "1px solid #E47004", padding: "12px 24px 12px 16px", borderRadius: "16px", color: "#E47004",
                            textTransform: "capitalize", whiteSpace: "nowrap", gap: "8px"
                          }}onClick={() => this.props.gotoEditContent(this.props.dialogContent?.attributes.content_release_schedule,Number(this.props.dialogContent?.id))}>
                            <img src={docIcon} />
                            &nbsp;  &nbsp;
                            <Typography
                              style={{
                                fontSize: "16px",
                                lineHeight: "16px",
                                fontWeight: 600,
                                fontFamily: "Poppins",
                                color: "#E47004"
                              }}>
                              Edit Content
                            </Typography>
                          </Button>
                        </Box>

                      </Box>
                    </Box>


                    <DescriptionBox >
                      <Typography style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        fontFamily: "Poppins",
                        lineHeight: "22px",
                        color: "#787776"
                      }}>
                        {this.props.dialogContent?.attributes?.description}
                      </Typography>
                    </DescriptionBox>

                    {this.props.dialogContent && this.props.dialogContent.attributes.associated_programs.

                      length > 0 &&
                      <>
                        <Box style={{
                           maxWidth: "771", gap: "8px"
                        }}>
                          <Typography
                            style={{
                              fontWeight: 600,
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              color: "#1C1B1B"
                            }}
                          >Programs that include this course</Typography>
                          <ul style={{ gap: "5px", padding: "0px" }}>

                            <Box style={{
                              gap: "5px",
                              display: "flex", flexDirection: "column", flexWrap: "wrap"
                            }}>
                              {this.renderAssociatedPrograms()}
                            </Box>
                          </ul>
                        </Box>
                      </>
                    }



                  </Box>
                </Grid>

                <Grid item xs={12} md={5} lg={4}>

                  <GridBox>

                    <ContentBox>
                      <div style={{ display: "flex", justifyContent: "space-between"}}>
                        <Typography
                          style={{
                            fontWeight: 600,
                            fontSize: "24px",
                            fontFamily: "Poppins",
                            lineHeight: "36px",
                            color: "#1C1B1B"
                          }}>Course Content</Typography>
                        <div data-test-id="toggleDropDown" onClick={() => this.setState({ toggleDropDown: !this.state.toggleDropDown })}>
                          <KeyboardArrowRightIcon style={this.state.toggleDropDown ? { rotate: "90deg" } : { rotate: "0deg" }} />
                        </div>
                      </div>

                      <Box style={{
                        display: "flex",
                        justifyContent: "space-between",padding:"15px 0" 
                      }}>

                        <Box style={{
                          display: "flex",
                          justifyContent: "space-between",
                          maxWidth: "304px",

                          gap: "8px",
                          alignItems: "center",
                          flexWrap: "wrap"

                        }}>

                          <div>
                            <img src={lessonpic} />
                          </div>

                          <div>
                            <Typography style={{
                              fontWeight: 700,
                              fontSize: "18px",
                              fontFamily: "Poppins",
                              lineHeight: "24px",
                              color: "#1A1A1A",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            > {this.props.dialogContent?.attributes.lessons_count} </Typography>
                            <Typography style={{
                              fontWeight: 400,
                              fontSize: "12px",
                              fontFamily: "Poppins",
                              lineHeight: "18px",
                              color: "#484646"
                            }}> Lessons </Typography>
                          </div>

                        </Box >
                        {this.props.dialogContent?.attributes.content_release_schedule &&
                          <Box style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            padding: "20px",
                            gap: "8px",
                            borderLeft: "1px solid #E5E2E1"
                          }}>
                            <Typography style={{
                              fontWeight: 500,
                              fontSize: "14px",
                              lineHeight: "16px",
                              fontFamily: "Poppins",
                            }}>
                              Phases are released</Typography>
                            <Button style={{
                              padding: "6px 9px 6px 9px", borderRadius: "88px", backgroundColor: "#E0D4E7",
                              textTransform: "capitalize", gap: "10px", color: "#542070", textAlign: "center",
                              cursor: "pointer"
                            }}>
                              {this.props.dialogContent?.attributes.content_release_schedule_value}
                            </Button>

                          </Box>
                        }

                      </Box>

                    </ContentBox>


                    {this.state.toggleDropDown && this.props.dialogContent?.attributes?.content_release_schedule &&
                    <Box style={{display:"flex",flexDirection:"column",justifyContent:"center",}}>
                      {this.props.dialogContent?.attributes?.contents?.map((item, index) => {
                        return (
                          <>
                            <WithOutPhase>

                              <Box style={{
                                display: "flex", alignItems: "center",
                                justifyContent: "space-between",
                                maxWidth: "304px"
                              }}>

                                <Accordion>
                                  <AccordionSummary
                                    onClick={() => this.rotateArrowIcon(String(index))}
                                    style={{ display: "flex", justifyContent: "space=between", alignItems: "center" }}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography
                                      style={{
                                        fontWeight: 700,
                                        fontSize: "16px",
                                        fontFamily: "Poppins",
                                        lineHeight: "22px",
                                        color: "#1C1B1B"
                                      }}>Phase &nbsp;{item.attributes.phase_number} &nbsp;
                                      <span>-&nbsp;{item.attributes.name}</span>
                                    </Typography>
                                    <KeyboardArrowRightIcon style={{ rotate: `${this.state.onArrclick.includes(String(index)) ? "90deg" : "0deg"}` }}

                                    />
                                  </AccordionSummary>
                                  {item.attributes.content.map((data, index) => {
                                    return (<AccordionDetails>
                                      <Box style={{
                                        height: "48px",
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "16px",


                                      }}>
                                        <div style={{
                                          height: "48px",
                                          width: "48px",
                                          border: "1px solid #E5E2E1",
                                          boxShadow: "0px 4px 8px 0px rgba(229, 229, 229, 0.25)",
                                          borderRadius: "4px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",

                                        }}>
                                          <Typography
                                            style={{

                                              fontWeight: 600,
                                              fontSize: "20px",
                                              lineHeight: "24px",
                                              color: "#652786",
                                              fontFamily: "Poppins"
                                            }}>
                                            {data.sequence_number}
                                          </Typography>
                                        </div>

                                        <div style={{ height: "43px", gap: "3px", display: "flex", flexDirection: "column" }}>
                                          {data.title.length > 12 ?
                                            (<TitleTypography>{data.title}</TitleTypography>)
                                            : (<TitleTypography style={{
                                              fontFamily: "Poppins",
                                              lineHeight: "22px",
                                              fontSize: "16px",
                                              color: "#1C1B1B",
                                              fontWeight: 400,
                                              wordBreak: "break-word"
                                            }}>{data.title}</TitleTypography>)}
                                          <Typography style={{
                                            lineHeight: "18px",
                                            color: "#1C1B1B",
                                            fontWeight: 400,
                                            fontSize: "12px",
                                            fontFamily: "Poppins"
                                          }}>
                                            {data.duration}</Typography>
                                        </div>
                                      </Box>
                                      <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        height: "32px", width: "32px"
                                      }} >
                                        <img style={{ height: "24px", width: "24px" }} src={this.RenderImges(data.type)} />
                                      </div>
                                    </AccordionDetails>)
                                  })}
                                </Accordion>
                              </Box>
                            </WithOutPhase>

                          </>)

                      })}</Box>}
                    {this.state.toggleDropDown && !this.props.dialogContent?.attributes?.content_release_schedule &&
                      this.props.dialogContent?.attributes?.contents?.map((item, index) => {
                        return (
                          <>

                            <WithOutPhase>
                              {item.attributes.content.map((data, index) => {
                                return (<Box style={{
                                  maxWidth: "304px", height: "72px", border: "1px solid  #E5E2E1", borderRadius: "8px", display: "flex",
                                  justifyContent: "space-between", padding: "12px", boxShadow: "1px 2px 10px 0px rgba(0, 0, 0, 0.07)",
                                  alignItems: "center", marginBottom: "10px", marginTop: "20px"
                                }}>
                                  <Box style={{
                                    alignItems: "center",
                                    gap: "16px",
                                    height: "48px",
                                    display: "flex",


                                  }}>
                                    <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      height: "48px",
                                      width: "48px",
                                      border: "1px solid #E5E2E1",
                                      borderRadius: "4px",
                                      alignItems: "center",
                                      boxShadow: "0px 4px 8px 0px rgba(229, 229, 229, 0.25)",

                                    }}>
                                      <Typography
                                        style={{
                                          lineHeight: "24px",
                                          fontSize: "20px",
                                          fontWeight: 600,
                                          color: "#652786",
                                          fontFamily: "Poppins"
                                        }}>
                                        {data.sequence_number}
                                      </Typography>
                                    </div>

                                    <div
                                      style={{
                                        height: "43px",
                                        width: "91px",
                                        gap: "3px"
                                      }}>
                                      {data.title.length > 12 ?
                                        (<TitleTypography>{data.title}</TitleTypography>)
                                        : (<TitleTypography style={{
                                          fontFamily: "Poppins",
                                          lineHeight: "22px",
                                          fontSize: "16px",
                                          color: "#1C1B1B",
                                          fontWeight: 400,
                                          wordBreak: "break-word"
                                        }}>{data.title}</TitleTypography>)}

                                      <Typography style={{
                                        lineHeight: "18px",
                                        color: "#1C1B1B",
                                        fontFamily: "Poppins",
                                        fontWeight: 400,
                                        fontSize: "12px",
                                      }}>
                                        {data.duration}</Typography>
                                    </div>
                                  </Box>
                                  <div style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "32px", width: "32px"
                                  }} >
                                    <img style={{ height: "24px", width: "24px" }} src={this.RenderImges(data.type)} />
                                  </div>
                                </Box>)
                              })}

                            </WithOutPhase>

                          </>)

                      })}

                  </GridBox>
                </Grid>
              </Grid>
            </ModalBox>
          </SecondDialogBox>
        </DialogBox>
      </>
    )
  }

};



export default CoursePreview;

const webStyle = {
  crossIcon: {
    position: 'absolute' as 'absolute',
    right: "1px",
    width: '32px',
    height: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 10
  },


  rotateArrowIcon: {
    transform: "rotate(90deg)"
  },

  statusNew: {
    display: 'flex',
    color: "#542070",
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#E0D4E7",
    textTransform: "capitalize" as 'capitalize'
  },

  statusDraft: {
    display: 'flex',
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFEDE4",
    color: "#E47004",
    textTransform: "capitalize" as 'capitalize'
  },

  statusArchived: {
    display: 'flex',
    justifyContent: "center",
    width: "96px",
    height: "40px",
    padding: "6px 9px",
    alignItems: "center",
    gap: "10px",
    borderRadius: "88px",
    background: "#FFD9DF",
    color: "#E70E6B",
    textTransform: "capitalize" as 'capitalize'
  },

  programListItemHover: {
    backgroundColor: "#EEE8F2",
    cursor: "pointer",
    borderLeft: "1.5px solid #6C328B",
    color: "#6C328B"
  },
  programListEditIcon: {
    display: "none",
    height: "24px",
    width: "24px",
    marginLeft: "auto",
  },
  programListEditIconVisible: {
    display: "block", 
  },
}

const ImageBox = styled(Box)({
  maxWidth: "771px",
  radius: "88px",
  height: "344px"
});

const SecondDialogBox = styled(Box)({
  position: "relative",
  "@media (min-width:720px)": {
    maxWidth: "1211px",
    width:"85%",
    marginLeft: "clamp(15px, 135px, 10%)",
},
})

const ContentBox = styled(Box)({
   display: "flex", flexDirection: "column", maxWidth: "304px", gap: "24px" ,
   "@media (max-width:960px)": {
    maxWidth: "360px",
  },
   "@media (min-width:720px)": {
    maxWidth: "1211px",
},
})
const GridBox = styled(Box)({
    display: "flex",
    maxWidth: "352px", height: "fit-content", borderRadius: "16px", flexDirection: "column",
     boxShadow: "1px 2px 10px 2px rgba(0, 0, 0, 0.07)",
    gap: "24px",
    padding:"32px 24px"
})

const Statuses = styled("div")({
  textAlign: "center",
  padding: "6px 9px",
  fontSize: "14px",
  borderRadius: "88px",
  gap: "10px",
  textTransform: "capitalize",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
  width: "125px",
  height: "40px",


});

const DescriptionBox = styled(Box)({
  height: "fit-content",

  "@media (max-width:960px)": {
    height: "110px", overflowY: "scroll"
  },
  "@media (min-width:720px)": {

  },
});


const WithOutPhase = styled(Box)({
  maxWidth: "304px",
  gap: "24px",
})


const ModalBox = styled(Box)({
  margin: "1rem",
  backgroundColor: "white",
  padding: "0px 32px 32px 32px",
  overflowY: "scroll",
  borderRadius: "16px",
  height: "97vh",

})



const TitleTypography = styled(Typography)({
  fontFamily: "Poppins",
  lineHeight: "22px",
  fontSize: "16px",
  color: "#1C1B1B",
  fontWeight: 400,
  wordBreak: "break-word",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  width:"160px",

  "@media (max-width:960px)": {
  width:"100px",
    
  },
  "@media (min-width:720px)": {

  },
})


const DialogBox = styled(Modal)({
  display: 'flex',
  justifyContent: "center",

  "& .MuiDialog-paperWidthSm": {
    maxWidth: "700px !important",

    "@media (max-width:960px)": {
      minWidth: "100px !important",
      height: "152px !important",
      padding: "16px",
    },
    "@media (min-width:720px)": {

    },
  },

})

