import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sortTableData } from "../../utilities/src/TableHelpers";
import { ChangeEvent, LegacyRef, createRef } from "react";
import ReactPlayer from "react-player";
import { ContentData } from "../../../blocks/videolibrary/src/VideoLibraryController";
import * as yup from "yup";
import debounce from "lodash.debounce";
import { getStorageData, removeStorageData } from "framework/src/Utilities";

export interface CheckboxSelectedListType { value: string, id: string }

export interface InvalidResponseType {
  errors: {
    message: string;
  }[];
}

export const  validationSchema = yup.object().shape({
  thumbnail: yup
    .string()
    .required("Title is required"),
})

  export interface ThumbnailType {
    data: ContentData[];
  }
  
  
  
interface DeleteRes {
    message:string
  
}
export interface CheckboxSelectedListTypes { value: string, id: string }
export interface ExpertListings {
  id: string,
  type: string,
  attributes: {
    first_name: string,
    last_name: string,
    email: string,
    full_name:string,
    profession: string,
    avatar: null,
    is_admin: boolean
  }
}
interface DeleteBulkRes {
  message1:string|[]
  message2:string|[]

}
interface UploadInputData {
  thumbnail:string
}
interface FileuploadRes {
  data:{
  id:string,
  type:string,
  attributes:{
    title:string,
    created_at:string,
    image:{
       id:number,
       url:string,
       filename:string
   },
   created_by:string
  } 
}

}
export interface CalendarValues {
  0: string | number | Date;
  1: string | number | Date;
}
  interface NumberArray {
    map(arg0: (id: number) => string): unknown;
    length: number;
    [index: number]: number;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history?: any;
  location?: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  bulkDelete: NumberArray;
  isContentModal: boolean;
  showFullDescription: boolean;
  fullScreenPdf: boolean;
  latestId: number;
  deleteModalOpen: boolean;
  deleteAllOpen: boolean;
  ThumbnailData: Array<ContentData>|null;
  anchorsFilterContentsEl: null | HTMLElement;
  openContentNavigate: boolean;
  tagsSearchText: string;
  fileSelectedItems: Array<{value:string, id:string}>;
  tagParams: string;
  creationDateParams: {
    start_date: string;
    end_date: string;
  };
  creationNameParams: string,
  fileTypeParams: string;
  searchContentValue: string;
  showContentList: boolean;
  showCreatePage:boolean,
  uploadFileProgress:number,
  uploadFileStatus:string,
  isAttachmentChange:boolean,
  attachedFile:File | { id: string; url: string; filename: string; } |null,
  deletealert:{ isOpen: boolean, msg?: string, type?: string };
  showMessage:boolean,
  FileType:string,
  thumbnailName:string,
  SearchBarValue:string;
filteredDataLists: Array<string>,
showContentData:boolean;
 filterItemsDatas:boolean;
placeholdersParams: string;
placeholderSelectsItemed:Array<CheckboxSelectedListTypes>
creationDateThumbnail:string;
creationDateParamsdatas: {
  start_date: string;
  end_date: string;
};
isCalendarshow:boolean;
placeholderSearchData:string;
anchorsEl: null | HTMLElement;
dropdownType: string;
expertAdminsData: Array<ExpertListings>;
searchList:boolean;
RemoveDeleteButton:boolean;
originFormdata: Object | null
editId: string;
getThumbnailDetails: ContentData
saveThumbnailPrompt: boolean;
displayThumbnail: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ThumbnailmanagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getAllThumbnailCallID:string=""
  deleteContentApiCallId: string = "";
  deleteBulkContentApiCallId: string = "";
  contentDetailApiCallId: string = "";
  UploadFileApiCallId: string="";
  GetAdminExpertCallIds:string="";
  getAdvanceSearchCallIds:string="";
  getThumbnailDetailCallID: string = "";
  
  myRef: LegacyRef<ReactPlayer> | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.myRef = createRef();
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      openContentNavigate: false,
      anchorsFilterContentsEl: null,
      bulkDelete: [],
      ThumbnailData: [],
      isContentModal: false,
      showFullDescription: false,
      fullScreenPdf: false,
      latestId: 0,
      deleteModalOpen: false,
      deleteAllOpen: false,
      tagsSearchText: "",
      fileSelectedItems: [],
      tagParams: "",
      creationDateParams: {
        start_date: "",
        end_date: ""
      },
      creationNameParams: "",
      fileTypeParams: "",
      searchContentValue: "",
      showContentList: false,
      showCreatePage:false,
      uploadFileProgress:0,
      uploadFileStatus: "notStarted",
      isAttachmentChange:false,
      attachedFile:null,
      deletealert:{ isOpen: false, msg: " ", type: "success" },
      showMessage:false,
      FileType:"",
      thumbnailName:"",
      SearchBarValue:"",
      filteredDataLists:[],
      showContentData:false,
       filterItemsDatas:false,
      placeholderSelectsItemed:[],
      placeholdersParams:"",
      creationDateThumbnail:"",
      creationDateParamsdatas: {
        start_date: "",
        end_date: ""
      },
      isCalendarshow:false,
      placeholderSearchData:"",
      anchorsEl: null,
      dropdownType: "",
      expertAdminsData:[],
      searchList:false,
      RemoveDeleteButton:false,
      originFormdata: null,
      editId: this.props.navigation.getParam("id"),
      getThumbnailDetails: {} as ContentData,
      saveThumbnailPrompt: false,
      displayThumbnail: false
      // Customizable Area End 
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if(message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showSnackbar){
      const value=message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.showSnackbar
      const isEdit=message.getData(getName(MessageEnum.NavigationPayLoadMessage))?.isEdit;
      const toastMessage = isEdit ? `Changes on ${value} saved`  : `${value} has been uploaded`
      this.setState((prev) => ({
        ...prev,
        deletealert: {
          isOpen: true,
          msg: toastMessage
        },showCreatePage:false,attachedFile:null
        
      }));
      this.handleAlertSuccessClose()
    }
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "From: " + this.state.txtSavedValue + " To: " + value,
        "Change Value"
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

if (apiRequestCallId === this.getAllThumbnailCallID) {
  this.getThumbailData(responseJson)
 
  this.parseApiCatchErrorResponse(errorResponse);
}
if(apiRequestCallId ===this.deleteContentApiCallId){
 
this.getThumbnailDelete(responseJson)
  
}
if(apiRequestCallId ===this.deleteBulkContentApiCallId){
    this.getBulkthumbnailDelete(responseJson) 
}
if(apiRequestCallId === this.UploadFileApiCallId){
  this.fileUploadSuccess(responseJson)
}else if(apiRequestCallId===this.getAdvanceSearchCallIds){
  this.setState({filteredDataLists:responseJson.data})
}else if (this.GetAdminExpertCallIds === apiRequestCallId) {  
  this.setState({
    expertAdminsData: responseJson.data
  })
}
this.getThumbanilDetailsById(apiRequestCallId, responseJson)

    // Customizable Area End
  }

  // Customizable Area Start  
  async componentDidMount() {

    super.componentDidMount();
    
    if(this.state.editId) {
      this.getThumbnailDetailByID(Number(this.state.editId));
    }
    this.getAllThumbnail();
    this.getAdminExpertListings();
    this.getOriginFormData();
  }

  isInValidResponse = (responseJson: InvalidResponseType) => {
    return responseJson.errors;
  };

  getThumbailData=(responseJson:ThumbnailType)=>{
    this.setState({ThumbnailData:responseJson.data})
  }

  getSelectedIDChecbox = (list:Array<number>) => {
    this.setState({
      bulkDelete: list
    })
  }


getAllThumbnail=async()=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };
  const requestMessages = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getAllThumbnailCallID = requestMessages.messageId
  requestMessages.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getAllThumbailEndPoint}?${this.ConstructEndPoints()}`
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessages.id, requestMessages);
  return requestMessages.messageId;
}
sortUsers = (dataType: string, keyType: string, sortType: string) => {
  const sortedUsers = sortTableData(
    this.state.ThumbnailData,
    dataType,
    keyType,
    sortType,
  );
  this.setState({ ThumbnailData: sortedUsers });
};

handleOpen = () => {
  const message = new Message(getName(MessageEnum.NavigationMessage));
  message.addData(getName(MessageEnum.NavigationTargetMessage), "Addthumbnail");
  message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(message);
}

deleteItemSingle = (idType: number) => {
 this.setState({
  latestId:idType,
  deleteModalOpen: true
 })  
  } 

handleDeleteContent = (latestId: number) => {
  this.deleteContentValue(latestId);

}

deleteContentValue(latestID: number) {
    this.DeleteSingleContent({
      contentTypes: configJSON.validationApiContentType,
      methods: configJSON.DeleteApiMethod,
      endPoint: `${configJSON.getAllThumbailEndPoint}/${latestID}`
    });
}
DeleteSingleContent(data: {
  contentTypes?: string;
  endPoint?: string;
  methods?: string;
  body?: {};
  type?: string;
}) {
  const { contentTypes, methods, endPoint } = data;
  const header = {
    "Content-Type": contentTypes,
    token: localStorage.getItem("token"),
  };
  const requestMessages = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.deleteContentApiCallId = requestMessages.messageId
  requestMessages.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    methods
  );
  runEngine.sendMessage(requestMessages.id, requestMessages);
  return requestMessages.messageId;
}

cancelContentAllDeleteDialog = () => {
  this.setState({
    deleteAllOpen: false
  })
}
handleInputSearch = (event:any) => {
  this.setState({SearchBarValue:event.target.value},() => {
    this.getAllThumbnail()
  })
  this.setState({searchList:false,showContentData:event.target.value !== ""},()=>{this.getAdvancedSearchLists()}) 
 }
getAdvancedSearchLists=()=>{
  const header = {
    token: localStorage.getItem("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getAdvanceSearchCallIds = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.AdvancedSearchEndPoint}?type=thumbnail&search=${this.state.SearchBarValue}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;

}
handleAlertSuccessClose=()=>{
  setTimeout(() => {
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: false,
      },
    }));
  }, 3000);
}
handleDialogAlert=(response:DeleteRes)=>{
  if(response.message==="Some thumbnails could not be deleted since they are in use."){
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: true,
        msg: "Some thumbnails could not be deleted since they are being used.",
        type: "success"
      },
      deleteModalOpen:false,
      displayThumbnail: false
    }));
  }else if(response.message==="Thumbnail images deleted successfully."){
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: true,
        msg: "Deletion successful",
        type: "success"
      },
      deleteModalOpen:false,
      displayThumbnail: false
    }));
    this.getAllThumbnail();
  }
this.handleAlertSuccessClose()
 
}
getThumbnailDelete=(responseJson:DeleteRes)=>{
 this.handleDialogAlert(responseJson)
}

handleDeleteBulk = () => {
  this.setState({
    deleteAllOpen: true,
  })
}
updateChildState = (newState:any) => {
  this.setState({ RemoveDeleteButton: newState });
};
getBulkthumbnailDelete=(responseJson:DeleteBulkRes)=>{
  if(responseJson.message1.length && responseJson.message2.length){
    this.setState({showMessage:true})
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: true,
        msg: "",
        type: "success"
      },
      deleteAllOpen:false,
      RemoveDeleteButton:false
    }));
    this.getAllThumbnail();
  }

 if(responseJson.message1 && responseJson.message2.length===0){
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: true,
        msg: "Deletion successful",
        type: "success"
      },
      deleteAllOpen:false,
      RemoveDeleteButton:false
    }));
    this.getAllThumbnail();
  }else if(responseJson.message2 && responseJson.message1.length===0){
    this.setState((prev) => ({
      ...prev,
      deletealert: {
        isOpen: true,
        msg: "Some thumbnails could not be deleted since they are being used.",
        type: "success"
      },
      deleteAllOpen:false,
      RemoveDeleteButton:false
    }));
    this.getAllThumbnail();
  }
this.handleAlertSuccessClose()
}

handleDeleteAllContent = () => {
this.deleteBulkContentItem() 

}

SelectedFileDeleted=()=>{
  this.setState({ isAttachmentChange: true, attachedFile: null})
}

deleteBulkContentItem() {
  const bulkDeleteIds = (this.state.bulkDelete as number[])?.map(contentId => `thumbnail_ids[]=${contentId}`).join('&');
  const endPoint = `${configJSON.getAllThumbailEndPoint}/bulk_delete?${bulkDeleteIds}`;
  this.doDeleteBulkContentItem({
      contentTypes: configJSON.validationApiContentType,
      methods: configJSON.validationApiMethodType,
      endPoint: endPoint
  });
}

doDeleteBulkContentItem(data: {
contentTypes?: string;
methods?: string;
endPoint?: string;
body?: {};
type?: string;
}) {
  const { contentTypes, methods, endPoint } = data;
const header = {
  "Content-Type": contentTypes,
  token: localStorage.getItem("token"),
};
const requestMessages = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);
requestMessages.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

this.deleteBulkContentApiCallId = requestMessages.messageId
requestMessages.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  endPoint
);
requestMessages.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
  methods
);
runEngine.sendMessage(requestMessages.id, requestMessages);
return requestMessages.messageId;
}
onSelectFile = (file: File) => {
  this.setState({ isAttachmentChange: true,FileType:"image", attachedFile: file, uploadFileProgress: 0, uploadFileStatus: 'uploaded', })
}
handleUploadClick=(values:UploadInputData)=>{
  this.setState({thumbnailName:values.thumbnail.trimStart()})
  
  if(this.state.editId) {
  this.setState({saveThumbnailPrompt:false})
  }

  const formData = new FormData();
  if(this.state.attachedFile && !(this.state.attachedFile as { id: string; url: string; filename: string; } )?.url){
    formData.append('image', this.state.attachedFile as File);
  }
  formData.append('title', values.thumbnail);
   

  const header = {
    token: localStorage.getItem("token"),
  };
  const requestMessages = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formData
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  
  this.UploadFileApiCallId = requestMessages.messageId
  const apiURL = `${configJSON.getAllThumbailEndPoint}${this.state.editId ? `/${this.state.editId}` : ""}`
  requestMessages.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    apiURL
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    this.state.editId ? "PUT" : configJSON.exampleAPiMethod
  );
  runEngine.sendMessage(requestMessages.id, requestMessages);
  return requestMessages.messageId;
}
// Function that will be triggered once the file upload is successful
fileUploadSuccess= async (responseJSON:FileuploadRes)=>{
// Check if the thumbnail data exists in the response
if(responseJSON.data){
  const originScreen = await getStorageData("originScreen");
  // Check if "originScreen" exists and navigate back with the thumbnail data
  if (originScreen) {
    this.props.history.push({
      pathname: originScreen,
      state: {
        thumbnail: responseJSON.data,
        formData: this.state.originFormdata
      }
    });
    removeStorageData("originScreen");
  // If "originScreen" doesn't exist, navigate to "Thumbnailmanagement" and show a snackbar
  } else {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "Thumbnailmanagement");
    message.addData(getName(MessageEnum.NavigationPayLoadMessage), {showSnackbar:this.state.thumbnailName, isEdit: this.state.editId ? true : false});
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
}
}

ConstructEndPoints = () => {
  let params = [];

  if (this.state.SearchBarValue && this.state.searchList) {
    params.push(this.searchValueParams());
  }
  if (this.state.creationDateParamsdatas) {
    params.push(this.addCreationDateRangeParams());
  }
  if (this.state.creationDateThumbnail) {
    params.push(this.addCreationDateParams());
  }
  if(this.state.placeholdersParams){
    params.push(this.addplacesholdersParams())
  }
  params = params.filter(param => param !== '');
  return params.length > 0 ? params.join('&') : '';
};
private searchValueParams(): string{
  return `search=${this.state.SearchBarValue ? encodeURIComponent(this.state.SearchBarValue):""}`;
}
private addCreationDateParams(): string {
  const dateParam = this.getDateParam();
  return dateParam ? `q[created_at]=${dateParam}` : ''
}

private addCreationDateRangeParams(): string {
  const { start_date, end_date } = this.state.creationDateParamsdatas;
  if (start_date && end_date) {
    const rangeObj = {
      start_date: start_date,
      end_date: end_date
    };
    const queryString = encodeURIComponent(JSON.stringify(rangeObj));
    return `q[custom_range]=${queryString}`;
  }
  return '';
}
private addplacesholdersParams(): string {
  return this.state.placeholdersParams ? `&${this.state.placeholdersParams}` : '';
}
private getDateParam(): string {
  switch (this.state.creationDateThumbnail) {
    case "Last Week":
      return "last_week";
    case "Last Month":
      return "last_month";
    case "Last Year":
      return "last_year";
    default:
      return '';
  }
}
handleFilterClick = () => {
  this.setState({
    anchorsFilterContentsEl: null
  }, () => {
    this.getAllThumbnail()
  })

}
handleThumbnailListClick=(item:string)=>{
  this.setState({
    searchList:true,
    SearchBarValue: item,
    showContentData: false
  },()=>{
     this.getAllThumbnail()
  })
 }
placeholderDebounced: () => void = debounce(
  () => this.getAdminExpertListings(),
  700
)
handleFilterDropClick = (event: React.MouseEvent<HTMLDivElement>) => {
  this.setState({
     filterItemsDatas: !this.state. filterItemsDatas,
    anchorsFilterContentsEl: event.currentTarget,
  })
};
dropdownHandlerOpens = (event: React.MouseEvent<HTMLDivElement>, dropdownType: string) => {
  this.setState({
    anchorsEl: event.currentTarget,
    dropdownType
  })
}
handlesFilterClear=()=>{
  this.setState({
    anchorsFilterContentsEl: null,
  placeholdersParams: "",
  placeholderSelectsItemed:[],
  creationDateThumbnail:"",
  creationDateParamsdatas: {
    start_date: "",
    end_date: ""
  },
  isCalendarshow:false,
  placeholderSearchData:"",
  anchorsEl: null ,
  dropdownType: "",
    }, () => {
      this.getAllThumbnail()
    })
}
handleOpenCalendors = () => {
  this.setState({
    isCalendarshow: true
  })
}
handleChangeCalender = (value: CalendarValues) => {
  const startDate = new Date(value[0]);
  const endDate = new Date(value[1]);
  const formattedStartDate = startDate.getDate();
  const formattedsStartDateNum = startDate.toLocaleDateString("en-US", { day: "2-digit" });
  const formattedStartMonthNum = startDate.toLocaleDateString("en-US", { month: "2-digit" });
  const formattedStartYear = startDate.getFullYear();
  const formattedsEndDate = endDate.getDate();
  const formattedsEndDateNum = endDate.toLocaleDateString("en-US", { day: "2-digit" });
  const formattedEndMonth = endDate.toLocaleDateString("en-US", { month: "long" });
  const formattedEndYear = endDate.getFullYear();
  const formattedEndMonthsNum = endDate.toLocaleDateString("en-US", { month: "2-digit" });
  const dateRange = `${formattedStartDate}-${formattedsEndDate} ${formattedEndMonth} ${formattedEndYear}`;
  const start_date = `${formattedStartYear}-${formattedStartMonthNum}-${formattedsStartDateNum}`;
  const end_date = `${formattedEndYear}-${formattedEndMonthsNum}-${formattedsEndDateNum}`;
  this.setState({
    creationDateThumbnail: dateRange,
    creationDateParamsdatas: {
      start_date,
      end_date
    }
  });
}

handlePlaceholderAssignedSearchChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    placeholderSearchData: event.target.value
  }, () => {
    this.placeholderDebounced();
  })
}
dropdownHandlerClose = () => {
  this.setState({
    anchorsEl: null,
    isCalendarshow: false,
    dropdownType: "",
    // categoryId: [],
  })
}
creationChangeHandlerDates = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({
    creationDateThumbnail: event.target.value
  })
}
filterContainerDropdownCloseHandlers = () => {
  this.setState({
    anchorsFilterContentsEl: null
  })
}

placeholderCheckboxAssignedExpertChangeHandle = (event: React.ChangeEvent<HTMLInputElement>, itemId: string) => {
  const { checked, name } = event.target;
  if (checked) {
    this.setState(prevState => ({
      placeholderSelectsItemed: [...prevState.placeholderSelectsItemed, { value: name, id: itemId }]
    }), () => {
      const params = this.state.placeholderSelectsItemed.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
      this.setState({
        placeholdersParams: params
      });
    });
  } else {
    this.setState(prevState => ({
      placeholderSelectsItemed: prevState.placeholderSelectsItemed.filter((item: { value: string, id: string }) => item.id !== itemId)
    }), () => {
      const params = this.state.placeholderSelectsItemed.map((item: { value: string, id: string }) => `q[expert_ids][]=${item.id}`).join("&");
      this.setState({
        placeholdersParams: params
      });
    });
  }
}
getAdminExpertListings=()=>{
  const header = {
    token: localStorage.getItem("token"),
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)

  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.GetAdminExpertCallIds = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.expertAdminListApiEndPoint}?search=${this.state.placeholderSearchData}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}
cancelContentDeleteDialog = () => {
this.setState({
  deleteModalOpen: false
})
}
// Get the origin page's form data
getOriginFormData = () => {
  if (this.props.location.state) {
    this.setState({originFormdata: this.props.location.state?.formData})
  }
}

getThumbnailDetailByID=async(thumbanilId: number)=>{
  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: localStorage.getItem("token"),
  };
  const requestMessages = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  this.getThumbnailDetailCallID = requestMessages.messageId
  requestMessages.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getAllThumbailEndPoint}/${thumbanilId}`
  );
  requestMessages.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
  runEngine.sendMessage(requestMessages.id, requestMessages);
  return requestMessages.messageId;
}

getThumbanilDetailsById = (apiRequestCallId: string , responseJson: {data: ContentData}) => {
  if(apiRequestCallId===this.getThumbnailDetailCallID) {
    this.setState({
      getThumbnailDetails: responseJson.data,
      attachedFile: { ...responseJson.data.attributes.image, name: responseJson.data.attributes.image?.filename, type: "image/png" }as { id: string; url: string; filename: string; },
      uploadFileProgress: 0, 
      uploadFileStatus: 'uploaded',
      FileType:"image/png"
    })
  }
}


openThumbnailPrompt = () => {
  this.setState({
    saveThumbnailPrompt: true
  })
}

handleCancelThumbnail = () => {
  this.setState({
    saveThumbnailPrompt: false
  })
}

previewThumbnailContaintById= (thumbnailId: number) => {
  this.setState({displayThumbnail: true, getThumbnailDetails: {} as ContentData})
  this.getThumbnailDetailByID(thumbnailId)
}

closeThumbnailDialogue= () => {
  this.setState({displayThumbnail: false})
}

navigateToEditThumbnail = (editId: number) => {
  this.props.navigation.navigate("Editthumbnail", {id: editId})
}
  // Customizable Area End
}
