import React, { Component } from "react";
// Customizable Area Start

import {
    Box,
    Button,
    Paper, Typography
} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Backdrop from '@material-ui/core/Backdrop';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createTheme, makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rating from '@material-ui/lab/Rating';
import './videostyles.css';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import {iconImage, iconPage, iconQuiz, iconVideo, iconMusic, frame, viewShape, smallCheckIcon, startIcon, cartIcon, lockIcon,startRating,emptyStart} from "./assets";
import './CoursePage.css';
const group = require("../assets/Group 8.png")
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Note from '../../../components/src/Notes/Notes';
import AddNote from "../../../components/src/Notes/AddNote"

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: "10px",
        border: '1px solid #E5E2E1',
        borderRadius: '8px'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});


import CoursePageController, { configJSON } from "./CoursePageController.web";
import { styled } from "@material-ui/styles";

// Customizable Area End
let id: any;
export default class CoursePage extends CoursePageController {
    // Customizable Area Start
    handleAddToCartBtnRender = () => {
        return (
            <Box style={{display:"flex",flexDirection:"column",justifyContent:"flex-end",alignItems:"end"}}>
                <AddToCartBox>
            <Typography style={{fontWeight:700,fontSize:"24px",fontFamily:"Poppins"}}>${this.state.allCourseLibraryData.data && this.state.allCourseLibraryData.data.attributes.price}/<span style={{fontWeight:400,fontSize:"16px",fontFamily:"Poppins"}}>month</span></Typography>
                        <Button onClick={this.addToCart}
startIcon={<img src={cartIcon} style={{width:"24px",height:"24px"}}/>}
                            style={{textTransform:"none",width:"220px",height:"48px",background: '#1C1B1B', color: '#fff',borderRadius: '88px',padding: '8px 25px',cursor: 'pointer',marginTop:"10px",fontWeight:600,fontSize:"16px",fontFamily:"Poppins"}}>Add to cart</Button>
                        </AddToCartBox> 
            </Box>
        )   
    }

    handleStartBtnRender = () => {
        return (
            <Button
            startIcon={<img src={startIcon} style={{width:"24px",height:"24px"}}/>}
            id="start-course-id-tst"
            data-test-id="startBtn"
            style={styles.start_button}
            onClick={() => this.playeTheLesson("start")}
        >
            Start
        </Button>
        )
    }

    handleContinueBtnRender = () => {
        return (<Button data-test-id="continueBtn"
            startIcon={<img src={startIcon} style={{width:"24px",height:"24px"}}/>}
                            style={{textTransform:"none",width:"220px",height:"48px",background: '#E47004', color: '#fff',borderRadius: '88px',padding: '8px 25px',cursor: 'pointer',marginTop:"10px",fontWeight:600,fontSize:"16px",fontFamily:"Poppins"}} onClick={()=>this.playeTheLesson("continue")}>Continue</Button>
        )
    }

    handleCompletedBtnRender = () => {
        return (
                        <Button startIcon={<img src={smallCheckIcon} style={{width:"24px",height:"24px"}}/>}
                            style={{textTransform:"none",width:"220px",height:"48px",background: '#652786', color: '#fff',borderRadius: '88px',padding: '8px 25px',cursor: 'pointer',marginTop:"10px",fontWeight:600,fontSize:"16px",fontFamily:"Poppins"}}>Completed</Button>
        )
    }

    handleBtnRender = () =>{
        if(this.state.allCourseLibraryData?.data?.attributes.is_enrolled){
            switch(this.state.allCourseLibraryData?.data?.attributes.status){
                case "pending":
                 return this.handleStartBtnRender();
                 case "progress":
                     return this.handleContinueBtnRender();
                     case "completed":
                 return this.handleCompletedBtnRender();
                 default:
                     return <></>;
             }
        }else{
                return this.handleAddToCartBtnRender();
        }
       
    } 
    // Customizable Area End

    render() {
        id = this.state.allCourseLibraryData?.data;
        let videoTime = parseFloat(this.state.allCourseLibraryData?.data?.attributes?.total_video_length).toFixed(0);
        const totalSeconds: any = videoTime;
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        function padTo2Digits(num: any) {
            return num.toString().padStart(2, '0');      
        }
        const result = `${padTo2Digits(hours)}h ${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
        const { description, learning_details } = this.state.allCourseLibraryData?.data?.attributes || {};
        const isDescriptionLong = description && description.length > 300;
        const { isShowBtn } = this.state;

        return (
            // Customizable Area Start
            <>
                        <Grid container style={styles.profileHeaderGrid}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box style={styles.headigBox}>
                    <Typography style={styles.profileHeader} variant="h3" data-test-id ="headerDataId"> 
                    <img data-test-id="backButtonId" src={viewShape} style={{padding:"0px 10px 0px 10px", width:"32px", height: "22px"}} onClick={this.backBtn}/>
                                {this.state.allCourseLibraryData?.data?.attributes?.title?.charAt(0).toUpperCase() +
                                    this.state.allCourseLibraryData?.data?.attributes?.title?.slice(1)}
                    </Typography>    
                    <Box style={styles.statusBox}>  
                        <FiberManualRecordIcon 
                        style={{
                            color:`${this.state.allCourseLibraryData?.data?.attributes?.course_color?.course_status_dot_color}`,
                            width:'14px',
                            paddingRight:'3px'
                        }}/>
                        <Typography   
                        style={{
                            color:`${this.state.allCourseLibraryData?.data?.attributes?.course_color?.course_status_color}`,
                            fontSize:'14px'
                        }}>   
                        {this.state.allCourseLibraryData?.data?.attributes?.course_status}
                        </Typography>     
                    </Box>
                    </Box>
                    <Backdrop style={styles.backdrop} open={this.state.open} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Grid container spacing={2} style={styles.mainGridBox} key={this.state.allCourseLibraryData?.data?.id}>
                        <Grid item lg={8} md={8} sm={12} xs={12}
                        style={{
                            width:"844px"
                        }}
                        >
                            <Box
                                style={styles.mainImg_box}>
                                <img src={this.state.allCourseLibraryData?.data?.attributes?.thumbnail_image?.url} style={styles.main_courseImg} alt="img" />
                            </Box> 
                            <Box style={styles.main_titleBox}>  
                                <p  
                                    style={styles.main_title}> 
                                   </p>            
                            </Box>  
                            <Grid container spacing={2} style={styles.mainGridButton}> 
                                <Grid item lg={2} md={2} sm={4} xs={6}>
                                    <Typography style={styles.create_typo}>Created By</Typography> 
                                    <Box style={styles.boxCreate}>{this.state.allCourseLibraryData?.data?.attributes?.created_by}</Box>
                                </Grid>
                                <Grid item lg={2} md={2} sm={4} xs={6}>
                                    <Typography style={styles.cateTypo}>Category</Typography>
                                    <Box style={styles.box_cate}>
                                    {(this.state.allCourseLibraryData?.data && this.state.allCourseLibraryData?.data.attributes.categories.length > 1) ? `${this.state.allCourseLibraryData?.data?.attributes.categories[0]} & ${this.state.allCourseLibraryData?.data.attributes && this.state.allCourseLibraryData?.data?.attributes.categories.length - 1} more` : this.state.allCourseLibraryData?.data?.attributes.categories[0]}</Box>
                                </Grid>
                                {this.state.allCourseLibraryData?.data?.attributes?.average_rating > 0 &&
                                <Grid item lg={2} md={2} sm={4} xs={6}>
                                    <Typography style={styles.rattingTypo}>Ratings</Typography>
                                    <Box style={styles.rattingBox}>
                                        <span style={styles.ratting} data-testid="rating-count">
                                            {this.state.allCourseLibraryData.data.attributes.average_rating % 1 === 0 ? this.state.allCourseLibraryData.data.attributes.average_rating : this.state.allCourseLibraryData.data.attributes.average_rating.toFixed(1)}</span>
                                        <Rating
                                            name='read-only'
                                            value={this.state.allCourseLibraryData.data.attributes.average_rating.toFixed(1)}
                                            precision={0.5}
                                            readOnly
                                            icon={<img src={startRating}/>}
                                            emptyIcon={<img src={emptyStart}/>}   
                                        />
                                    </Box>
                                </Grid>
                                }
                                <Grid item lg={6} md={6} sm={4} xs={6} container justifyContent="flex-end" >

                                    {this.handleBtnRender()}

                                </Grid>
                            </Grid>
                                
                                    <Typography style={styles.data_detailP}>Description</Typography> 
                                    <Typography style={styles.data_detail_main}>
                                        {isShowBtn || !isDescriptionLong
                                            ? description
                                            : description.slice(0, 300) + '...'}
                                        {isDescriptionLong && (
                                            <Typography
                                                component={"span"}
                                                style={{ color: 'purple', width: "89px", height: "21px" }}


                                                onClick={this.handleShowButton}
                                            >
                                                {isShowBtn ? "Read Less..." : "Read More..."}
                                            </Typography>

                                        )}
                                    </Typography>
                                
                <p style={styles.data_detail}>{learning_details}</p>
                {(this.state.allCourseLibraryData?.data?.attributes?.rewards?.length > 0 || 
                                this.state.allCourseLibraryData?.data?.attributes?.achievements?.length > 0) &&
                            <Box>
                                <Typography style={styles.rewadAchivementMain}>{configJSON.coursePart}</Typography>
                                <Box style={styles.rewardsAchivementMainBox}>
                                    <RewardManiBox>
                                        <Typography style={styles.rewardsText}>{configJSON.rewards}</Typography>
                                        {this.state.allCourseLibraryData?.data?.attributes?.rewards?.map((data:{icon_url:string,name:string}) =>
                                        <Box  style={styles.rewardsInnerBox}>
                                            <img style={styles.rewardImage} src={data.icon_url} alt="reward"/>
                                            <RewardText>{data.name}</RewardText>
                                        </Box>
                                        )}
                                    </RewardManiBox>
                                    <RewardManiBox>
                                    <Typography style={styles.rewardsText}>{configJSON.achievement}</Typography>
                                    {this.state.allCourseLibraryData?.data?.attributes?.achievements?.map((item:{icon_url:string,name:string}) =>
                                        <Box style={styles.rewardsInnerBox}>
                                            <img style={styles.rewardImage} src={item.icon_url} alt="reward"/>
                                            <RewardText>{item.name}</RewardText>
                                        </Box>
                                        )}
                                    </RewardManiBox>
                                </Box>
                            </Box>
                            }
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}
                        style={{
                            width:"412px"
                        }}
                        >
                            <Paper elevation={4} style={styles.sidebar_paper}>
                              
                               {this.state.allCourseLibraryData?.data?.attributes &&
                               <>
                                {(this.state.allCourseLibraryData.data.attributes.is_enrolled  && 
                                    this.state.allCourseLibraryData.data.attributes.status !== "pending"
                                ) ?
                                 <>
                                <StyledTabs>
                                    <StyledTab data-testid='lesson-tab' onClick={() => this.handleToggleTab(0)} className={this.state.activeTab === 0 ? "activeTab": ""}>Lessons</StyledTab>
                                    <StyledTab data-testid='note-tab' onClick={() => this.handleToggleTab(1)}  className={this.state.activeTab === 1 ? "activeTab": ""}>Notes</StyledTab>
                                </StyledTabs>
                                {this.state.activeTab === 0 ?
                                <ProgressContainer>
                                <ProgressTop>
                                    <CourseSetting>Course Completion</CourseSetting>
                                    <CourseSettingAfter>{this.state.allCourseLibraryData.data.attributes.due_in_days}</CourseSettingAfter>
                                </ProgressTop>
                                <Box><StyledProgressBar variant="determinate" value={this.state.allCourseLibraryData.data.attributes.progress_bar_percentage * 100} />
</Box>
                                <ProgressBottom>
                                    <LessonsFont>{this.state.allCourseLibraryData.data.attributes.completed_lesson} of {this.state.allCourseLibraryData.data.attributes.lesson_count} lessons completed</LessonsFont>
                                </ProgressBottom>
                               </ProgressContainer> :
                               <Note 
                               notes={this.state.noteData} 
                               createNote={false}
                               data-testid="note-card"
                               handleOpenNote={this.handleOpenNote}
                               openNoteModal={this.state.openNoteModal}
                               noteId={this.state.noteId}
                               handleOpenEditNote={this.handleOpenEditNote}
                               iconType="video"
                               handleDeleteNote={this.handleDeleteNote}
                               deleteNoteModal={this.state.deleteNoteModal}
                               handleDeleteApiCall={this.handleDeleteApiCall}
                               />
                               }
                               </> :
                                <>
                                <h2 style={styles.mainh2_sidebar}>Course includes</h2>
                           <BoxDataAlign>
                               <Box>
                               <ImageWithData><span><img src={frame} height="48px" width ="49px"/></span><LessonCountWithText><span style={{ fontWeight: 700, fontSize: "18px" }}>{this.state.allCourseLibraryData.data.attributes.lesson_count}</span> <span className="demo">Lessons</span> </LessonCountWithText></ImageWithData>
                               </Box>

                               <SecondBox>
                                {this.state.allCourseLibraryData.data.attributes.content_release_schedule &&
                                <>
                               <Box><Divider></Divider></Box>
                               <AfterCountLesson>
                                   <Typography className="weightSet">Phases are released</Typography>
                                   <ContentReleaseStatus>
                                       {this.state.allCourseLibraryData.data.attributes.content_release_schedule_value}</ContentReleaseStatus>
                               </AfterCountLesson>
                               </>
                                }
                               </SecondBox>
                               <Box></Box>
                           </BoxDataAlign>
                           </>}
                           </>
                                }

                            {this.state.activeTab === 0 &&
                                <Box style={styles.main_Accordion}>
                                    <StyledAccordian className="accordionCoustom" style={styles.course_detail_phase}>
                                        {this.state.allCourseLibraryData.data?.attributes?.content_release_schedule ?
                                            <>
                                                {this.state.allCourseLibraryData.data?.attributes?.phases && Object.entries(this.state.allCourseLibraryData.data?.attributes?.phases).map(([k, v]: any) => {
                                                    return (
                                                        <Accordion style={styles.course_accordion}  disabled={this.state.lockedPhases.includes(v.phase_id)}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon style={{color:"black"}} />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                {v.is_lock && <img  style={{marginTop:"6px"}} src={lockIcon} />}
                                                                <div>
                                                                <p style={styles.course_p}>{v.phase}</p>
                                                                {v.is_lock ? <p style={{fontSize:"12px",margin:0}}><span style={{color:"#787776",fontWeight:700,fontSize:"12px"}}>Release: </span>{v.release_date}</p> :
                                                                <p style={{fontSize:"12px",margin:0}}><span style={{color:"#E47004",fontWeight:700,fontSize:"12px"}}>Due: </span>{v.due_date}</p>}
                                                                </div>
                                                            </AccordionSummary>
                                                            <AccordionDetails style={styles.course_detail}>
                                                                {v?.videos?.map((ele: any, index: any) => {

                                                                    return (
                                                                        <Box key={index}>
                                                                            <MediaControlCard handleNavigateToLesson={this.handleNavigateToLesson} isFreeTrial={k} button={this.state.allCourseLibraryData?.data?.attributes?.button} condition={this.state.allCourseLibraryData?.data?.attributes?.is_enrolled} ButtonStart={this.startButton} index={index} ele={ele} />
                                                                        </Box>
                                                                    )
                                                                })}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )
                                                })}
                                            </>
                                            :
                                            <>
                                                {this.state.allCourseLibraryData.data?.attributes?.videos.map((ele: any, index: any) => {
                                                    return (
                                                        <Box key={index} style={{ width: "100%" }}>
                                                            <MediaControlCard data-test-id="courseMediaControlCard" handleNavigateToLesson={this.handleNavigateToLesson} button={this.state.allCourseLibraryData?.data?.attributes?.button} condition={this.state.allCourseLibraryData?.data?.attributes?.is_enrolled} ButtonStart={this.startButton} index={index} ele={ele} />
                                                        </Box>
                                                    )
                                                })}
                                            </>
                                        }
                                    </StyledAccordian>
                                </Box>
                            }
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <AddNote 
                open={this.state.editNoteModal} 
                data-testid="edit-note-modal"
                handleCloseModal={this.handleCloseEditNote}
                lessonName={''}
                selectedThumbnailFile={this.SelectedIconFileDeleted}
                type={this.state.FileType}
                onSelectFile={this.onSelectFile}
                selectedFile={this.state.imageFile}
                progress={this.state.uploadFileProgress}
                uploadStatus={this.state.uploadFileStatus}
                timeStamp={this.state.timeStamp}
                iconType={'video'}
                titleValue={this.state.titleValue}
                handleChangeTitle={this.handleChangeTitle}
                titleError={this.state.titleError}
                titleErrorMessage={this.state.titleErrorMessage}
                handleChangeDescription={this.handleChangeDescription}
                descriptionError={this.state.descriptionError}
                descriptionErrorMessage={this.state.descriptionErrorMessage}
                descriptionValue={this.state.descriptionValue}
                handleCreateNote={() => {}}
                validationError={this.state.validationError}
                editNoteModal={this.state.editNoteModal}
                deleteImagePopup={this.deleteImagePopup}
                deleteImageModal={this.state.deleteImageModal}
                handleEdit={this.handleEditAPI}
            />
    </>

            // Customizable Area End
        );
    }
}

// Customizable Area Start




export function MediaControlCard({ ele, ButtonStart, condition, index, button, isFreeTrial,handleNavigateToLesson }: any) {
    const classes = useStyles();
    function showIcons(element: string) {
        switch (element) {
            case "video":
                return iconVideo
            case "document":
                return iconPage
            case "audio":
                return iconMusic
            case "image":
                return iconImage
            case "quiz":
                return iconQuiz
            default:
                return;
        }
    };

    return (
        <Card className={classes.root} style={styles.cardMedia} onClick={()=>  !ele.is_lock && handleNavigateToLesson(ele)}>
            <Box style={styles.media_mainBox} >
                <SequenceNumberShow> {ele.is_lock   ? <img src={lockIcon} /> : ele.sequence_number}
                </SequenceNumberShow>
                <Box style={styles.media_boxtitle}>
                    {button === "start" ?
                        <h5 onClick={() => ButtonStart(ele)}
                            id="start-course-id-tst3"
                            style={styles.media_title}>
                            {ele?.title}
                        </h5>
                        :
                        <>
                            {isFreeTrial === "free_trial" ?
                                <h5 onClick={() => ButtonStart(ele)}
                                    style={styles.media_title}>
                                    {ele?.title}
                                </h5>
                                :
                                <Box>
                                    <NameWithCount
                                        style={styles.media_title}
                                        className="media_title">
                                       <span>{ele.title}</span> 
                                    </NameWithCount>
                                </Box>
                               
                            }
                        </>
                    }
                </Box>
                <Box>
                </Box>
            </Box>
            <IconArrange>
                <Box style={{ marginTop: "10px" }}>
                    {
                        <img src={showIcons(ele.icon_type)}/>
                    }
                </Box>
            </IconArrange>
        </Card>
    )
}



const AddToCartBox = styled(Box)({
    gap:"16px",
    textAlign:"center",
    "@media (max-width:960px)": {
        alignItems:"center",
        marginTop:"20px",
        display:"flex",
      },
})

const styles = {
    cardMedia: {
        padding: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignContent: "center",
        alignItem: "center",
        
    },
    media_mainBox: {
        position: 'relative' as 'relative',
        display:"flex",
    },
    media_img: {
        height: '65px',
        width: '106px',
        borderRadius: '9px'
    },
    media_boxplay: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        
    },
    media_boxtitle: {
        marginLeft: '10px',
        marginTop: "10px"
    },
    media_title: {
        width: '100%', margin: 'auto',
        marginLeft: '0px', fontSize: '16px',
        fontWeight: 400, lineHeight: '16px',
        marginBottom: '10px', cursor: 'pointer',
    },
    media_chapter: {
        margin: 'auto',
        fontSize: '14px',
        lineHeight: '18px',
        fontWeight: 400,
        width: 'auto',
        
    },
    course_box: {
        height: '500px',
        maxWidth: '83vw',
        overflow: 'hidden',
        flex: 1,
        
    },
    course_h3: {
        fontFamily: 'Montserrat'
    },
    course_detail: {
        flexDirection: 'column' as 'column',
        padding: 0,
        alignItems: 'center',
        display: 'block',
        
    },
    course_p: {
        fontWeight: 700,
        fontSize: "16px",
        margin: 0,
        textTransform: 'capitalize' as 'capitalize',
    },
    course_accordion: {
        boxShadow: 'none'
    },
    phase_h2: {
        marginTop: '-5px',
        fontFamily: 'Montserrat'
    },
    course_detail_phase: {
        flexDirection: 'column' as 'column',
        padding: 0,
        maxWidth: "unset",     
    },
    main_Accordion: {
        boxShadow: 'none',
        position: 'inherit' as 'inherit',
        maxWidth: 'unset !important',
        overflow: 'auto',
        overflowX: 'hidden' as 'hidden',
        maxHeight: '350px',
        padding: '30px',
    },
    
    
    mainh2_sidebar: {
        marginTop: '-5px',
        fontFamily: 'Montserrat'
    },
    sidebar_paper: {
        marginBottom: '2rem',
        // padding: '30px',
        borderRadius: '16px'
    },
    data_detail: {
        color: 'gray'
    },
    "& .tabBox": {
        marginTop: "15px",
        width: "100%",
      },
      "& .tabMainBox": {
        borderBottom: "1px solid rgba(234, 236, 240, 1)",
        minHeight: "0px",
      },
    data_detailP: {
        fontSize: "20px",
        fontStyle: 'roman' as 'roman',
        fontWeight: 600,
        fontFamily: "Poppins",
        lineHeight: "24px",
        paddingTop: "1rem",

    },
    data_detail_main: {
        color: 'rgba(80, 80, 80, 1)',
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        
    },
    course_start: {
        background: '#2b2b2b',
        color: '#fff',
        borderRadius: '17px',
        padding: '6px 18px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    course_buy: {
        background: '#2b2b2b',
        color: '#fff',
        borderRadius: '17px',
        padding: '6px 18px',
        cursor: 'pointer',
        marginLeft: '-10px'
    },
    start_button: {
        background: '#2b2b2b',
        color: '#fff',
        width: '220px',
        height: '48px',
        borderRadius: '88px',
        padding: '6px 18px',
        cursor: 'pointer',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "16px",textTransform:"none" as "none"
    },
    continue_button:{
        background: '#E47004',
        color: '#fff',
        width: '220px',
        height: '48px',
        borderRadius: '88px',
        padding: '6px 18px',
        cursor: 'pointer',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "16px",
    },
    more_info_button:{
        background: '#E47004',
        color: '#fff',
        width: '343px',
        height: '48px',
        borderRadius: '88px',
        padding: '6px 18px',
        cursor: 'pointer',
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "21px",
    },
    completed_button:{
        background: '#652786',
        color: '#fff',
        width: '220px',
        height: '48px',
        borderRadius: '88px',
        padding: '6px 18px',
        cursor: 'pointer',
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "16px",
    },
    ratting: {
        marginTop: '3px',
        color: '#FF8B3F',
        fontSize: '16px',
        fontWeight:600,
        overflow:"hidden",
    },
    rattingBox: {
        display: 'flex',
        alignItems: 'center',
        gap:'2px'
    },
    rattingTypo: {
        fontSize: '12px',
        fontWeight: 400
    },
    rattingGrid: {
    },
    cateTypo: {
        fontFamily: 'Montserrat' as 'Montserrat',
        paddingBottom: "10px",
        fontSize: '12px',
        fontWeight: 400
    },
    box_cate: {
        marginTop: '-12px',
        fontWeight: 400,
        fontSize: "16px",
        color: "#787776"

    },
    boxCreate: {
        marginTop: '-9px',
        fontWeight: 500,
        fontSize: "16px",
        color: "787776"
    },
    create_typo: {
        fontFamily: 'Montserrat' as 'Montserrat',
        paddingBottom: "10px",
        fontSize: '12px',
        fontWeight: 400
    },
    mainGridButton: {
        justifyContent:"space-between",
        alignItems:"center"
    },
    main_title: {
        fontSize: "20px",
        fontStyle: 'roman' as 'roman',
        fontWeight: 'bold' as 'bold',
        fontFamily: 'Montserrat' as 'Montserrat'
    },
    main_titleBox: {
        display: 'flex',
        flexDirection: 'column' as 'column'
    },
    main_courseImg: {
        width: '100%',
        height: '100%',
        borderTopRightRadius: "20px",
        borderTopLeftRadius: "20px"
    },
    mainImg_box: {
        width: "100%",
        height: "344px",
        borderRadius:"16px 16px 0px 0px"
    },
    mainGridBox: {
        marginTop: '15px'
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff'
    },
    profileHeader: {
        fontFamily: 'Poppins',
        fontSize: '48px',
        fontWeight: 700,
        color: "#1C1B1B"
    },
    profileHeaderGrid: {
        paddingTop: '0px',
    },
    headigBox:{
        display:'flex',
        gap:'20px',
        alignItems:"center"
    },
    statusBox:{
        display:'flex',
        paddingTop:'20px'
    },
    rewadAchivementMain:{
        fontSize:'22px',
        fontWeight:600,
        lineHeight:'33px',
        color: '#1C1B1B',
        marginBottom:'20px'
    },
    rewardsAchivementMainBox:{
        display:'flex',
        justifyContent:'space-between',
        marginBottom:'10px',
        gap:'20px',
        flexWrap:'wrap' as 'wrap'
    },
    rewardsText:{
        fontSize:'20px',
        fontWeight:600,
        color:'#1C1B1B',
        lineHeight:'24px',
        marginBottom:'10px'
    },
    rewardsInnerBox:{
        display:'flex',
        background:'#FFFFFF',
        padding:'24px',
        borderRadius:'8px',
        gap:'10px',
        alignItems: 'center',
        boxShadow: 'rgba(0, 0, 0, 0.07) 1px 2px 9px 3px',
        marginBottom:'20px'
    },
    rewardImage:{
        width: '81px',
        height: '81px',
        borderRadius:'8px'
    }
}

const RewardText = styled(Typography)({
    fontSize:'14px',
        fontWeight:500,
        color:' #484646',
        lineHeight:'22px',
        '&::first-letter':{
          textTransform: 'uppercase'
        }
});

const RewardManiBox = styled(Typography)({
    width:'45%',
    '@media(max-width: 680px)': {
        width:'100%',
    },
})

const BoxDataAlign = styled(Box)({
    display: "flex",
        flexWrap: "wrap",
    justifyContent: "space-between",
    alignContent: "center",
    gap: "12px",
    alignItems: "center",
    padding: "20px 0px 20px 10px"
});

const SecondBox = styled(Box)({
    display: "flex",
    gap: "10px"
});

const StyledAccordian = styled(AccordionDetails)({

    "& .Mui-expanded":{
        maxWidth: "unset !important",
    },

    "&.MuiAccordionDetails-root":{
        maxWidth: "unset !important",
        border: "none",
        boxShadow: "none"
        
    },
});

const IconArrange = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItem: "center"
});

const ImageSize = styled("span")({
    fontSize: "16px"
});

const CourseSetting = styled(Typography)({
    fontSize: "18px !important",
    fontWeight: 600
});

const CourseSettingAfter = styled(Typography)({
    fontSize: "12px !important",
    fontWeight: 700 
});

const LessonCountWithText = styled(Typography)({
    display: "flex", 
    flexDirection: "column", 
    fontSize: "16px", 
    justifyContent: "center", 
    alignContent: "center", 
    alignItems: "center",
    "& .MuiTypography-body1":{
        fontSize: "none"
    }
});

const BoxAligne = styled(Box)({
    width: "364px",
    height: "37px",
    display: "flex",
    justifyContent: "space-between"
});

const Divider = styled(Typography)({
    width: "2px", 
    height: "67px", 
    border: "1px Solid #E5E2E1" 
});


const LessonsFont = styled(Typography)({
    fontWeight: 400,
    fontSize: "12px !important"
});

const InnerBoxAligne = styled(Box)({
    width: "174px",
    height: "37px",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    alignContent: "center",
    fontWeight: 600,
    fontSize: "16px"
});

const AfterCountLesson = styled(Box)({
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "center", 
    alignContent: "center", 
    alignItems: "center",
     width: "162px", 
     height: "64px", 
     gap: "10px",
     "& .MuiTypography-body1":
     {
        fontWeight: 399,
     },
     "& .weightSet":{
        fontWeight: 400,
        fontSize: "14px"
     },
});

const ImageWithData = styled(Box)({
    display: "flex", 
    fontSize: "16px", 
    width: "111px", 
    height: "44px", 
    gap: "10px",
    "& .demo": {
        fontSize: "14px",
        fontWeight: 400
    },
});

const ContentReleaseStatus = styled(Typography)({
    background: "#E0D4E7",
    padding: "6px 9px 6px 9px",
    borderRadius: "88px",
    width: "86px",
    height: "40px",
    textAlign: "center",
    fontWeight: 400,
    fontSize: "14px !important",
    color: "#652786"
});

const StyledTabs = styled(Box)({
    display: "flex",
    position: "relative",
    width: "100%",
    "&::before":{
        content: "' '",
        height:"2px",
        width: "100%",
        backgroundColor: "lightgray",
        position: "absolute",
        bottom: "0",
        left:"0"
    }
});

const StyledTab = styled(Box)({
    color: "#1C1B1B",
    height: "81px",
    textAlign: "center",
    lineHeight: "80px",
    position: "relative",
    flex: "0.5",
    fontSize:"16px",
    fontWeight: 600,
    "&.activeTab":{
        color: "#652786",
    },
    "&.activeTab::before":{
        content: "' '",
        height:"4px",
        width: "100%",
        backgroundColor: "#652786",
        position: "absolute",
        bottom: "-1px",
        left:"0",
        borderRadius: "10px"
    }
});

const StyledProgressBar = styled(LinearProgress)({
        height: "8px !important",
        borderRadius: "5px",
        backgroundColor: "#EEE8F2 !important",
        "& .MuiLinearProgress-barColorPrimary":{
            background: "linear-gradient(269.95deg, #6C328B 0.04%, #F42074 71.48%, #EF2B4B 99.95%)",
            borderRadius:"4px"
        }
      
});

const ProgressContainer = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    margin: "20px 0",
    paddingBottom:"35px",
    borderBottom: " 1px solid #E5E2E1",
    padding:"30px"
});

const ProgressTop = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const ProgressBottom = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
});

const SequenceNumberShow = styled(Box)({
    height: '48px',
    width: '48px',
    borderRadius: '4px',
    color:"#652786",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
    padding: "7px",
    border: "1px solid #E5E2E1 !important"
});

const NameWithCount = styled("p")({
    display: "flex",
    flexDirection: "column"
});

// Customizable Area End